import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { downloadFile, downloadStr } from '../../core/download-string';
import * as moment from 'moment';
import { AuthService } from 'src/app/api/auth.service';
import { LangService } from 'src/app/core/lang.service';

export interface IExportColumn {
  prop: string;
  caption: string;
  isClickable?: boolean;
  cssStyles?: object
}

export const generateExportFileName = (tableName: string) => {
  return `${tableName}-${moment().format('YYYY-MM-DD[T]HH_mm_ss')}`;
} 

export const saveDataAsTsv = (tableName:string, columns:IExportColumn[], data:any[]) => {
  const TAB = '\t';
  const NEWLINE = '\n';
  const stripTabs = (str: string) => ('' + (str || '')).split(TAB).join('');
  const tsv:any[] = data.map(row => {
    return columns.map(col => {
      const cell = row[col.prop]
      return stripTabs(cell);
    }).join(TAB)
  })
  tsv.unshift( columns.map(col => stripTabs(col.caption) ).join(TAB) );
  const filename = `${generateExportFileName(tableName)}.tsv`;
  downloadStr(tsv.join(NEWLINE), filename);
}

export const downloadFromExportData = (data: any[], columns: IExportColumn[], tableName, auth: AuthService) => {
  let exportData = [];

  for(let d of data) {
    let ed = {};
    for(const col of columns) {
      let v = d[col.prop];
      if(v === undefined) {
        v = null; //important for writing blank cells in excel
      }
      ed[col.caption] = v;
    }
    exportData.push(ed);
  }

  auth.jsonToExcel(exportData, generateExportFileName(tableName) ).then((res: any)=> {
    downloadFile(res.url);
  });
}

export const downloadCSVFromExportData = (filename, data, columns) => {
  let arrayOfJson = [];
  //convert data to json
  for(let d of data) {
    let ed = {};
    for(const col of columns) {
      let v = d[col.prop];
      if(v === undefined) {
        v = null; //important for writing blank cells in excel
      }
      ed[col.caption] = v;
    }
    arrayOfJson.push(ed);
  }

  // convert JSON to CSV
  const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
  const header = Object.keys(arrayOfJson[0])
  let csv = arrayOfJson.map(row => header.map(fieldName => 
  JSON.stringify(row[fieldName], replacer)).join(','))
  csv.unshift(header.join(','))
  const csvString = csv.join('\r\n')

  // Create link and download
  var link = document.createElement('a');
  link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csvString));
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadExportData = (exportData: any[], tableName, auth: AuthService) => {
  auth.jsonToExcel(exportData, generateExportFileName(tableName) ).then((res: any)=> {
    downloadFile(res.url);
  });
}

@Component({
  selector: 'tc-table-common',
  templateUrl: './tc-table-common.component.html',
  styleUrls: ['./tc-table-common.component.scss']
})
export class TcTableCommonComponent implements OnInit {

  @Input() name:string = 'data'; // for file saving
  @Input() data:any[] = [{}];
  @Input() hideTable:boolean;
  @Input() columns:IExportColumn[] = [];
  @Input() exportModal:Boolean;
  @Input() additionalInstrSlug:string;
  @Input() additionalDownload:{urlSlug: string, urlSlugExport?:string, captionSlug: string, captionSlugExport: string}
  @Output() cellSelect = new EventEmitter();
  @Output() exportClick = new EventEmitter();

  constructor(
    private lang:LangService,
    ) { }

  ngOnInit(): void {
  }

  selectCell(row, prop){
    this.cellSelect.emit({row, prop})
  }

  export(){
    saveDataAsTsv(this.name, this.columns, this.data);
  }

  downloadFileFromSlug(slug: string) {
    if(!slug) {
      return;
    }
    var a = document.createElement("a");
    a.href = this.lang.tra(slug);
    a.click();
  }
  exportStudents(){
    this.exportClick.emit()
  }
}
