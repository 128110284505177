import {ElementRef, Injectable, Input} from '@angular/core';
import Sockette from "sockette";
import { Subject } from 'rxjs';
import {AuthService} from '../api/auth.service';
import {ActivatedRoute} from '@angular/router';
import {ITestSessionDashboardInfo} from '../api/models/db/test-sessions.schema';
import {RoutesService} from '../api/routes.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  socket: any;
  readonly uri: string = 'wss://6lhkips2da.execute-api.ca-central-1.amazonaws.com/production';

  public isSupport;
  public isSupervisor;
  public markingPoolId;
  public markingPoolGroupId;
  public uid;
  public currentMessage = '';

  // Marker only.
  public supervisorId = null;
  public currentChat = [];

  // When marker is not in the chat component.
  public newMessage = false;
  public messagePoolIds = [];

  // Supervisor only
  // public chatTitle = "Current Chat";
  public markers = [];
  public broadcasts = [];
  public selectedMarker = null;
  public showBroadcasts = false;
  public messages = {};
  public isActive = {};
  public hasUnread = {};
  public names = {};

  public markingPool: any;

  // added for invigilator/test taker
  public isInvigilator = false;
  public isTestTaker = false;
  public instit_group_id = null;
  private studentsForInvig = [];

  // for autoscroll
  public elem: ElementRef;

  constructor(private auth: AuthService,
              private route: ActivatedRoute,
              private routes: RoutesService) {
    // this.initSocket();
  }

  scrollToBottom() {
    let self = this;
    setTimeout(function() {
      if (self.elem) {
        console.log('scrolling to bottom');
        self.elem.nativeElement.scrollTop = self.elem.nativeElement.scrollHeight;
      } else {
        console.error('chatDiv is undefined');
      }
    }, 50);
  }

  setStudents(students) {
    this.studentsForInvig = students;
  }

  initSocket() {
    this.socket = new Sockette(this.uri, {
      timeout: 10e3,
      maxAttempts: 10,
      onopen: e => {
        if (this.isSupport) {
          this.onSupportConnect(e);
        } else if (this.isSupervisor) {
          this.onSupervisorConnect(e);
        } else if (!this.isInvigilator && !this.isTestTaker) {
          this.onMarkerConnect(e);
        } else if (this.isInvigilator) {
          this.onInvigConnect(e);
        } else if (this.isTestTaker) {
          this.onTestTakerConnect(e);
        }

      },
      onmessage: e => {
        if (this.isSupervisor || this.isInvigilator) {
          this.onSupervisorMessage(e);
        } else {
          this.onMarkerMessage(e);
        }
      },
      onreconnect: e => console.log('Reconnecting...', e),
      onmaximum: e => console.log('Stop Attempting!', e),
      onclose: e => console.log('Closed!', e),
      onerror: e => console.log('Error:', e)
    });
  }

  public emit(action, data) {
    this.socket.json({action, data});
  }

  onMarkerConnect(e) {
    console.log('Connected as MRKR!');

    if (this.uid && this.markingPoolId) {
      const data = {
        uid: this.uid.toString(),
        gameId: [this.markingPoolId.toString()]
      };

      this.emit('playerConnect', data);
      this.removeUnread();
    } else {
      console.log('external');
      // get all marking pools this player is in.
      this.auth.apiFind('public/mrkg-mrkr/marking-pools').then(pools => {
        const data = {
          uid: this.auth.user().value.uid.toString(),
          gameId: pools
        };

        this.emit('playerConnect', data);

      });
    }
  }

  private onMarkerMessage(e) {
    let eObj, eventType;
    try {
      eObj = JSON.parse(e.data);
      eventType = eObj.eventType;
    }
    catch (e) {
      return;
    }
    // console.log('Marker message received:', eventType);
    console.log('Marker message received.');

    // Possible Events
    // getGame: returns all the game data this view needs.
    // message: a message has been received.
    // broadcast: a broadcast has been received.

    if (eventType === 'getGame') {
      const coachId = eObj.Attributes.coachId;
      const chat = eObj.Attributes.chat;

      this.currentChat = chat;
      this.supervisorId = coachId;

      eObj.Attributes.playerData.unreadFrom.forEach(id => {
        if (id !== 'placeholder') {
          this.messagePoolIds.push(id);
          this.newMessage = true;
        }
      });
      this.scrollToBottom();

    } else if (eventType === 'message' || eventType === 'broadcast') {
      const msg = eObj.Attributes.message;

      this.playSound();

      console.log(msg);
      this.newMessage = true;
      this.messagePoolIds.push(msg.poolId);

      this.currentChat.push(msg);

      if (!this.isTestTaker) {
        this.removeUnread();
      }
      this.scrollToBottom();

    }
  }

  private onSupportConnect(e) {
    console.log('Connected as support!');
    const data = {
      // uids
    };
    this.socket.json({action: 'supportConnect', data: data });
  }

  private onSupervisorConnect(e) {
    console.log('Connected as SUPR!', e);

    // connectionId is available in the lambda.

    this.auth.apiFind('public/mrkg-supr/marking-pools', {
      query: {
        getAll: 1,
      }
    }).then(pools => {
      let currentPool = pools.data.find(pool => +pool.id === +this.markingPoolId);
      this.markingPoolGroupId = currentPool.group_id;

      // get all markers for the marking pool.
      this.auth.apiFind('public/mrkg-supr/marking-pool/markers', {
        query: {
          marking_pool_id: this.markingPoolId,
          only_users: true,
          marking_pool_group_id: this.markingPoolGroupId
        }
      }).then((markers: any) => {
        let uids = []; // markers.data.map(marker => marker.id.toString());

        markers.data.forEach(marker => {
          uids.push(marker.id.toString());
          this.names[marker.id] = marker.first_name + ' ' + marker.last_name;
        });

        const data = {
          gameId: this.markingPoolId,
          supervisorUid: this.uid,
          uids
        };

        this.socket.json({action: 'coachConnect', data: data });
      });
    });

  }

  private onSupervisorMessage(e) {
    // console.log('Supervisor message received', e);
    console.log('Supervisor message received.');

    const eObj = JSON.parse(e.data);
    const eventType = eObj.eventType;

    // Possible Events
    // gameData: Returns all the current game, which use to update the state
    // playerUpdate: playerList has changed or player has changed their activity.
    // message: message received from a player
    // playerChangeConnection: a player goes active or inactive.

    if (eventType === 'gameData') {
      const mPoolChat = eObj.Attributes;
      console.log(mPoolChat);

      this.markers = mPoolChat.players;
      this.broadcasts = mPoolChat.broadcasts;
      this.messages = mPoolChat.messages;
      this.markingPool = mPoolChat;
      this.isActive = mPoolChat.isActive;
      this.hasUnread = mPoolChat.hasUnread;

      this.scrollToBottom();
    } else if (eventType === 'playerUpdate') {
      const playerList = eObj.playerList;
      const currPlayer = eObj.currPlayer;
      const isActive = eObj.isActive;

      console.log(isActive);
      console.log(currPlayer);

      this.markers = playerList;
      this.isActive[currPlayer] = isActive;

    }  else if (eventType === 'message') {
      console.log(eObj);
      console.log('MESSAGE!!!');

      const msg = eObj.Attributes.message;
      const markerId = eObj.Attributes.playerId;

      this.playSound();

      this.messages[markerId].push(msg);
      if (markerId == this.selectedMarker) {
        this.hasUnread[markerId] = false;

        // unset it as hasUnread=false on db.
        this.updateUnread(markerId, false);

      } else { // then we just update messages
        this.hasUnread[markerId] = true;
      }
      this.scrollToBottom();

    } else if (eventType === 'playerChangeConnection') {
      console.log(eObj);

      const isActive = eObj.Attributes.isActive;
      const playerId = eObj.Attributes.playerId;

      this.isActive[playerId] = isActive;

    }

  }

  private onInvigConnect(e) {
    console.log('Connected as Invigilator!', e);

    if (this.studentsForInvig.length > 0) {
      let uids = [];
      this.studentsForInvig.forEach(student => {
        uids.push(student.id.toString());
        this.names[student.id] = student.name;
      });

      const data = {
        gameId: this.markingPoolId + '-' + this.instit_group_id,
        supervisorUid: this.uid,
        uids
      };
      this.socket.json({action: 'coachConnect', data: data });
    } else {

      this.auth.apiFind('public/test-admin/test-sessions/students', {
        query: {
          instit_group_id: this.instit_group_id
        }
      }).then((students: any) => {
        let uids = []; // markers.data.map(marker => marker.id.toString());

        students.data.forEach(student => {
          uids.push(student.id.toString());
          this.names[student.id] = student.first_name + ' ' + student.last_name;
        });

        const data = {
          gameId: this.markingPoolId + '-' + this.instit_group_id,
          supervisorUid: this.uid,
          uids
        };
        this.socket.json({action: 'coachConnect', data: data });
      }).catch(e => {
        console.warn(e);
      });

    }

  }

  //private onInvigMessage(e);
  // Don't need, same

  private onTestTakerConnect(e) {
    console.log('Connected as Test Taker!');

    if (this.uid && this.markingPoolId) {
      const data = {
        uid: this.uid.toString(),
        gameId: [this.markingPoolId + '-' + this.instit_group_id]
      };

      this.emit('playerConnect', data);
      this.removeUnread();
    }

    /*
    if (this.uid && this.markingPoolId) {
      const data = {
        uid: this.uid.toString(),
        gameId: [this.markingPoolId.toString()]
      };

      this.emit('playerConnect', data);
      this.removeUnread();
    } else {
      console.log('external');
      // get all marking pools this player is in.
      this.auth.apiFind('public/mrkg-mrkr/marking-pools').then(pools => {
        const data = {
          uid: this.auth.user().value.uid.toString(),
          gameId: pools
        };

        this.emit('playerConnect', data);

      });
    }*/
  }

  // HELPERS

  // for supervisor
  updateUnread(playerId, unread) {
    const data = {
      'gameId': this.markingPoolId,
      'unread': unread,
      'playerId': playerId,
    };

    if (this.isInvigilator) {
      data.gameId = this.markingPoolId + '-' + this.instit_group_id;
    }

    this.emit('updateUnread', data);
  }

  private playSound() {
    let audio = new Audio();
    audio.src = 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/2329038/authoring/when/1666376102105/when.mp3';
    audio.load();
    audio.play();
  }

  // for marker
  removeUnread() {
    const unreadData = {
      isAdd: false,
      uid: this.uid.toString(),
      markingPoolId: this.markingPoolId.toString()
    };

    if (this.isTestTaker) {
      unreadData.markingPoolId = this.markingPoolId + '-' + this.instit_group_id;
    }

    // remove this one from unread list (locally).
    this.messagePoolIds.shift();
    // if that was the last one, disable the notification.
    if (this.messagePoolIds.length === 0) {
      this.newMessage = false;
    }
    this.emit('updatePlayerUnread', unreadData);
  }

}
