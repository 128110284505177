<div>
  <a routerLink="/{{lang.c()}}/message-centre">
    <span class="icon-text">
      <span class="icon" [class.has-new-notification]="getAccountNotifications().length > 0">
        <i class="fas fa-bell fa-lg swing"></i>
      </span>
      <!-- For non-header components, show 'new notifications' text -->
      <ng-container *ngIf="!isHeader">
        <span class="is-size-6" *ngIf="getAccountNotifications().length > 0">
          {{lang.tra("lbl-new-notifications").replace("#", getAccountNotifications().length.toString())}}
        </span>
        <span class="is-size-6" *ngIf="getAccountNotifications().length == 0">
          <tra slug="lbl-no-new-notifications"></tra>
        </span>
      </ng-container>
      <ng-container *ngIf="isHeader">
        <tra slug="lbl_centre_messag"></tra>
        <span *ngIf="notifications.unreadNotifications().length > 0">&nbsp;({{notifications.unreadNotifications().length}})</span>
      </ng-container>
    </span>
  </a>
</div>