import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ScoringTypes } from '../models';
import { HyperlinkService } from '../hyperlink.service';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { IContentElementTextLink } from '../element-render-text-link/model';



const SCORING_TYPE = ScoringTypes.AUTO;

@Component({
  selector: 'element-render-bookmark-link',
  templateUrl: './element-render-bookmark-link.component.html',
  styleUrls: ['./element-render-bookmark-link.component.scss']
})
export class ElementRenderBookmarkLinkComponent implements OnInit, OnChanges {

  @Input() element:IContentElementTextLink;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() changeCounter:number;
  @Input() questionState:any;
  @Input() questionPubSub?: QuestionPubSub;
  @Input() isHighContrast: boolean = false;

  constructor(
    private hyperLinkService: HyperlinkService
    ) { }

  ngOnInit() {

  }

  openLink(){
    this.hyperLinkService.linkRequest.next({
      readerElementId: this.element.readerElementId,
      bookmarkId: this.element.bookmarkId,
      itemLabel: this.element.itemLabel
    })
  }

  ngOnChanges(changes:SimpleChanges){
  }

  // getFontSize() {
  //   if (this.element.fontSize) {
  //     return `${this.element.fontSize}em`;
  //   }
  //   return 'inherit';
  // }
}
