import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import katex from 'katex';
import { LangService } from '../../core/lang.service';
import { StyleprofileService, processText, transformThousandsSeparator, transformFrenchDecimal } from 'src/app/core/styleprofile.service';

@Component({
  selector: 'render-math',
  templateUrl: './render-math.component.html',
  styleUrls: ['./render-math.component.scss']
})
export class RenderMathComponent implements OnInit, OnDestroy, OnChanges {

  @Input() obj:any;
  @Input() prop:string;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() raw:string;
  @ViewChild('mathField', { static: true }) mathFieldRef: ElementRef;

  mathField:HTMLSpanElement;
  currentLatex;
  intervalTracker;
  isRendered:boolean;
  styleProfileChangeSub;

  constructor(
    private lang:LangService,
    private profile:StyleprofileService
  ) { }

  ngOnInit() {
    this.styleProfileChangeSub = this.profile.getStyleProfileChanges().subscribe( hasStyleProfile => {
      if(hasStyleProfile) {
        this.updateLatex();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateLatex();
  }

  ngOnDestroy() {
    this.clearListener();  

    if(this.styleProfileChangeSub) {
      this.styleProfileChangeSub.unsubscribe();
    }
  }

  clearListener(){
    if (this.intervalTracker){
      clearInterval(this.intervalTracker);
    }
  }

  ngAfterViewInit() {
    // console.log('ngAfterViewInit')
    this.mathField = this.mathFieldRef.nativeElement;
    if(this.profile.getStyleProfile()){
      this.updateLatex();
    }
    // if (!this.raw){
    this.intervalTracker = setInterval(this.updateLatex.bind(this), 2000);
    // }
  }

  sanitizeLatex(latex:string){
    return processText(latex, this.profile.getStyleProfile()[this.lang.c()].renderStyling.math.transforms);
  }

  renderLatex(latex){
    latex = this.sanitizeLatex(latex);
    katex.render(latex, this.mathField, {
      throwOnError: false
    });
  }

  updateLatex(isForcedChange:boolean=false){
    // if (this.isRendered){
    //   this.clearListener(); 
    //   return 
    // }
    // console.log('updateLatex', this.raw, this.mathField)
    if (this.raw && this.mathField){
      this.renderLatex(this.raw);
      this.isRendered = true;
      return;
    }
    else if (this.mathField && this.obj && this.obj[this.prop]){
      if ( isForcedChange || (this.obj[this.prop] !== this.currentLatex) ){
        this.currentLatex = this.obj[this.prop];
        // this.mathField.innerHTML = '$$'+this.currentLatex+'$$';
        // this.MathLive.renderMathInElement(this.mathField);
        this.renderLatex(this.currentLatex);
        this.isRendered = true;
      }
  
    }
  }

}

