import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { bindFormControl, bindFormControls } from '../../ui-item-maker/services/data-bind';


export enum FilterSettingMode {
  VALUE = 'value',
  RANGE = 'range',
  LIST = 'list',
}

export enum FilterDataType {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  DATETIME = 'datetime',
  DROPDOWN = 'dropdown',
}

export interface IFilterSetting {
  mode: FilterSettingMode,
  config: IFilterSettingConfigValue | IFilterSettingConfigRange | IFilterSettingConfigList
}

export interface IFilterSettingConfigValue {
  value: string,
}
export interface IFilterSettingConfigRange {
  from: number,
  to: number,
}
export interface IFilterSettingConfigList {
  selectedValue: string,
  list: any[],
}

@Component({
  selector: 'capture-filter-range',
  templateUrl: './capture-filter-range.component.html',
  styleUrls: ['./capture-filter-range.component.scss']
})
export class CaptureFilterRangeComponent implements OnInit {

  @Input() isActive:boolean;
  @Input() model:{[key:string]:IFilterSetting}
  @Input() id:string;
  @Input() isModeSelect:boolean = false;
  @Input() dataType:FilterDataType = FilterDataType.TEXT;
  @Input() defaultMode:FilterSettingMode = FilterSettingMode.VALUE;
  @Output() change = new EventEmitter();

  FilterSettingMode = FilterSettingMode;
  modeOptions = [
    {id: FilterSettingMode.VALUE, caption:'Value'},
    {id: FilterSettingMode.RANGE, caption:'Range'},
    {id: FilterSettingMode.LIST, caption:'List'},
  ];
  
  state:IFilterSetting;
  config;
  modeSelector = new FormControl();
  value = new FormControl();
  rangeFrom = new FormControl();
  rangeTo = new FormControl();
  list = new FormControl();
  selectedValue = new FormControl();
  isInited:boolean;

  FilterDataType = FilterDataType;

  constructor() { }

  ngOnInit() {
    if (!this.model[this.id]){
      this.model[this.id] = {
        mode: this.defaultMode || FilterSettingMode.VALUE,
        config: { value: null }
      }
    }
    this.state = this.model[this.id];
    if(this.model[this.id].mode == FilterSettingMode.LIST){
      this.config = <IFilterSettingConfigList> this.model[this.id].config;
    }

    bindFormControls(this.state, [
      {f: this.modeSelector, p: 'mode'},
    ]);
    bindFormControls(this.state.config, [
      {f: this.value, p: 'value'},
      {f: this.rangeFrom, p: 'from'},
      {f: this.rangeTo, p: 'to'},
    ]);
    bindFormControls(this.state.config, [
      {f: this.list, p: 'list'},
      {f: this.selectedValue, p: 'selectedValue'}
    ])
    if(this.model[this.id].mode == FilterSettingMode.LIST){
      const filterConfig = <IFilterSettingConfigList> this.state.config;
      const val = filterConfig.selectedValue;
      if(val){
        this.selectedValue.setValue(val);
      }
    }
    this.modeSelector.setValue(this.model[this.id].mode);
    this.modeSelector.valueChanges.subscribe(obs => this.reflectChange())
    this.value.valueChanges.subscribe(obs => this.reflectChange())
    this.rangeFrom.valueChanges.subscribe(obs => this.reflectChange())
    this.rangeTo.valueChanges.subscribe(obs => this.reflectChange())
    this.list.valueChanges.subscribe(obs => this.reflectChange())
    this.selectedValue.valueChanges.subscribe(obs => this.reflectChange())
    this.isInited = true;
  }

  reflectChange(){
    if (this.isInited){
      this.change.emit();
    }
  }

}
