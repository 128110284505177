<div class="element-type-ordering">
<div *ngIf="element.showDefaultText" class="question-block-label mb-3" style="text-align: center; font-size:0.8em;margin-bottom: 4em;">
  <!-- <tra-md slug="txt_default_order_instr"></tra-md> -->
  <tra-md [slug]="getDefaultOrderInstrSlug()"></tra-md>

</div>

<div cdkDropListGroup
    [style.display]="'flex'"
    [style.flex-direction]="isVertical() ? (element?.isTargetOrientationReversed ? 'row-reverse' : 'row') : (element?.isTargetOrientationReversed ? 'column-reverse' : 'column')"
    [style.margin-left.em]="isVertical() && isTargetMode()? 4 : 0"
>
  <div 
    class="option-container"
    cdkDropList 
    (cdkDropListDropped)="(!isTargetMode()) ? drop(options, $event) : dropTargetMode($event, null, true)"
    tabindex="0" (keyup.Enter)="(isTargetMode()) ? onEnterdropTargetMode($event, options, null, null, true) : ''; "
    (mousedown)="(isTargetMode()) ? onClickToDropTargetMode($event, options, null, null, true) : ''; "
    [cdkDropListSortingDisabled]="isTargetMode()"
    [cdkDropListData]="this.options"
    [cdkDropListOrientation]="isHorizontal() ? 'horizontal' : 'vertical'"
    [class.is-vertical]="isVertical()"
    [class.is-horizontal]="isHorizontal()"
    [class.is-target-mode-source]="isTargetMode()"
    [ngStyle]="getDragWidthSet()"
  >
    <div cdkDrag [cdkDragDisabled]="isLocked"
      *ngFor="let optionElement of options; let optionIndex = index;" 
      tabindex="0" (keyup.enter)="onEnter($event, optionElement, options, optionIndex); playTargetAudio(optionElement)"
      (mousedown)="onClickToDrag($event, optionElement, options, optionIndex); playTargetAudio(optionElement)"
      [class.is-selected]="isSelected(optionElement, options)"
    >
      <div 
        class="inner-option-container"
        [class.is-vertical]="isVertical()"
        [class.is-horizontal]="isHorizontal()"
        [style.height]="'inherit'"
        [style.max-height]="'inherit'"
        style="display:flex;flex-grow:1;"
        *ngIf="!optionElement?.isReadOnly || !isTargetMode()"
      >
        <div *ngIf="optionIndex !== 0 && !isTargetMode()" style="padding:0.3em; flex-grow:0;">
          <div *ngIf="!element?.delimiterType || element?.delimiterType=='text'" [style.line-height]="'1em'" [innerHTML]="element?.delimiter ? processThisText(element?.delimiter) : ''">
            <!--<markdown-inline [input]="element.delimiter"></markdown-inline>-->
          </div>
          <div *ngIf="element?.delimiterType=='image'">
            <element-render-image *ngIf="element?.delimiterImg" [element]="element?.delimiterImg"></element-render-image>
          </div>
          <div *ngIf="element?.delimiterType=='icon'">
            <i *ngIf="element?.delimiterImg" [class]="element?.delimiterIcon"></i>
          </div>
        </div>
        <div 
          [ngSwitch]="optionElement?.elementType" class="option not-in-target" 
          [style.color]="element?.colourScheme?.textColor ? element?.colourScheme?.textColor : ''"
          [style.backgroundColor]="element?.colourScheme?.backgroundColor ? element?.colourScheme?.backgroundColor : ''"
          [ngStyle]="isTargetMode() ? getDims(false) : {}"
          [style.justify-content]="element?.isCenterDraggableContent ? 'center' : 'left'"
          [style.align-items]="element?.isCenterDraggableContent ? 'center' : 'left'"
        >
        <ng-container *ngSwitchCase="'text'">
          <div *ngIf="isAdvTextEl(optionElement)" >
            <element-render-text [element]="optionElement"></element-render-text>
          </div>
          <div *ngIf="!isAdvTextEl(optionElement)" [style.line-height]="'1em'" [style.text-align]="element?.isCenterDraggableContent ? 'center' : 'left'" style="white-space: pre-wrap;" [innerHTML]="getText(optionElement)">
            <!--<markdown-inline [input]="optionElement.content"></markdown-inline>-->
          </div>
        </ng-container>          
          <div *ngSwitchCase="'image'">
            <element-render-image [element]="optionElement"></element-render-image>
          </div>
          <div *ngSwitchCase="'math'">
            <render-math [obj]="optionElement" [prop]="'content'"></render-math>
          </div>
        </div>
      </div>
      <render-audio
        [url]="getElementAudio(optionElement?.voiceover)"
        [trigger]="getAudioTrigger(optionElement)"
        [isTriggerDisabled]="!isVoiceoverEnabled()"
      ></render-audio>
    </div>
  </div>

  <div *ngIf="isTargetMode()" style="display:flex;align-items:center;" [style.flex-direction]="getTargetFlowDirection()">
    <div *ngIf="!element?.isShowSeparatorInRenderHidden">
      <div *ngIf="element?.separatorType == 'text'">
        <element-render-text *ngIf="element?.separatorText" [element]='element?.separatorText'></element-render-text>
      </div>
      <div *ngIf="this.element?.separatorType == 'image'">
        <element-render-image *ngIf="this.element?.separatorImage" [element]='this.element?.separatorImage'></element-render-image>
      </div>
      <div *ngIf="this.element?.separatorType == 'canvas'">
        <element-render-canvas *ngIf="this.element?.separatorCanvas" [element]='this.element?.separatorCanvas' [changeCounter]="this.element?.separatorCanvas._changeCounter"></element-render-canvas>
      </div>
    </div>
    <div
      class="option-container"
      [class.is-vertical]="isVertical()"
      [class.is-horizontal]="isHorizontal()"
      [style.margin-right.em]="isVertical() && element?.isTargetOrientationReversed ? 4 : 0"
      [style.margin-left.em]="isVertical() && !element?.isTargetOrientationReversed ? 4 : 0"
      [style.margin-top.em]="isHorizontal() && !element?.isTargetOrientationReversed ? 4 : 0"
      [style.margin-bottom.em]="isHorizontal() && element?.isTargetOrientationReversed ? 4 : 0"
    >
      <div *ngFor="let optionElement of answers; let i = index;">
        <div 
          class="inner-option-container"
          [class.is-vertical]="isVertical()"
          [class.is-horizontal]="isHorizontal()"
          [style.left.em]="this.element?.options[i] && this.element?.options[i].x ? this.element?.options[i].x : 0"
          [style.top.em]="this.element?.options[i] && this.element?.options[i].y ? this.element?.options[i].y : 0"
        >
          <div *ngIf="i !== 0" style="padding:0.3em; flex-grow:0; align-self:center;">
            <div *ngIf="!element?.delimiterType || element?.delimiterType=='text'" [style.line-height]="'1em'" [innerHTML]="element?.delimiter ? processThisText(element?.delimiter) : ''">
              <!--<markdown-inline [input]="element?.delimiter"></markdown-inline>-->
            </div>
            <div *ngIf="element?.delimiterType=='image'">
              <element-render-image *ngIf="element?.delimiterImg" [element]="element?.delimiterImg"></element-render-image>
            </div>
            <div *ngIf="element?.delimiterType=='icon'">
              <i *ngIf="element?.delimiterImg" [class]="element?.delimiterIcon"></i>
            </div>
          </div>
          <div style="display:flex; flex-wrap: nowrap;align-items: center;" [style.flex-grow]="1" [style.flex-direction]="'column'"
            (keyup.Enter)="canBeDropped(optionElement) ? onEnterdropTargetMode($event, optionElement, element?.options[i], i , false) : '';"
            (mousedown)="canBeDropped(optionElement) ? onClickToDropTargetMode($event, optionElement, element?.options[i], i , false) : '';"
            tabindex="0"
          >
            <div [style.color]="this.element?.colourScheme?.textColor"
                [style.backgroundColor]="this.element?.colourScheme?.backgroundColor"
                [ngStyle]="isTargetMode() ? getDims(true) : {}"
                [style.border-style]="'solid'"
                [style.border-width.em]="0.1"
                [style.border-color]="'#000000'"
                style="display:flex;flex-direction:column;justify-content: flex-start;align-items: center;"
                cdkDropList [cdkDropListData]="optionElement" (cdkDropListDropped)="canBeDropped(optionElement) ? dropTargetMode($event, element?.options[i], false) : '';" 
                [cdkDropListEnterPredicate]="canBeDroppedPredicate"
              >
  
              <div (mousedown)="playTargetAudio(this.element?.options[i], dropTriggers, 'label_voiceover')" 
              style="display:flex;flex-direction: column;white-space:pre;align-self:stretch">
                <div [style.text-align]="this.element?.isCenterLabelContent ? 'center' : 'left'"
                    [style.line-height]="'1em'"
                    [style.align-self]="'stretch'"
                    [style.border-bottom]="this.element?.options[i].label && this.element?.options[i].label != '' ? '2px solid' : 'none'"
                    *ngIf="!this.element?.options[i].labelType || this.element?.options[i].labelType=='text' || this.element?.options[i].labelType=='simple_text'"
                    [innerHTML]="this.element?.options[i].label ? processThisText(this.element?.options[i].label) : ''">
                    <!--<markdown-inline [input]="this.element?.options[i].label ? processThisText(this.element?.options[i].label) : ''"></markdown-inline>-->
                </div>
                <div style="display:flex;flex-direction: row;" 
                    [style.justify-content]="this.element?.isCenterLabelContent ? 'center' : 'flex-start'" 
                    [style.border-bottom]="hasDefaultImage(this.element?.options[i].labelImg) ? '2px solid' : 'none'"
                    *ngIf="this.element?.options[i].labelType=='image'" >
                  <element-render-image [style.align-items]="this.element?.isCenterLabelContent ? 'center' : 'left'" [element]="this.element?.options[i].labelImg"></element-render-image>
                </div>
                
              </div>
  
  
              <div *ngFor="let ans of optionElement" cdkDrag [cdkDragDisabled]="ans.isReadOnly || isLocked" class="option"
                (mousedown)="canBeDropped(optionElement) ? onClickToDropTargetMode($event, optionElement, element?.options[i], i , false) : ''; playTargetAudio(ans)"
                [class.is-selected]="isSelected(element?.options[i], optionElement)"
                [style.margin]="(isSelected(element?.options[i], optionElement)) ? '0.5em': ''"
                [class.is-fixed]="ans.isReadOnly"
                [style.justify-content]="this.element?.isCenterDraggableContent ? 'center' : 'left'"
                [style.align-items]="this.element?.isCenterDraggableContent ? 'center' : 'left'"
                [ngStyle]="isTargetMode() ? getDims(false) : {}">
                <div [ngSwitch]="ans.elementType">
                  <ng-container *ngSwitchCase="'text'">
                    <div *ngIf="isAdvTextEl(ans)" >
                      <element-render-text [element]="ans"></element-render-text>
                    </div>
                    <div *ngIf="!isAdvTextEl(ans)" [style.line-height]="'1em'" [style.text-align]="element?.isCenterDraggableContent ? 'center' : 'left'" style="white-space: pre-wrap;" [innerHTML]="getText(ans)">
                      <!--<markdown-inline [input]="optionElement.content"></markdown-inline>-->
                    </div>
                  </ng-container>                  
                  <div *ngSwitchCase="'image'">
                    <element-render-image [element]="ans"></element-render-image>
                  </div>
                  <div *ngSwitchCase="'math'">
                    <render-math [obj]="ans" [prop]="'content'"></render-math>
                  </div>
                </div>
                <render-audio
                  [url]="getElementAudio(ans?.voiceover)"
                  [trigger]="getAudioTrigger(ans)"
                  [isTriggerDisabled]="!isVoiceoverEnabled()"
                ></render-audio>
              </div>
            </div>
            <render-audio
              [url]="getElementAudio(this.element?.options[i].label_voiceover)"
              [trigger]="getAudioTrigger(this.element?.options[i], dropTriggers)"
              [isTriggerDisabled]="!isVoiceoverEnabled()"
            ></render-audio>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>