import { AfterViewInit, Component, HostListener, Input, OnInit } from '@angular/core';
import { IContentElementPassage } from './model';
import { DEFAULT_WIDTH_EM, QuestionState } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { StyleprofileService } from 'src/app/core/styleprofile.service';
import { AuthScopeSettingsService } from 'src/app/ui-item-maker/auth-scope-settings.service';
import { LangService } from 'src/app/core/lang.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { TextToSpeechService } from '../text-to-speech.service';

const PARARGRAPH_SPACING = 1;
type ILine = {
  // identSpans:{
  //   str: string,
  //   width: number,
  // }[]
}
type ISegment = {
  // lines:ILine[], 
  str:string,
  isComplete?:boolean
}
@Component({
  selector: 'element-render-passage',
  templateUrl: './element-render-passage.component.html',
  styleUrls: ['./element-render-passage.component.scss']
})
export class ElementRenderPassageComponent implements OnInit, AfterViewInit {

  @Input() element:IContentElementPassage;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter:number;
  @Input() questionPubSub?: QuestionPubSub;

  constructor(
    private lang:LangService,
    private styleProfile:StyleprofileService,
    private authScope: AuthScopeSettingsService,
    private sanitizer: DomSanitizer,
    public textToSpeech: TextToSpeechService,
  ) { }

  ngOnInit() {
    // this.updateRender();
    // this.profile.getStyleProfileChanges().subscribe((hasStyleProfile) => {
    //   if(hasStyleProfile) {
    //     this.updateRender();
    //   }
    // })
  }

  ngAfterViewInit(): void {
    this.images.forEach((img) => {
      this.addImgListener(img.id, img.imageData);
    })
  }

  ngOnDestroy(): void {
    // if(this.styleProfileChangeSub) {
    //   this.styleProfileChangeSub.unsubscribe();
    // }
  }

  getImageRefs(){
    const imageRef:Map<number, {url:string, width_em:number, alignment?:string, isNoInvertOnHiContrast?: boolean, altText?: string, voiceover}> = new Map()
    if (this.element.images){
      for (let image of this.element.images){
        const alignment = image.alignment
        const isNoInvertOnHiContrast = image.el?.isNoInvertOnHiContrast
        const imageEl = image.el?.images?.default?.image || {}; // todo:bad backwards compatibility
        const altText = imageEl.altText;
        const url = imageEl.url;
        const width_em = DEFAULT_WIDTH_EM * (imageEl.scale /100)
        imageRef.set(+image.id, { url, alignment, width_em, isNoInvertOnHiContrast, altText, voiceover: image.el?.voiceover })
      }
    }
    return imageRef
  }

  getImageStyle(isNoInvertOnHiContrast?: boolean) {
    let style = ""
    if (isNoInvertOnHiContrast) {
      // style["filter"]="invert(1)";
      style = "filter: invert(0);";
    }
    return style;
  }

  processPStyle(htmlContent: string): string {
    const htmlContentSplit = htmlContent.split('\n')

    return htmlContentSplit.map(line => {
      let paragraphStyle:{tabs:{sizeEm:number}[]};
      line = line.replace(/<pstyle id="(\d+)"\/?>/g, (match:string, id:string) => {
        for (let ps of this.element.paragraphStyles){
          if (+ps.id == +id){
            paragraphStyle = ps;
          }
        }
        return '';
      });
      if (paragraphStyle){
        const lineIndentSpans = line.split('<t/>');
        line = `${ lineIndentSpans.map((str, i) => {
          paragraphStyle.tabs
          const tab = paragraphStyle.tabs[i]
          const widthStyle = (tab && tab.sizeEm) ? ' width:'+tab.sizeEm+'em' : '';
          return `<span style="display:inline-block;${widthStyle}">${str}</span>`
        }).join('') }`
      }

      return line;
    }).join('\n');
  }

  voiceover = null;
  getCurrentVoiceoverUrl() {
    return this.voiceover?.url || null;
  }

  clickTrigger = new Subject<boolean>();
  addImgListener(id: string, imageData: any){
    document.getElementById(id).addEventListener('click', () => {
      this.voiceover = imageData.voiceover
      this.clickTrigger.next(true);
    })
  }

  images: {id: string, imageData: any}[] = [];
  lastTextSnapshot:string;
  segments:{html?:SafeHtml, isComplete?:boolean}[];
  renderTextSegments(){
    const getBookmarkTag = (i, body: string, isParagraph?: boolean): string => {
      return `<div class="bookmark id-${isParagraph ? 'paragraph' : 'line'}_${i+1}">${body}</div>`
    }
    const snapshot = this.element._changeCounter+this.element.text
    if (this.lastTextSnapshot != snapshot){
      this.lastTextSnapshot = snapshot
      let htmlContent = this.element.text || '';
      const imageRef = this.getImageRefs()
      // use custom small caps
      htmlContent = htmlContent.replace(/<sc\b[^>]*>(.*?)<\/sc>/g, '<span class="small-caps">$1</span>');
      // apply bookmark tags (and other style profile transforms)
      htmlContent = this.processPStyle(htmlContent);
      htmlContent = this.processBookmark(htmlContent);
      htmlContent = this.styleProfile.processBookmark(htmlContent);
      htmlContent = this.styleProfile.processBoldedMarkup(htmlContent);
      htmlContent = this.styleProfile.processItalicsMarkup(htmlContent);
      htmlContent = this.styleProfile.processTooltip(htmlContent);
      // apply image tags
      htmlContent = htmlContent.replace(/<img id="(\d+)">/g, (match:string, id:string) => {
        const imageData = imageRef.get(+id);
        if (imageData) {
          const alignmentClass = imageData.alignment ? ` align-${imageData.alignment} ` : ''
          this.images.push({id: `passage-image-${id}`, imageData})
          let replacement = `<div class="tooltip-container img-container ${alignmentClass}"><img id="passage-image-${id}" style="width:${imageData.width_em}em;${this.getImageStyle(imageData.isNoInvertOnHiContrast)}" src="${imageData.url}" > <div class="tooltip-content">${imageData.altText}</div></div>`;
          return replacement;
        }
        return match; // Return the original match if no image data is found
      })
      // split the lines
      
      const segments:ISegment[] = [];
      let currentSegment:{chunks:string[], numLines:number};
      const resetCurrentSegment = () => currentSegment = {chunks:[], numLines:0};
      resetCurrentSegment();

      const htmlContentSplit = htmlContent.split('\n')
      if (this.element.counterType === 'LINE'){
        let lineCount = 0;
        for (let line of htmlContentSplit){
          // strippedLine holds the version of the line without bookmark tags to check for emptyness
          let strippedLine = line.replace(/<div class="bookmark id-[^>]+>/g, '');
          strippedLine = strippedLine.replace(/<\/div>/g, '');
          // bookmarkTag holds the original bookmark tag before it was replaced.
          const bookmarkTag = line.match(/<div class="bookmark id-[^>]+>/g)

          const isLineFilled = ((strippedLine || ' ').trim() != '');
          let isLineSkipped = !(isLineFilled || !this.element.isLineCountSkipBlank);
          line = line.replace(/<skip\/>/g, (match:string, id:string) => {
            isLineSkipped = true
            return '';
          })

          // If the line is empty, an empty space needs to be added to be parsed by the HTML
          if(!isLineFilled && bookmarkTag.length > 0) {
            currentSegment.chunks.push(`${bookmarkTag[0]} </div>`)
          } else {
            currentSegment.chunks.push(line)
          }
          if (!isLineSkipped){
            currentSegment.numLines += 1
          }
          if (currentSegment.numLines >= this.element.lineCountInterval){
            // currentSegment.chunks.forEach((line, i) => {
            //   let processedLine = getBookmarkTag(lineCount++, line);
            //   processedLine = this.closeBookmarkTags(processedLine);
            //   processedLine = this.openBookmarkTags(processedLine);
            //   currentSegment.chunks[i] = processedLine;
            // });
            segments.push({ str: currentSegment.chunks.join('\n'), isComplete:true })
            resetCurrentSegment();
          }
        }
      }
      else if (this.element.counterType === 'PARAGRAPH'){
        for (let line of htmlContentSplit){
          let strippedLine = line.replace(/<div class="bookmark id-[^>]+>/g, '');
          strippedLine = strippedLine.replace(/<\/div>/g, '');

          const isBlankLine = (
            strippedLine.trim() == ''
          )

          if (!isBlankLine){
            currentSegment.chunks.push(line);
          }
          if (isBlankLine && currentSegment.chunks.length > 0){
            segments.push( {str:currentSegment.chunks.join('\n'), isComplete:true} )
            resetCurrentSegment();
          }
        }
      }
      else if (this.element.counterType === 'NONE'){
        segments.push( {str:htmlContentSplit.join('\n')} )
      }
      // push whatever is left
      if (currentSegment.chunks.length){
        segments.push( {
          str: currentSegment.chunks.join('\n'),
          isComplete: (this.element.counterType === 'PARAGRAPH'), // if paragraph mode, then whereever the text ends is considered the end of the paragraph (dont need another set of spaces afterwards)
        })
      }
      this.segments = segments.map((segment, i) =>{
        const {str, isComplete} = segment;
        return {
          html: this.sanitizer.bypassSecurityTrustHtml(str),
          isComplete,
        }
      });
      // console.log('this.segments', this.segments)
    }
    return this.segments
  }

  paragraphSpacing(){
    if (this.element.counterType == 'PARAGRAPH'){
      return PARARGRAPH_SPACING;
    }
    return 0
  }

  isCounterAlignRight(){
    return (this.element.counterAlignment == 'right')
  }
  isCounterAlignLeft(){
    return ! this.isCounterAlignRight()
  }

  isLinesMode(){
    return (this.element.counterType == 'LINE')
  }
  isParagraphMode(){
    return (this.element.counterType == 'PARAGRAPH')
  }

  isShowCounter(){
    return (this.element.counterType !== 'NONE') && (+this.element.lineCountInterval > 0);
  }
  
  renderLineCount(index:number){
    const count = (+index + 1);
    if (this.element.counterType == 'PARAGRAPH'){
      return count
    }
    else if (this.element.counterType == 'LINE'){
      return count*this.element.lineCountInterval
    }
  }

  processBookmark(html: string): string {
    const passage = html.split('\n')
    let processedPassage = passage.map((line, i) => {
      return `<bookmark id="line_${i+1}">${this.openBookmarkTags(this.closeBookmarkTags(line))}</bookmark>`;
    });
    return processedPassage.join('\n');
  }

  lastTags: string[] = [];
  closeBookmarkTags(html: string): string {
    const closedTags = /<\/bookmark>/gs;
    const openTags = /<bookmark(.*?)>/gs;
    const htmlClosedTags = html.match(closedTags);
    const htmlOpenTags = html.match(openTags);
    if ((!htmlClosedTags && !htmlOpenTags) || (htmlClosedTags && !htmlOpenTags)) {
        return html;
    } else if(htmlOpenTags) {
        const closedArray = htmlClosedTags ? Array.from(htmlClosedTags) : [];
        const openArray = Array.from(htmlOpenTags);
        const openTagCount = openArray.length - closedArray.length;
        if(openTagCount <= 0) {
            return html;
        }

        this.lastTags.push(...openArray);
        const closeTags = Array.from({ length: openTagCount }, (_, index) => `</bookmark>`);
      
        return html + closeTags.join('');
    }

    return html;
  }

  openBookmarkTags(html: string): string { //need to account for closing tags
    const closedTags = /<\/bookmark>/gs;
    const openTags = /<bookmark(.*?)>/gs;
    const htmlClosedTags = html.match(closedTags);
    const htmlOpenTags = html.match(openTags);
    if (htmlOpenTags && !htmlClosedTags) {
        return html;
    } else if(!htmlClosedTags && !htmlOpenTags && this.lastTags.length > 0) {
        const openTags = this.lastTags.join('');
        const closeTags = Array.from({ length: this.lastTags.length }, (_, index) => `</bookmark>`);
        return openTags + html + closeTags;
    } else if(htmlClosedTags) {
        const openArray = htmlOpenTags ? Array.from(htmlOpenTags) : [];
        const closedArray = Array.from(htmlClosedTags);
        const closeTagCount = closedArray.length - openArray.length;

        if(closeTagCount <= 0) {
            return html;
        }

        const openTags = [];
        for(let i=0;i<closeTagCount; i++) {
            openTags.push(this.lastTags.shift())
        }
        
        return openTags.join('') + html;
    }

    return html;
  }

}
