<span class="tag {{background}}" *ngIf="!isDismissedHidden" [class.is-dismissed-transparent]="isDismissedTransparent">
    <tra-md *ngIf="slug" [slug]="slug" [isFlex]="true">
    </tra-md>
    <ng-content>
    </ng-content>

    <!-- Dismiss icon (replaced with warning triangle if not dismissable) -->
    <span class="delete is-small" (click)="dismiss()" *ngIf="isDismissable"></span>
    <span class="icon is-small" *ngIf="!isDismissable">
        <i class="fas fa-exclamation-triangle"></i>
    </span>
</span>