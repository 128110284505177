<div class="layout-bg">
    <div class="layout-container">
        <div class="header-container">
            <img src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6263/authoring/HELIX/1594929184731/HELIX.png">
            <img src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6263/authoring/ENS/1594929007313/ENS.png">
            <hr class="solid">
        </div>
        
        <img class="certificate" src="assets/ens/certificatevector.svg">
        <div class="congratulations-container">
            <div class="congratulations">CONGRATULATIONS</div>
            <div *ngIf="!this.purchaseClicked">
                <div class="congratulations-message">You earned a personalized</div>
                <div class="congratulations-message">Certificate of Achievement!</div>
            </div>
        </div>

        <div *ngIf="this.purchaseClicked" class="fill-out-container">
            <div class="congratulations-message">Please fill out the following:</div>
        </div>

        <div *ngIf="this.purchaseClicked" class="form-container">
            <div>
                <div class="fixed-width-div">Full Name*</div>
                <input class="input-box" type="text" [formControl]="nameFc"> 
                <div *ngIf="nameFc.invalid && (nameFc.touched || nameFc.dirty)">
                    <div *ngIf="nameFc.errors.required" class="input-error">
                        Name is required.
                    </div>
                </div>
            </div>

            <div>
                <div class="fixed-width-div">E-mail*</div>
                <input class="input-box" type="text" [formControl]="emailFc"> 
                <div *ngIf="emailFc.invalid && emailFc.touched">
                    <div *ngIf="emailFc.errors.required" class="input-error">
                        E-mail is required.
                    </div>
                    <div *ngIf="emailFc.errors.email" class="input-error">
                        Invalid e-mail.
                    </div>
                </div>
            </div>
            <div>
                <div class="fixed-width-div">Access Code*</div>
                <input class="input-box" type="text" [formControl]="accessCodeFc"> 
                <div *ngIf="accessCodeFc.invalid && (accessCodeFc.touched || accessCodeFc.dirty)">
                    <div *ngIf="!invalidAccessCode && accessCodeFc.errors.required" class="input-error">
                        Access Code is required.
                    </div>
                </div>
                <div *ngIf="invalidAccessCode" class="input-error">
                    Invalid Access Code.
                </div>
            </div>
        </div>

        <div *ngIf="this.purchaseClicked" class="button-submit-container">
            <button
                [class.button-submit-disabled]="submitIsDisabled()"
                [class.button-submit-enabled]="!submitIsDisabled()" 
                class="button-submit" 
                [disabled]="submitIsDisabled()"
                (click)="onSubmitClick()">
                <span class="button-purchase-text">SUBMIT<br></span>
            </button>
        </div>

        <div *ngIf="!this.purchaseClicked" class="button-purchase-container">
            <button class="button-purchase" 
                (click)="onPurchaseClick()">
                <span class="button-purchase-text">PURCHASE CERTIFICATE<br></span>
                <span class="button-purchase-subtext">($10 + tax)</span>
            </button>
        </div>

        <div class="logo-container"><img class="logo" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/vretta-logo/1596159474108/vretta-logo.png"></div>
    </div>
</div>
