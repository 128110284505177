<div 
    class="passage-container"
    [class.numbers-align-left]="isCounterAlignLeft()"
    [class.numbers-align-right]="isCounterAlignRight()"
>
    <div class="passage" >
        <!-- todo:OPTIMIZE we should only re-render the text segments when there is a change to the text -->
        <div 
            *ngFor="let textSegment of renderTextSegments(); let i = index" 
            style="position:relative"
            [style.margin-bottom.em]="paragraphSpacing()"
        >
            <div [innerHtml]="textSegment.html"></div>
            <div 
                *ngIf="isShowCounter() && textSegment.isComplete" 
                class="passage-numbering-placer"
                [class.is-lines]="isLinesMode()"
                [class.is-paragraph]="isParagraphMode()"
                [class.align-left]="isCounterAlignLeft()"
                [class.align-right]="isCounterAlignRight()"
            >
                <span class="passage-numbering">{{renderLineCount(i)}}</span>
            </div>
        </div>
    </div>   
    <render-audio
        *ngIf="getCurrentVoiceoverUrl()"
        [url]="getCurrentVoiceoverUrl()"
        [trigger]="clickTrigger"
        [isTriggerDisabled]="!textToSpeech.isActive"
    ></render-audio> 
</div>
