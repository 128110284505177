<div style="position:relative; display:inline-block;">
    <input 
        type="number" 
        [style.width.em]="width" 
        [(ngModel)]="el[prop]"
        placeholder="(Empty)"
        [step]="step" 
        (click)="toggleSlider()"
    >
    <div *ngIf="isSliderActive" class="slider-overlay-container">
        <div class="slider-overlay">
            <input 
                type="range" 
                [(ngModel)]="el[prop]"
                placeholder="(Empty)"
                [step]="step" 
                [min]="min" 
                [max]="max" 
                (change)="change.emit()"
            >
        </div>
    </div>
</div>