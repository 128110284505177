<ng-container *ngIf="isLoggedIn" [ngSwitch]="currentView">
    <div *ngIf="showTtsToggle()" class="pj-speech-toggle">
        <mat-slide-toggle [(ngModel)]="textToSpeech.isActive" (ngModelChange)="logAction($event)" (keydown.enter)="toggleAudioButton()">
        </mat-slide-toggle>
        <tra-md audio-slug class="content" [class.has-dark-bg]="currViewHasDarkBg()" [isCondensed]="true" slug="text_to_speech_disabled" *ngIf="!textToSpeech.isActive"></tra-md>
        <tra-md audio-slug class="content" [class.has-dark-bg]="currViewHasDarkBg()" [isCondensed]="true" slug="text_to_speech_enabled" *ngIf="textToSpeech.isActive"></tra-md>
    </div>

    <pj-welcome *ngSwitchCase="View.WELCOME" 
        [isG6]="isG6" 
        [isPublic]="isPublic" 
        [isSample]="isSample()" 
        [linear]="linear" 
        [session]="session"
        (goHome)="goTo(View.HOME)" 
        >
    </pj-welcome>
    <pj-home *ngSwitchCase="View.HOME" 
        [isG6]="isG6" 
        [isPublic]="isPublic" 
        [session]="session" 
        (goToMap)="goTo(View.MAP)" 
        (goToTools)="goTo(View.TOOL)"
        >
    </pj-home>
    <pj-map *ngSwitchCase="View.MAP" 
        [isG6]="isG6" 
        [map]="map" 
        [animTriggerEvents]="animTriggerEvents" 
        [ssAttemptSlugMap]="ssAttemptSlugMap" 
        [activeSubSessionId]="activeSubSessionId" 
        [refreshSubElTrigger]="refreshSubElTrigger" 
        (enterSubSession)="enterSubSession($event)" 
        (goHome)="goTo(View.HOME)" 
        (goToCompletion)="goTo(View.COMPLETION)"
    ></pj-map>
    <ng-container *ngFor="let testId of Object.keys(trConfig)">
        <ng-container *ngSwitchCase="testId">
            <test-runner *ngIf="trConfig[testId].testForm"
                (backToMap)='goTo(View.MAP)'
                (endSection)="completeSubSession($event)"
                (questionTitles)="trConfig[testId].questionTitleMap = $event"
                (studentPosition)="setStudentPosition($event)"
                [asmtFmrk]="trConfig[testId].asmtFmrk"
                [currentTestDesign]="trConfig[testId].testForm.currentTestDesign"
                [documentItems]="trConfig[testId].asmtFmrk.referenceDocumentPages"
                [frameWorkTags]="trConfig[testId].asmtFmrk.tags"
                [helpPageItem]="trConfig[testId].asmtFmrk.helpPageId"
                [sectionIndexInit]="getSectionIndexInit(testId)"
                [questionIndexInit]="trConfig[testId].questionIndex || 0"
                [moduleIdInit]="trConfig[testId].moduleId"
                [testAttemptId]="trConfig[testId].attemptId"
                [sectionsAllowed]="trConfig[testId].sectionsAllowed"
                [isSectionControlsEnabled]="false"
                [isTimeEnabled]="!trConfig[testId].asmtFmrk.isTimerDisabled"
                [questionSrcDb]="trConfig[testId].testForm.questionSrcDb"
                [questionStates]="trConfig[testId].testForm.questionStates"
                [saveQuestion]="saveQuestionResponseForTestId(testId)"
                [submitTest]="submitTestWTestId(testId)"
                [postSubmit]="isSample()? postSubmit_SampleTest : postSubmit"
                [testFormType]="trConfig[testId].asmtFmrk.testFormType"
                [testLang]="trConfig[testId].testForm.testLang"
                [testTakerName]="testTakerName"
                [isPj]="true"
                [isG6]="isG6"
                [customConfirmTestDialogData]="getCustomConfirmTestDialogData(testId)"
                [studentG9Connection]="studentG9Connection"
                [isQuestionnaire]="trConfig[testId].isQuestionnaire"
                [currentSubSession]="currentSubSession"
                [asmtSlug]="session.slug"
                [isStudent]="true"
                [testSessionId]="session.test_session_id"
                >
            </test-runner>
        </ng-container>
    </ng-container>
    <pj-completion *ngSwitchCase="View.COMPLETION" [isPublic]="isPublic" [isSample]="isSample()" [isG6]="isG6" (goHome)="goTo(View.HOME)"></pj-completion>
    <div *ngSwitchCase="View.RESULTS">
        <external-results
            [asmtFmrk]="trConfig[currentSubSession.twtdar_order].asmtFmrk" 
            [testForm]="trConfig[currentSubSession.twtdar_order].testForm" 
            [questionTitleMap]="trConfig[currentSubSession.twtdar_order].questionTitleMap" 
            [questionScores]="trConfig[currentSubSession.twtdar_order].questionScores"
            [isPj]="true"
            [pjTwtdar]="currentSubSession.twtdar_order"
            [showReadingSelections]="true"
            exitButtonCaption="btn_continue"
            [showSections]="getShowSections()"
            [moveLogoDown]="true"
            (exitClick)="goTo(View.MAP)"
            >
        </external-results>
    </div>
</ng-container>
<ng-container>
    <div class="custom-modal" *ngIf="cModal() && !getIsStudentVerified()">
        <div class="modal-contents">
            <s-modal-verify-student
                [student]="student" 
                (closeVerifyStudentModal)="verifyStudentModalFinish()" 
            ></s-modal-verify-student>
        </div>
    </div>
</ng-container>
