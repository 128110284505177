<ng-content></ng-content>
<p class="control">
    <button class="button is-dark" ngxClipboard [cbContent]="url">
        <span class="icon is-small">
            <i class="fas fa-clipboard"></i>
        </span>
    </button>
</p>
<p class="control has-icons-left" [class.is-expanded]="isExpanded">
    <span class="icon is-small is-left">
        <i class="fas fa-link"></i>
    </span>
    <input class="input" type="text" readonly [ngModel]="url">
</p>
<p class="control">
    <a class="button is-link" (click)="openInNewTab()" >
        Open
    </a>
</p>