import * as _ from 'lodash';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MemDataPaginated, SortMode } from '../paginator/helpers/mem-data-paginated';
import { FilterSettingMode } from '../capture-filter-range/capture-filter-range.component';
import {IColumnFilter, IColumnFilterType} from '../custom-column-filter/custom-column-filter.component'; 



@Component({
  selector: 'table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit {

  @Input() filterMode?: string;
  @Input() list?: any[];
  @Input() selectedVal?: string;
  @Input() caption:string = 'Header';
  @Input() color:string = '#333333';
  @Input() id:string;
  @Input() isSortEnabled:boolean;
  @Input() disableFilter:boolean;
  @Input() disableCaptionWrap:boolean;
  @Input() minWidth:number = 0;
  @Input() ctrl:MemDataPaginated<any>;
  @Input() customFilter:IColumnFilter;
  @Output() sort = new EventEmitter();
  @Output() filter = new EventEmitter();

  constructor() { }

  isFilterActive:boolean;
  filterSetting = {};
  SortMode = SortMode;

  ngOnInit(): void {
    if (this.ctrl && this.ctrl.activeFilters && this.ctrl.activeFilters[this.id]){
      this.isFilterActive = true;
    }
    if (this.customFilter) {
      this.ctrl.activeFilters[this.id] = {
        mode: FilterSettingMode.VALUE,
        config: { value: null }
      }
    }
  }

  toggleSort(){
    if (this.isSortEnabled && this.ctrl && this.id){
      this.ctrl.toggleSortSetting(this.id);
    }
  }

  toggleFilter(){
    this.isFilterActive = !this.isFilterActive;
    if (this.ctrl && this.id){
      const filterModel = this.getFilterModel();
      if (!this.isFilterActive){
        filterModel[this.id] = null;
        if (this.customFilter) {
          const filter = this.customFilter;
          if (filter.type === IColumnFilterType.BOOLEAN_LIST) {
            filter.options.forEach(option => {
              option.checked = false;
            });
          }
        }        
      }else{
        if(this.filterMode){
          if(this.filterMode == 'LIST'){
            this.ctrl.activeFilters[this.id] = {
              mode: FilterSettingMode.LIST,
              config: {list: this.list, selectedValue: this.selectedVal}
            }
          }
        }
      }
      if (this.customFilter) {
      }
      this.updateFilter();
    }
  }

  getFilterModel(){
    if (this.ctrl){
      return this.ctrl.activeFilters;
    }
  }


  updateFilter = _.debounce(() => {
    if (this.ctrl && this.id) {
      this.ctrl.refreshFilters();
      this.filter.emit();
    }
  }, 500, {leading: true});

  isSorted(){
    return !!this.getSortType();
  }

  getSortType(){
    if (this.ctrl && this.id){
      return this.ctrl.getSortSettingMode(this.id);
    }
  }

}
