import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LangService } from 'src/app/core/lang.service';
import { G9DemoDataService } from '../../../ui-schooladmin/g9-demo-data.service';
import { RoutesService } from 'src/app/api/routes.service';
import { AuthService } from 'src/app/api/auth.service';
import { LoginGuardService } from 'src/app/api/login-guard.service';
import { ISSUE_REVIEW_CATEGORY } from '../../../ui-schooladmin/data/types';

enum AppealStatus {
  None = 'None',
  Review = 'Review',
  Approved = 'Approved',
  Declined = 'Declined',
}
@Component({
  selector: 'sa-reports-appeal',
  templateUrl: './sa-reports-appeal.component.html',
  styleUrls: ['./sa-reports-appeal.component.scss']
})
export class SaReportsAppealComponent implements OnInit {

  @Input() testWindowID:number;

  constructor(
    public lang: LangService,
    public auth: AuthService,
    private routes: RoutesService,
    private g9demoService: G9DemoDataService,
    private loginGuard: LoginGuardService,
  ) { }

  public searchStudentReportForm = new FormGroup({
    student_oen: new FormControl(),
    student_first_name: new FormControl(),
    student_last_name: new FormControl(),
  });

  student;
  appeal_reason
  isGraduatingThisYear = false
  
  ngOnInit(): void {
  }
  
   /**",
   * Call the API to get the student Report Records and stored in this.student
   * @param none
   * @returns none
   */
  searchStudentReport(){
    const id = -1 // no id is needed
    
    const additonalParams = {
      studentIdentifier:this.searchStudentReportForm.controls.student_oen.value, // optional
      studentFirstName:this.searchStudentReportForm.controls.student_first_name.value, // optional
      studentLastName:this.searchStudentReportForm.controls.student_last_name.value, // optional
    }
    const params = this.configureQueryParams(additonalParams);

    this.auth.apiGet(this.routes.SCHOOL_ADMIN_APPEALS, id, params)
      .then(res => {
        this.student =res;
      })
      .catch(error => {
        this.handleAPICallErrMsg(error.message)
      })
  }

  /**",
   * convert overalls from value to translated string
   * @param overalls
   * @returns translated string
   */
  convertOveralls(overalls){
    switch(overalls){
      case '1':
        return this.lang.tra("sa_report_appeal_successful")
      case '0':
        return this.lang.tra("sa_report_appeal_not_yet_successful")
      default:
        return overalls
    }
  }

    /**",
   * convert reported issue and appeal status
   * @param reported_issue
   * @returns string
   */
    convertIssueStatus(reported_issue){
      let reportedIssueStatus = ""
      if(!reported_issue.is_appeal){ // when reported_issue is not appeal use "is resovled"  for issue status
        if(!!reported_issue.resolved){
          reportedIssueStatus = this.lang.tra('issues_archived_caption')
        }else{
          reportedIssueStatus = this.lang.tra('auth_unresolved')
        }
      }else{ //when reported_issue is appeal use "appeal_status" for issue status
        switch(reported_issue.appeal_status){
          case AppealStatus.Approved:
            reportedIssueStatus = this.lang.tra('sa_report_appeal_approved')
            break;
          case AppealStatus.Declined:
            reportedIssueStatus = this.lang.tra('sa_report_appeal_declined')
            break;  
          case AppealStatus.Review:
          default:
            reportedIssueStatus = this.lang.tra('sa_report_appeal_under_review')
            break;
        }
      }
      return reportedIssueStatus
    }

  /**",
   * get the student's reported issue count
   * @param none
   * @returns number
   */
  getStudentAppealReportedIssueCount(){
    return { reported_issue_count: this.student.reported_issues.length || 0 }
  }


  /**",
   * click to open/close the student reported issues info
   * @param none
   * @returns none
   */
  expandReportIssues(){
    if(this.student){
      if (!this.student.hasOwnProperty('showReportedIssue')) {
        this.student['showReportedIssue'] = false;
      }
      this.student['showReportedIssue']  = !this.student['showReportedIssue'] 
    }
  }

  getTranslatedReportCategory(category){
    switch(category){
      case ISSUE_REVIEW_CATEGORY.TECH_SUPPORT:
        return this.lang.tra("lbl_report_issue_technologyIssue")
      case ISSUE_REVIEW_CATEGORY.RESPONSE_ISSUE:
        return this.lang.tra("lbl_report_issue_studentResponseIssue")
      case ISSUE_REVIEW_CATEGORY.ANOMALY:
        return this.lang.tra("lbl_report_issue_administrationAnomalies")
      case ISSUE_REVIEW_CATEGORY.APPEAL:
        return this.lang.tra("lbl_outstanding_appeals")   
      default:
        return category
    }
  }

  /**",
   * call the API to request the appeal for the student
   * @param none
   * @returns none
   */
  sentAppealRequest(){
    //throw errors if reason is empty
    if(!this.appeal_reason || this.appeal_reason.trim().length === 0){
      this.handleAPICallErrMsg("APPEAL_REASON_EMPTY")
      return
    }

    //throw error if student report is not allow for appeal
    if(!this.student.is_appeal_allow){
      this.handleAPICallErrMsg("APPEAL_NOT_ALLOW")
      return
    }

    //throw error if student already request appeal
    if(this.student.appeal_existed){
      this.handleAPICallErrMsg("APPEAL_EXISTED")
      return
    }

    this.loginGuard.confirmationReqActivate ({
      caption: this.lang.tra("sa_appeal_request_confirm_sent"),
      confirm: () => {
        const data = {
          student_uid: this.student.uid,
          report_ids: this.student.sr_ids.split(","),
          reason: this.appeal_reason,
          graduating: this.isGraduatingThisYear
        } 
        const params = this.configureQueryParams();
        this.auth.apiCreate(this.routes.SCHOOL_ADMIN_APPEALS, data, params)
          .then(res => {
            this.loginGuard.quickPopup(this.lang.tra("sa_appeal_request_sent"))
            this.student.reported_issues = res;
          })
          .catch(error => {
            this.handleAPICallErrMsg(error.message)
          })
      }, 
      close: () => {
        //Do nothing.
      }, 
    })
  }

  /**",
   * API call default params
   * @param additonalParams
   * @returns {query:{}}
   */
  configureQueryParams(additonalParams = {}){
    return {
      query : {
        schl_group_id: this.g9demoService.schoolData.group_id,
        test_window_id: this.testWindowID,
        ...additonalParams
      }
    }
  }

  /**",
   * handle all the error returns from the API call
   * @param message  API returns error message
   * @returns pop up string that explained the error 
   */
  handleAPICallErrMsg(message){
    switch(message){
      case 'APPEAL_REASON_EMPTY':
        this.loginGuard.quickPopup(this.lang.tra("sa_appeal_request_reason_empty"))
        break;
      case 'APPEAL_NOT_ALLOW':
        this.loginGuard.quickPopup(this.lang.tra("sa_appeal_request_not_allow"))
        break;
      case 'APPEAL_EXISTED':
        this.loginGuard.quickPopup(this.lang.tra("sa_appeal_request_existed"))
        break;          
      case 'STUDENT_NOT_FOUND':
        this.loginGuard.quickPopup(this.lang.tra("sa_appeal_search_student_not_found"))
        break;
      case 'TOO_MANY_MATCH_STUDENT':
        this.loginGuard.quickPopup(this.lang.tra("sa_appeal_search_student_match_too_many"))
        break;
      default:
        this.loginGuard.quickPopup(message)
        break
    }
  }

}
