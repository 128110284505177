<div class="split-example ex-pixel" id="page" [ngStyle]="{'font-size': zoomLevel+'em'}" aria-hidden="false" aria- #page>
    <as-split unit="pixel" direction="horizontal" gutterSize="30" (dragEnd)="log($event)" style="height:100vh;">
        <as-split-area [size]="readingSelectionWidth" minSize="300">
            <div class="simulated-question-text" style="min-width:20em">

                <div style="margin-bottom:1.5em; width:100%; display: flex; align-items: center; justify-content: space-between;">
                    <div style="display: flex; align-items: center; ">
                        <button class="button btn is-small is-grey" id="instr-btn" aria-controls="modal1" tabindex="0" (click)="toggleInstructions()" #popupBtn>Instructions</button>
                    </div>
                    <div style="display: flex; align-items: center;">
                        <span style="font-size: 1rem;" *ngIf="zoomLevel !== 1">{{zoomLevel*100}}%</span>
                        <button class="button btn is-small" (click)="zoomLevel=zoomLevel+0.25" aria-disabled="false" [disabled]="isMaxZoom(zoomLevel)">Zoom In</button>
                        <button class="button btn is-small" (click)="zoomLevel=zoomLevel-0.25" aria-disabled="false" [disabled]="isMinZoom(zoomLevel)">Zoom Out</button>
                    </div>
                </div>
                <p aria-roledescription="Questiontitle">High-efficiency appliances and fixtures can help reduce the amount of water we use.</p>
                <div style="padding:1em; border-radius:2em; border:0.15em solid #1a6eb7; margin-top:0.5em; margin-bottom:0.5em;">
                    <div style="font-weight: 600; text-align: center; line-height: 110%; font-size: 1.2em; color: #1a6eb7;">
                        Indoor Water Use with<br> High-Efficiency Appliances and Fixtures
                    </div>
                    <img alt="image alt text" style="width: 32em;" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/Screen%20Shot%202020-08-28%20at%208.07.52%20PM/1598661582381/Screen%20Shot%202020-08-28%20at%208.07.52%20PM.png">
                </div>
                <div>
                    <p>You want to reduce your personal water use to 1050 L/week. You install high-efficiency appliances and fixtures, and change your water-use habits.</p>
                    <p>Plan a water budget for yourself for 1 week that meets this goal using the high-efficiency appliances and fixtures.</p>
                    <p>Explain and justify your solution.</p>
                    <p>You must use everything in the table below at least once in the week. </p>
                </div>

                <table class="temp-stim-table">
                    <tr>
                        <th style="background-color: #d0e2f2"> High-Efficiency Appliances and Fixtures </th>
                    </tr>
                    <tr>
                        <td>
                            <div class="cell-content">
                                <div class="image-container">
                                    <img src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/Screen%20Shot%202020-08-31%20at%2012.29.04%20PM/1598891373342/Screen%20Shot%202020-08-31%20at%2012.29.04%20PM.png">
                                </div>
                                Shower and/or bath
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="cell-content">
                                <div class="image-container">
                                    <img src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/Screen%20Shot%202020-08-31%20at%2012.29.08%20PM/1598891443509/Screen%20Shot%202020-08-31%20at%2012.29.08%20PM.png">
                                </div>
                                Toilet
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="cell-content">
                                <div class="image-container">
                                    <img src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/Screen%20Shot%202020-08-31%20at%2012.29.11%20PM/1598891458160/Screen%20Shot%202020-08-31%20at%2012.29.11%20PM.png">
                                </div>
                                Tap
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="cell-content">
                                <div class="image-container">
                                    <img src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/Screen%20Shot%202020-08-31%20at%2012.29.14%20PM/1598891469718/Screen%20Shot%202020-08-31%20at%2012.29.14%20PM.png">
                                </div>
                                Dishwasher
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="cell-content">
                                <div class="image-container">
                                    <img src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/Screen%20Shot%202020-08-31%20at%2012.29.19%20PM/1598891479216/Screen%20Shot%202020-08-31%20at%2012.29.19%20PM.png">
                                </div>
                                Clothes Washer
                            </div>
                        </td>
                    </tr>
                </table>
                <p>This question can be answered on paper or in the space provided on the right. </p>
            </div>
        </as-split-area>
        <as-split-area size="*" minSize="300" style="position:relative;overflow-x:scroll;" id="split-area">
            <div class="screen" id="screen">
                <h1 *ngIf="!review" class="block-inserters" style="margin-top:2em; font-size:1em;">
                    Use the buttons below to respond to the question using text, tables, or freeform drawings.
                </h1>
                <h1 *ngIf="review" class="block-inserters" style="margin-top:2em; font-size:1em; padding-left:1em" aria-label="Review mode">
                    <strong>Document is now on review mode and can not be edited.Click on "cancel" below to exit.</strong>
                </h1>

                <div class="blocks" cdkDropList (cdkDropListDropped)="drop(blocks, $event);">
                    <div *ngFor="let block of blocks; let index = index;" cdkDrag id="blocks">
                        <div class="solution-block" id="{{ 'block' + index }}">
                            <button *ngIf="!review" class="button is-white is-handle" cdkDragHandle aria-label="DragBlock">
              <i class="fas fa-sort" aria-hidden="true"></i>
            </button>
                            <div class="solution-block-content" [ngSwitch]="block.blockType">
                                <div *ngSwitchCase="BlockTypes.TEXT" [class.hide-ckeditor-toolbar]="!block.isInFocus" #text class="ckdiv">
                                    <!-- <element-render-text-constr></element-render-text-constr> -->
                                    <ckeditor [editor]="Editor" [config]="ckEditorConfig" (ready)="onReady($event)" (focus)="block.isInFocus=true" (blur)="block.isInFocus=false"></ckeditor>
                                </div>
                                <div class=""></div>
                                <div *ngSwitchCase="BlockTypes.MATH">
                                    <capture-math></capture-math>
                                </div>
                                <div *ngSwitchCase="BlockTypes.TABLE" #table id="{{ 'tableBlock' + index }}">
                                    <table class="table-input" id="table">
                                        <tr *ngIf="block.isEditingTable">
                                            <td></td>
                                            <td *ngFor="let cell of block.rows[0].cells; let col_i = index;" class="edit-cell">
                                                <button *ngIf="!review" class="button  is-small " (click)="removeColumn(block, col_i)">
                        <i class="fa fa-trash" aria-hidden="true" aria-label="DeleteBlock"></i>
                      </button>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let row of block.rows; let row_i = index;">
                                            <td *ngIf="block.isEditingTable" class="edit-cell">
                                                <button *ngIf="!review" class="button is-small " (click)="removeRow(block, row_i)">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </button>
                                            </td>
                                            <td *ngFor="let cell of row.cells">
                                                <textarea 
                                                    cdkTextareaAutosize 
                                                    [cdkTextareaAutosize]="true" 
                                                    [cdkAutosizeMinRows]="2"
                                                    [readonly]="review"
                                                ></textarea>
                                            </td>
                                        </tr>
                                        <tr>
                                    </table>
                                    <!-- <img *ngIf="review" [src]="imgCanvas"> -->
                                    <div style="display:flex; flex-direction:row; justify-content: space-between;">
                                        <div>
                                            <div *ngIf="block.isEditingTable">
                                                <button (click)="insertRow(block)" class="button is-small">Add Row</button>
                                                <button (click)="insertColumn(block)" class="button is-small">Add Column</button>
                                            </div>
                                        </div>
                                        <button *ngIf="!review" (click)="block.isEditingTable = !block.isEditingTable" class="button is-white is-small show-on-hover">
                      <i class="fas fa-cog"></i>
                    </button>
                                    </div>
                                </div>
                                <div *ngSwitchCase="BlockTypes.DIAGRAM" [ngClass]="{'drawing': review === false}" #diagram>
                                    <element-render-drawing [mode]="mode" [id]="index"></element-render-drawing>
                                </div>
                            </div>
                            <button *ngIf="!review" class="button is-white is-handle" (click)="removeElement(blocks, index)">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="!review" #constrRespInput class="buttons block-inserters">
                    <button *ngFor="let block of availableBlocks" [disabled]="isBlockInsertBuffered(block.id)" (click)="insertBlock(block.id)" class="button btn block-selector">
              <div class="icon"><i  [ngClass]="block.icon"></i></div>
              <div> {{block.caption}} </div>
            </button>
                </div>
                <div *ngIf="blocks.length && !review" style="margin:0 5em;">
                    <hr>
                    <div>
                        <button class="button is-info" (click)="toggleReview()">Review</button>
                        <!-- <button class="button" (click)="showNext()">Next Question</button> -->
                    </div>
                </div>


            </div>

            <div *ngIf="review" class="review">
                <div class="review__content">
                    <div style="margin:0 5em;">
                        <hr>
                        <div>
                            <button class="button is-info" (click)="toggleReview()">Cancel</button>
                            <button class="button" (click)="showNext()">Next Question</button>
                        </div>
                    </div>
                </div>
            </div>
        </as-split-area>
    </as-split>
</div>

<button *ngIf="removeStack.length > 0 && !review" class="undo_btn" (click)="undo()">Undo <i class="fas fa-undo-alt"></i></button>
<!---Instructions modal box begins here-->
<div class="instr-popup" #popup id="modal1" aria-modal="true" role=”dialog” aria-hidden="true">
    <div class="instr-popup__box">
        <div class="instr-popup__box--header">
            <div class="intsr-popup__box--header-contents" style="width: 100%;height: 100%;display: flex;align-items: center; justify-content: space-between;font-size: 1rem;">
                <h1 style="color:whitesmoke;">Editor Instructions</h1>
                <button style="border:none; background:transparent;" (click)="removeInstructions()" aria-label="closemodal" tabindex="0">
          <i class="fas fa-times cancel"  style="font-size:1.6rem; color:whitesmoke; cursor:pointer;"></i>
         </button>
            </div>
        </div>
        <div class="instr-popup__box--body">
            <p>Below is a list of the most important keystrokes supported by the text editor and its features:</p>
            <table>
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>PC</th>
                        <th>Mac</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Copy</td>
                        <td><kbd>Ctrl</kbd> + <kbd>C</kbd></td>
                        <td><kbd>&#x2318;</kbd> + <kbd>C</kbd></td>
                    </tr>
                    <tr>
                        <td>Paste</td>
                        <td><kbd>Ctrl</kbd> + <kbd>V</kbd></td>
                        <td><kbd>&#x2318;</kbd> + <kbd>V</kbd></td>
                    </tr>
                    <tr>
                        <td>Undo</td>
                        <td><kbd>Ctrl</kbd> + <kbd>Z</kbd></td>
                        <td><kbd>&#x2318;</kbd> + <kbd>Z</kbd></td>
                    </tr>
                    <tr>
                        <td>Redo</td>
                        <td><kbd>Ctrl</kbd> + <kbd>Y</kbd> <br> <kbd>Ctrl</kbd> + <kbd>Shift</kbd> + <kbd>Z</kbd></td>
                        <td><kbd>&#x2318;</kbd> + <kbd>Y</kbd> <br> <kbd>&#x2318;</kbd> + <kbd>Shift</kbd> + <kbd>Z</kbd></td>
                    </tr>
                    <tr>
                        <td>Bold</td>
                        <td><kbd>Ctrl</kbd> + <kbd>B</kbd></td>
                        <td><kbd>&#x2318;</kbd> + <kbd>B</kbd></td>
                    </tr>
                    <tr>
                        <td>Italic</td>
                        <td><kbd>Ctrl</kbd> + <kbd>I</kbd></td>
                        <td><kbd>&#x2318;</kbd> + <kbd>I</kbd></td>
                    </tr>
                    <tr>
                        <td>Link</td>
                        <td><kbd>Ctrl</kbd> + <kbd>K</kbd></td>
                        <td><kbd>&#x2318;</kbd> + <kbd>K</kbd></td>
                    </tr>
                    <tr>
                        <td>Insert a hard break (e.g. a new paragraph)</td>
                        <td colspan="2"><kbd>Enter</kbd></td>
                    </tr>
                    <tr>
                        <td>Insert a soft break (i.e. a <code>&lt;br&gt;</code>)</td>
                        <td colspan="2"><kbd>Shift</kbd> + <kbd>Enter</kbd></td>
                    </tr>
                    <tr>
                        <td>Nest the current list item (when in a list)</td>
                        <td colspan="2"><kbd>Tab</kbd></td>
                    </tr>
                    <tr>
                        <th colspan="3">When a widget is selected (for example: image, table, horizontal line, etc.)</th>
                    </tr>
                    <tr>
                        <td>Insert a new paragraph directly after a widget</td>
                        <td colspan="2"><kbd>Enter</kbd></td>
                    </tr>
                    <tr>
                        <td>Insert a new paragraph directly before a widget</td>
                        <td colspan="2"><kbd>Shift</kbd> + <kbd>Enter</kbd></td>
                    </tr>
                    <tr>
                        <td>Display the caret to allow typing directly before a widget</td>
                        <td colspan="2"><kbd>&#x2191;</kbd> / <kbd>&#x2190;</kbd></td>
                    </tr>
                    <tr>
                        <td>Display the caret to allow typing directly after a widget</td>
                        <td colspan="2"><kbd>&#x2193;</kbd> / <kbd>&#x2192;</kbd></td>
                    </tr>
                    <tr>
                        <th colspan="3">In a table cell</th>
                    </tr>
                    <tr>
                        <td>Move the selection to the next cell</td>
                        <td colspan="2"><kbd>Tab</kbd></td>
                    </tr>
                    <tr>
                        <td>Move the selection to the previous cell</td>
                        <td colspan="2"><kbd>Shift</kbd> + <kbd>Tab</kbd></td>
                    </tr>
                    <tr>
                        <td>Insert a new table row (when in the last cell of a table)</td>
                        <td colspan="2"><kbd>Tab</kbd></td>
                    </tr>
                    <tr>
                        <td>Navigate through the table</td>
                        <td colspan="2"><kbd>&#x2191;</kbd> / <kbd>&#x2192;</kbd> / <kbd>&#x2193;</kbd> / <kbd>&#x2190;</kbd></td>
                    </tr>
                </tbody>
            </table>
            <h2 id="user-interface-and-navigation"><a class="headerlink" href="#user-interface-and-navigation">#</a> User interface and navigation</h2>
            <p>Use the following keystrokes for more efficient navigation in the CKEditor 5 user interface:</p>
            <table>
                <thead>
                    <tr>
                        <th>Action</th>
                        <th>PC</th>
                        <th>Mac</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Close contextual balloons and UI components like dropdowns</td>
                        <td colspan="2"><kbd>Esc</kbd></td>
                    </tr>
                    <tr>
                        <td>Move focus to the visible contextual balloon</td>
                        <td colspan="2"><kbd>Tab</kbd></td>
                    </tr>
                    <tr>
                        <td>Move focus between fields (inputs and buttons) in contextual balloons</td>
                        <td colspan="2"><kbd>Tab</kbd></td>
                    </tr>
                    <tr>
                        <td>Move focus to the toolbar</td>
                        <td><kbd>Alt</kbd> + <kbd>F10</kbd></td>
                        <td><kbd>Alt</kbd> + <kbd>F10</kbd> <br> (may require <kbd>Fn</kbd>)</td>
                    </tr>
                    <tr>
                        <td>Navigate through the toolbar</td>
                        <td colspan="2"><kbd>&#x2191;</kbd> / <kbd>&#x2192;</kbd> / <kbd>&#x2193;</kbd> / <kbd>&#x2190;</kbd></td>
                    </tr>
                    <tr>
                        <td>Execute the currently focused button</td>
                        <td colspan="2"><kbd>Enter</kbd></td>
                    </tr>
                </tbody>
            </table>
            <style>
                .keyboard-shortcuts th {
                    text-align: center;
                }
                
                .keyboard-shortcuts td:nth-of-type(1) {
                    text-align: right;
                }
                
                .keyboard-shortcuts td:nth-of-type(2),
                .keyboard-shortcuts td:nth-of-type(3) {
                    width: 30%;
                }
            </style>
        </div>
    </div>
</div>

<!-- End of modal -->