<div class="assessment-sessions-view">
    <p><tra slug="txt_board_it_monitoring"></tra></p>
     <h2><tra slug="title_sessions"></tra></h2>
     <div class="filter-container">
        <!-- <filter-toggles 
          [state]="myBoard.classFilterToggles"
          (id)="setClassFilter($event)"
        ></filter-toggles> -->
        <filter-toggles 
          [state]="classFilterToggles"
          (id)="setClassFilter($event)"
        ></filter-toggles>
        <button *ngIf="currentClassFilter" class="button is-small has-icon" style="margin-right: 1em; margin-top: 0.5em;" (click)="exportMonitoringViewModal()">
            <span class="icon"><i class="fas fa-table"></i></span>
            <span><tra slug="sa_board_it_export"></tra></span>
        </button>
    </div>
    <div *ngIf="!currentClassFilter">
        <tra-md slug="txt_guide_select_toggle_for_sessions"></tra-md>
    </div>
    <div style="margin-top:1em;" *ngIf="currentClassFilter">
        <paginator *ngIf="isLoaded" [model]="sessionsTable.getPaginatorCtrl()" [page]="sessionsTable.getPage()" [numEntries]="sessionsTable.numEntries()"></paginator>
        <div class="sessions-table-container">
            <table *ngIf="isLoaded" style="overflow-x: scroll;" class="sessions-table table is-hoverable">
                <tr>
                    <th class="flush"> <table-header id = "status"       [caption] = "columnLabels.status"         [ctrl] = "sessionsTable" [isSortEnabled]="true"></table-header><!--Status--> </th>
                    <th class="flush"> <table-header id = "school"  [caption] = "columnLabels.school"   [ctrl] = "sessionsTable" [isSortEnabled]="true"></table-header><!--Invigilator(s)--> </th>
                    <th class="flush"> <table-header id = "invigilator"  [caption] = "columnLabels.invigilators"   [ctrl] = "sessionsTable" [isSortEnabled]="true"></table-header><!--Invigilator(s)--> </th>
                    <th class="flush"> <table-header id = "classroom_id" [caption] = "columnLabels.classroom"      [ctrl] = "sessionsTable" [isSortEnabled]="true"></table-header><!--Classroom--> </th>
                    <th class="flush"> <table-header id = "students"     [caption] = "columnLabels.students"       [ctrl] = "sessionsTable" [isSortEnabled]="true"></table-header><!--Students--> </th>
                    <th class="flush"> <table-header id = "times"        [caption] = "columnLabels.times"          [ctrl] = "sessionsTable" [isSortEnabled]="true"  [disableFilter]="true"></table-header><!--Times--> </th>
                    <th class="flush"> <table-header id = "submissions"        [caption] = "columnLabels.submissions"          [ctrl] = "sessionsTable" [isSortEnabled]="true"  [disableFilter]="true"></table-header><!--Times--> </th>
                </tr>
                <tr *ngFor="let session of sessionsTable.getCurrentPageData();">
                    <td class="status">
                        <div class="content">
                            <span class="fa fa-circle" [ngClass]="session.status">
                            </span>{{columnStatusLabel[session.status]}}
                        </div>
                    </td>
                    <td>{{session.school_name}}</td>
                    <td>{{session.invigilator}}</td>
                    <td> {{session.classCode}} </td>
                    <td class="studentId"> 
                {{session.students}}
                    </td>
                    <td> 
                        <div>{{ session.startTime }}</div>
                        <div>{{ session.endTime }}</div>
                    </td>
                    <td> 
                        <div>{{ session.submissions }}</div>
                    </td>

                </tr>
            </table>
        </div>
    </div>
</div>
