import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { AuthService, IUserInfo } from '../../api/auth.service';
import { RoutesService } from '../../api/routes.service';
import { LangService } from "../../core/lang.service";
import { TWAttr, TWMainMenuTab } from '../../ui-testctrl/view-tc-test-window/view-tc-test-window.component';
import { PageModalController, PageModalService } from '../../ui-partial/page-modal.service';
import { MyBoardService } from '../my-board.service';
import { DIST_ADMIN_TECH_READ_CHECKLIST, DIST_ADMIN_TECH_READ_CHECKLIST_PJ, CheckingCaptionStatus, NetworkProtocol } from './data/checklist';
import { ICheckListItem, SecureLinkSlug } from './data/types';
import { ClassFilterId } from '../../ui-schooladmin/my-school.service';
import { LoginGuardService } from '../../api/login-guard.service';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, first } from 'rxjs/operators';

const FORCE_DISABLE_UNCHECK_WARNING = true; // feature flag

enum TechReadinessModal {
  NEW = 'NEW',
  WARN = 'WARN',
  TOGGLEWARN = 'TOGGLEWARN'
}

const checklist = {};


@Component({
  selector: 'sb-tech-readiness',
  templateUrl: './sb-tech-readiness.component.html',
  styleUrls: ['./sb-tech-readiness.component.scss']
})
export class SbTechReadinessComponent implements OnInit {
  @Output() readinessStatus = new EventEmitter();
  @Input() boardInfo;
  
  constructor(
    public lang: LangService,
    public auth: AuthService,
    private routes: RoutesService,
    private loginGuard: LoginGuardService,
    private pageModalService: PageModalService,
    public myBoard: MyBoardService,
    private httpClient: HttpClient,
  ) { }
  
  CheckingCaptionStatus = CheckingCaptionStatus
  TechReadinessModal = TechReadinessModal
  checklist = checklist;
  techReadinessConfirmed: boolean = false;
  pageModal: PageModalController;
  currentSecureLink;
  isShowSebPass: boolean;
  isShowKioskPass: boolean;

  // ICheckListItem
  list: any[] = DIST_ADMIN_TECH_READ_CHECKLIST;
  pjList: any[] = DIST_ADMIN_TECH_READ_CHECKLIST_PJ;
  primaryList:any[];
  juniorList:any[];
  ossltList:any[];
  ngOnInit(): void {
    this.pageModal = this.pageModalService.defineNewPageModal();
    this.loadChecklists();
    this.initializeOSSLTList();
    this.initializePrimaryList();
    this.initializeJuniorList();
  }

  loadChecklists() {
    this.myBoard.loadChecklistStates(this.boardInfo.group_id)
      .then(state => {
        this.checklist = state;
        this.verifyChecklistCompletion()
      })
  }
  
  initializePrimaryList(){
    this.primaryList = this.pjList.map(v => {
      return {...v, id: v.id+'_primary'};
    });
  }

  initializeJuniorList(){
    this.juniorList = this.pjList.map(v => {
      return {...v, id: v.id+'_junior'};
    });
  }

  initializeOSSLTList(){
    this.ossltList = this.list.map(v => {
      return {...v, id: v.id+'_osslt'};
    });
  }

  currentClassFilter;
  suffix = '';
  setClassFilter(filterId){
    this.currentClassFilter = filterId;
    if (this.currentClassFilter){
      switch(this.currentClassFilter){
        case ClassFilterId.Primary:    this.suffix = '_primary'; this.verifyChecklistCompletion(); break;
        case ClassFilterId.Junior:    this.suffix = '_junior'; this.verifyChecklistCompletion(); break;
        case ClassFilterId.G9:    this.suffix = ''; this.verifyChecklistCompletion(); break;
        case ClassFilterId.OSSLT: this.suffix = '_osslt'; this.verifyChecklistCompletion(); break;
      }
    }
  }

  cModal() {
    return this.pageModal.getCurrentModal();
  }

  parseSecureLinkPayload(slug: SecureLinkSlug, payload: any) {
    // console.log('parseSecureLinkPayload', slug, payload)
    switch (slug) {
      case SecureLinkSlug.SEB_CONFIG: return { link: payload.seb.config };
      case SecureLinkSlug.SEB_CONFIG_PASS: return { text: payload.seb.creds };
      case SecureLinkSlug.KIOSK_POLICY: return { link: payload.kiosk.config };
      case SecureLinkSlug.KIOSK_POLICY_CREDS: return { text: payload.kiosk.creds };
    }
  }

  verifyChecklistCompletion = () => {
    let isAnyUnchecked = false;
    if (this.currentClassFilter === ClassFilterId.Primary){
      this.primaryList.forEach(listEntry => {
        if (!this.checklist[listEntry.id]) {
          isAnyUnchecked = true;
        }
      })
    }
    else if (this.currentClassFilter === ClassFilterId.Junior){
      this.juniorList.forEach(listEntry => {
        if (!this.checklist[listEntry.id]) {
          isAnyUnchecked = true;
        }
      })
    }
    else if (this.currentClassFilter === ClassFilterId.OSSLT){
      this.ossltList.forEach(listEntry => {
        if (!this.checklist[listEntry.id]) {
          isAnyUnchecked = true;
        }
      })
    }
    else{
      this.list.forEach(listEntry => {
        if (!this.checklist[listEntry.id]) {
          isAnyUnchecked = true;
        }
      })
    }
    this.techReadinessConfirmed = !isAnyUnchecked; // todo, this is still poorly named
    this.readinessStatus.emit(this.techReadinessConfirmed)
  }
  
  /**
   * Toggle the slug item in the check list
   * Prompt a warning before toggle it if its required
   * @param slug
   */
  toggleCheckList(slug: string) {
    if (!this.techReadinessConfirmed || FORCE_DISABLE_UNCHECK_WARNING){
      if(this.warningBeforeToggle(slug)){
        const config = {}
        this.pageModal.newModal({
          type: this.TechReadinessModal.TOGGLEWARN,
          config,
          finish: () => this.warnModalFinish(slug)
        });
      }else{
        this.completeChecklistToggle(slug)
      }
    }
    else{
      const config = {}
      // this.loginGuard.confirmationReqActivate({
      //   caption: 'WARING',
      //   confirm: () => this.warnModalFinish(slug)
      // })
      this.pageModal.newModal({
        type: this.TechReadinessModal.WARN,
        config,
        finish: () => this.warnModalFinish(slug)
      });
    }
  }

  /**
   * Check if the item need a warning before they toggle it.
   * Case 1. Domain Exclusion toggle need warning when user manually toggle it if 
   *         1.1 its not toggled and
   *         1.2 one of the connection test fail
   * @param slug 
   * @returns 
   */
  warningBeforeToggle(slug){
    //Case 1. Domain Exclusion toggle
    const domainExcludeItemId = "board_tech_redi_txt_2"  // domainExclusion ID
    if(slug.includes(domainExcludeItemId)){
      const domainExcludeAllPass = this.itemCheckAllPass(domainExcludeItemId)
      const domainExclusionToggled = this.checklist[slug] // domainExclusion toggled
      if(!domainExclusionToggled && !domainExcludeAllPass){
        return true
      }
    }

    //default:
    return false // not sending warning as default
  }
 
  warnModalFinish(slug:string) {
    this.completeChecklistToggle(slug)
    this.pageModal.closeModal()
  }

  renderChecklistId(item, option) {
    return item.id+this.suffix + '/' + option.slug
  }

  completeChecklistToggle(slug:string){
    const newVal = !this.checklist[slug];
    this.myBoard
      .toggleTRChecklist(slug, newVal, this.boardInfo.group_id)
      .then(() => {
        this.checklist[slug] = newVal;
        this.verifyChecklistCompletion()
      });
  }

  isPJ(){
    return this.currentClassFilter === ClassFilterId.Primary || this.currentClassFilter === ClassFilterId.Junior;
  }

  isG9Osslt(){
    return this.currentClassFilter === ClassFilterId.G9 || this.currentClassFilter === ClassFilterId.OSSLT;
  }

  /**
   * Check if the toggle need to be disabled. false as default
   * Case 1. Domain Exclusion is disabled until "Test" is pressed if its un-toggled
   * @param slug 
   * @returns boolean
   */
  listItemDisableCheck(slug:string){
    //Case 1. Domain Exclusion Disabled until "Test" is pressed
    const domainExcludeItemId = "board_tech_redi_txt_2"  // domainExclusion ID
    if(slug.includes(domainExcludeItemId)){
      const itemList = this.currentItemList()// OSSLT check list of PJ check List
      const domainExclusionItem = itemList.find(item => item.id === domainExcludeItemId)
      const domainExclusionToggled = this.checklist[slug] // domainExclusion toggled
      if(!domainExclusionToggled && !domainExclusionItem?.captionChecked){
        return true
      }
    }

    //default:
    return false // not disable as default
  }

  /**
   * @returns this.list or this.pjList 
   */
  currentItemList(){
    let itemList
    if(this.isG9Osslt()){
      itemList = this.list
    }
    if(this.isPJ()){
      itemList = this.pjList
    }
    return itemList
  }

  /**
   * Test all the link in the domain exclusion
   */
  async testDomainExclusion(){
    const itemList = this.currentItemList()
    const domainExcludeItemId = "board_tech_redi_txt_2"  // domainExclusion ID

    //Async Check all domains in the list 
    const domainExclusionItem = itemList.find(item => item.id === domainExcludeItemId)
    const exclustionDomains = domainExclusionItem.captionChecks
    await Promise.all(exclustionDomains.map(async domain =>{
      domain.status = CheckingCaptionStatus.CHECKING
      let testingUrl;
      if(domain.signed){
        const data = {
          filePath: domain.filePath,
          expireSeconds: 60, 
          bucket: domain.bucket
        }
        testingUrl = await this.auth.apiCreate(this.routes.DIST_ADMIN_SIGNED_URL, data, this.configureQueryParams())
      }else{
        testingUrl = domain.url
      }
      const validUrl = await this.validateUrl(testingUrl, domain.protocol )
      if(validUrl){
        domain.status = CheckingCaptionStatus.PASS
      }else{
        domain.status = CheckingCaptionStatus.FAIL
      }
    }))
    //set domain Exclusion checked
    domainExclusionItem.captionChecked = true
    

    //If curernt domain exclusion is not toggled, and testing result is all pass, toggle it
    const domainExcludeSlug = domainExcludeItemId + this.suffix
    const domainExclusionToggled = this.checklist[domainExcludeSlug] // domainExclusion toggled
    const domainExcludeAllPass = this.itemCheckAllPass(domainExcludeItemId)
    if(domainExcludeAllPass && !domainExclusionToggled){
      //console.log(" all pass but domainExclusion not Passed")
      this.toggleCheckList(domainExcludeItemId + this.suffix)
    }
  }

  /**
   * Get the default API param object
   * @returns { query {schl_dist_group_id}} default API param object
   */
  configureQueryParams() {
    return {
      query: {
        schl_dist_group_id: this.boardInfo.group_id
      }
    }
  }

  /**
   * Check if all the caption checks in the item are passed
   * @param domainExcludeItemId 
   * @returns 
   */
  itemCheckAllPass(itemId:string){
    const itemList = this.currentItemList() // get the current item list
    const item = itemList.find(item => item.id === itemId)
    const captionChecks = item?.captionChecks
    const allPass = captionChecks?.every(captionCheck => captionCheck.status === CheckingCaptionStatus.PASS);
    return allPass
  }

  /**
   * Check if url domain is reachable
   * @param url 
   * @param protocol Protocol used in the URL (HTTP, WSS)
   * @returns Boolean
   */
  async validateUrl(url: string, protocol:NetworkProtocol): Promise<boolean> {

    switch(protocol){
      case NetworkProtocol.HTTP:
        try{
          const response = await this.checkHTTPConnection(url)
          console.log(`Network response: ${response}`);
          return response
        }catch (err) {
          console.log(`Network error: ${err}`);
          return false
        }
      case NetworkProtocol.WSS:
        try{
          const response = await this.checkWebSocketConnection(url)
          console.log(`Network response: ${response}`);
          return response
        }catch(err){
          console.log(`Network error: ${err}`);
          return false
        } 
      default:
        return false
    }
  }

  /**
   * Check if the URL is reachable using http protocol
   * @param url
   * @returns boolean
   */
  checkHTTPConnection(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.onload = () => {
        if (200 <= xhr.status && xhr.status < 300) {
          resolve(`Request pass with status: ${xhr.status}`);
        }
        else {
          reject(`Request failed with status: ${xhr.status}`);
        }
      };
      xhr.onerror = (error) => {
        reject(`An error occurred while making the request: ${error}`);
      };

      xhr.send();
    });
  }

  /**
   * Check if the URL is reachable using wss protocol
   * @param url 
   * @returns boolean
   */
  checkWebSocketConnection(url: string): Promise<boolean> {
    let socket: WebSocket | null = null;
    return new Promise<boolean>((resolve) => {
      socket = new WebSocket(url);

      socket.addEventListener('open', () => {
        // WebSocket connection is open, close it before we return true
        if (socket) {
          socket.close();
          socket = null;
        }
        resolve(true);
      });

      socket.addEventListener('close', () => {
        // WebSocket connection is closed
        resolve(false);
      });
    });
  }
}  

