import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/api/auth.service';
import { LangService } from 'src/app/core/lang.service';
import { LoginGuardService } from '../../api/login-guard.service';
import { RoutesService } from "../../api/routes.service";
import {Router} from '@angular/router';
import { NotificationService } from 'src/app/api/notifications/notification.service';
import { NotificationCentre } from 'src/app/api/notifications/data/types';
@Component({
  selector: 'view-login-portal',
  templateUrl: './view-login-portal.component.html',
  styleUrls: ['./view-login-portal.component.scss']
})
export class ViewLoginPortalComponent implements OnInit {

  constructor(
    private auth:AuthService,
    private lang:LangService,
    public loginGuard: LoginGuardService,
    public notifications: NotificationService,
    private routes: RoutesService,
    private router: Router,
  ) { }

  fullName:string;
  accountTypes:any = [];
  accountTypelocalStorageAffix = 'accountTypes:'
  accessToken

  public userNotifications: NotificationCentre.IUserMessage[];

  ngOnInit(): void {

    const replaceProps = (str:string, params:any) => {
      Object.keys(params).forEach(paramKey => {
        str = str.split(':'+paramKey).join(params[paramKey])
      });
      return str;
    }

    this.auth.user().subscribe(async userInfo => {
      if (userInfo) {
        this.fullName = userInfo.firstName +' ' + userInfo.lastName
        const lang = this.lang.c();
        this.accessToken = userInfo.accessToken
        const accountTypelocalStoragePhrase = this.accountTypelocalStorageAffix+userInfo.accessToken
        const savedAccountType = JSON.parse(localStorage.getItem(accountTypelocalStoragePhrase));
        if(savedAccountType){
          this.accountTypes = savedAccountType
        }else{
          this.accountTypes = userInfo.accountTypes.map(record => {
            const {route_template, group_id} = record;
            record.route = '#'+replaceProps(route_template, {
              lang,
              group_id,
            })
            return record;
          })
        }
      }

      this.notifications.onNotificationsLoaded.subscribe((notifications: NotificationCentre.IUserMessage[]) => {
        this.userNotifications = notifications;
      });
    })

  }

  showConfirmBtn(accountType){
    if(+accountType.is_conf_req === 1 && +accountType.is_confirmed === 0){
      return true;
    }
    return false;
  }

  confirmBtnClick(accountType){
    const ur_id = accountType.ur_id
    const langCode = this.lang.getCurrentLanguage();
    this.auth.apiPatch(this.routes.LANDING_CONFIRM_USER_ROLE, ur_id, {} )
      .then(res => {
        if(+res.is_confirmed === 1 && +accountType.is_confirmed === 0){
          accountType.is_confirmed = 1
          const accountTypelocalStoragePhrase = this.accountTypelocalStorageAffix+ this.accessToken
          localStorage.setItem(accountTypelocalStoragePhrase, JSON.stringify(this.accountTypes));
        }
      })
  }

  notMySchoolBtnClick(accountType){
    const uid = this.auth.getUid();
    const langCode = this.lang.getCurrentLanguage();
    const confirmationMsg = this.lang.tra('lp_not_schl_admin_warning');
    this.loginGuard.confirmationReqActivate({
      caption: confirmationMsg,
      confirm: () => {
        //revoke self as school admin
        this.auth.apiRemove(this.routes.LANDING_CONFIRM_USER_ROLE, uid, { query: { schl_group_id: accountType.group_id } })
        //remove school admin
        const i = this.accountTypes.indexOf(accountType);
        this.accountTypes.splice(i, 1);
        const accountTypelocalStoragePhrase = this.accountTypelocalStorageAffix+ this.accessToken
        localStorage.setItem(accountTypelocalStoragePhrase, JSON.stringify(this.accountTypes));
      }
    })
  }

  getAccountNotifications(accountType) {
    // const accountNotifications = this.notifications.getNotificationsByGroup(accountType.group_id);
    const accountNotifications = this.notifications.getNotificationsByRole(accountType.ur_id, accountType.group_id);
    return this.notifications.unreadNotifications(accountNotifications);
  }

  storeCurrentAccountType(accountType) {
    this.auth.storeCurrentAccountType(accountType);
  }
}
