import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { LangService } from 'src/app/core/lang.service';
import { IStudentAccount } from '../../ui-schooladmin/data/types';
import { MySchoolService, ClassFilterId } from '../../ui-schooladmin/my-school.service';
import * as _ from 'lodash';
import { WhitelabelService } from 'src/app/domain/whitelabel.service';
import { AuthService, getFrontendDomain } from '../../api/auth.service';
import { FormControl } from '@angular/forms';
import { G9DemoDataService } from '../../ui-schooladmin/g9-demo-data.service';
import { downloadFromExportData, downloadCSVFromExportData, IExportColumn } from 'src/app/ui-testctrl/tc-table-common/tc-table-common.component';
import { formatDate } from '@angular/common';
import { RoutesService } from 'src/app/api/routes.service';
import { OSSLTResultColumns } from '../../ui-dist-admin/sb-board/sb-board.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MemDataPaginated } from '../../ui-partial/paginator/helpers/mem-data-paginated';
import { LoginGuardService } from '../../api/login-guard.service';
import { allowBypassDomains } from '../../domain/qa-bypass'
import { Subscription } from 'rxjs';
import { ASSESSMENT } from '../../ui-teacher/data/types';

declare const require: any;
const cssFilePath = require('src/styles/specific-component-styles/osslt-single-isr.css').default;

@Component({
  selector: 'isr-reports',
  templateUrl: './isr-reports.component.html',
  styleUrls: ['./isr-reports.component.scss']
})
export class IsrReportsComponent implements OnInit {

  @Output() onSetClassFilter = new EventEmitter();
  @Input() isIssueReviewer = false;
  @Input() schl_group_id_input;
  @Input() schoolsId_input;
  @Input() singleStudentUid_input;
  @Input() tw_id_input;
  @Input() schoolLanguage;
  @ViewChild('printContent') printContent!: ElementRef;

  studentReports: [];
  studentReportDates: [];
  studentPJData: any = [];
  studentReportsAfterDate: any [];
  singleStudentUid?:number;
  isReportsLoaded:boolean;
  //REPORT_YEAR:string = "2020 - 2021";
  isPrivateSchool = false;
  report_after_date = new FormControl();
  currentAdminWindow
  adminWindows = []
  public bypassAdminSignOff:boolean;
  isShowingG9Reports =false;
  g9Classes: any[] = [];
  g9ClassesInSelectedTw : any[] = [];
  currentG9AdminWindow
  g9AdminWindows = []
  classroomsTable: MemDataPaginated<any>;
  ClassFilterId = ClassFilterId;
  queryParamSub: Subscription;
  reportAssessment: string;
  queryParam;
  fullStudentPJData = [];
  showAppealInterface = false

  constructor(
    public schoolService:MySchoolService,
    public lang: LangService,
    public auth: AuthService,
    private route:ActivatedRoute,
    private routes: RoutesService,
    private whitelabel: WhitelabelService,
    public mySchool: MySchoolService,
    private g9demoService: G9DemoDataService,
    private sanitizer: DomSanitizer,
    private loginGuard: LoginGuardService,
    private router: Router,
    ) {}

  ngOnInit(): void {
    if(!this.isIssueReviewer){
      this.route.queryParams.subscribe((queryParams) => {
        this.queryParam = queryParams;
        if(queryParams['studentUID']) {
          this.singleStudentUid = queryParams['studentUID'];
        }
        if(queryParams['reportAssessment']){
          this.reportAssessment = queryParams['reportAssessment'];
          this.currentClassFilter = this.reportAssessment
          const targetToggle = this.schoolService.getClassFilterToggles().find(cft => cft.id === this.reportAssessment)
          if(targetToggle){
            targetToggle.value = true;
          }
          this.setClassFilter(this.currentClassFilter)
        }
      });
      this.isPrivateSchool = this.g9demoService.schoolData["is_private"];
      if (this.isPrivateSchool) {
        this.setClassFilter(ClassFilterId.OSSLT);
      }
      this.mySchool.initStuAsmtSignoff();
      const schoolLang = this.g9demoService.schoolData["lang"];
      this.lang.setCurrentLanguage(schoolLang);

      this.classroomsTable = new MemDataPaginated({
        data: [],
        pageSize: 5,
        filterSettings: {
        },
        sortSettings: {
        }
      })
    }else{
      this.currentClassFilter = ClassFilterId.OSSLT
      this.lang.setCurrentLanguage(this.schoolLanguage);
      const queryparams = {
        schl_group_id: this.schl_group_id_input,
        schoolsId: this.schoolsId_input, 
        assessment_slug:ASSESSMENT.OSSLT_OPERATIONAL,
        singleStudentUid: this.singleStudentUid_input,
        clientDomain: getFrontendDomain(),
        bypassAdminSignOff:true,
        tw_id: this.tw_id_input,
      }
      this.auth.apiFind(this.routes.TEST_CTRL_OSSLT_SINGLE_REPORTS, {
        query: queryparams
      }).then(res =>{
        this.isReportsLoaded = true
        this.isShowingReports = true
        this.studentReportsAfterDate = res
      })
    }
  }

  getReportTableBody(report: any): any[] {
    const rowTitles = [
      {
        title: report.var_reading ? this.lang.tra('pj_student_report_result_reading_2') : this.lang.tra('pj_student_report_result_reading'), 
        content: report.var_reading ? report.var_reading : null
      },
      {
        title: report.var_writing ? this.lang.tra('pj_student_report_result_writing_2') : this.lang.tra('pj_student_report_result_writing'), 
        content: report.var_writing ? report.var_writing : null
      },
      {
        title: report.var_mathematics ? this.lang.tra('pj_student_report_result_math_2') : this.lang.tra('pj_student_report_result_math'), 
        content: report.var_mathematics ? report.var_mathematics : null
      }
    ];
    const tableRows = [];
    let index = 0;
    rowTitles.forEach((e: any) => {
      let layer_1, layer_2;
      if(index == 0){
        layer_1 = report.reading_layer_1;
        layer_2 = report.reading_layer_2;
      }
      if(index == 1){
        layer_1 = report.writing_layer_1;
        layer_2 = report.writing_layer_2;
      }
      if(index == 2){
        layer_1 = report.math_layer_1;
        layer_2 = report.math_layer_2;
      }
      const row = {
        reportID: report.id,
        id: `${report.id}-row-${index}`,
        columns: [],
        layers: {
          layer_1: layer_1,
          layer_2: layer_2
        }
      };
      for(let j = 0; j < 6; j++) {
        row.columns.push({
          id: `${report.id}-row-${index}-col-${j}`,
          title: e.title,
          content: j === 0 ? e.content : null
        });
      }
      tableRows.push(row);
      index++;
    });
    return tableRows;
  }
  // Table performance for rendering
  byID(index, item){
    return item.id
  }
  
  addSquarePosition(reportID: number,rowNumber: number, columNumber: number, squarePostion: number): void {
    const sectionNum = 5;
    columNumber = columNumber + 1;
    const id = `${reportID}-row-${rowNumber}-col-${columNumber}`;

    const box = document.getElementById(id);

    if (!box) {
      return null;
    }
    box.style.textAlign = "left";
    const level2Spaces = box.clientWidth / ((sectionNum + 1) * 2);
    let marginLeft = (level2Spaces * 2) * (squarePostion-1) + level2Spaces;
    box.innerHTML = `<i style="margin-left:${marginLeft}px; font-size: ${level2Spaces * 2}px;" class="fas fa-square"></i>`;
  }

  clearSingleSudentFocus(){
    this.singleStudentUid = null;
    this.loadStudentReports();
  }

  async loadTestWindows(){
    this.isReportsLoaded = false;
    this.studentReportDates = await this.schoolService.loadSchoolReportTestWindows(this.singleStudentUid);
    this.createAdminWindows();
    if(this.adminWindows.length > 0){
      this.currentAdminWindow = this.adminWindows[this.adminWindows.length - 1];
    }
    this.loadStudentReports();
  }
  
  async loadStudentReports(){
    if(this.currentClassFilter == ClassFilterId.OSSLT){
      this.isReportsLoaded = false;
      if(this.currentAdminWindow != undefined){
        this.studentReports = await this.schoolService.loadSchoolReports(this.singleStudentUid, this.bypassAdminSignOff, this.currentAdminWindow.tw_id);
        this.studentReports = _.orderBy(this.studentReports, ['last_name', 'first_name', 'student_oen']);
        this.isReportsLoaded = true;
        this.studentReportsAfterDate = this.studentReports;
        this.filterReports();
      }
        
      this.auth.apiCreate('public/log', {
        slug: 'SA_REPORT_VIEW',
        data: {
          singleStudentUid: this.singleStudentUid,
          schoolId: this.schoolService.getCurrentSchoolId()
        }
      })
    }
    if(this.currentClassFilter == ClassFilterId.Primary||this.currentClassFilter == ClassFilterId.Junior){
      //this.isReportsLoaded = false;
      this.fullStudentPJData = [];
      this.studentPJData = [];
      let getData = true;
      this.fullStudentPJData = await this.schoolService.loadSchoolPJReports(this.currentClassFilter, this.singleStudentUid, this.bypassAdminSignOff, getData)
      //this.studentReports = _.orderBy(this.studentReports, ['last_name', 'first_name', 'student_oen']);
      this.isReportsLoaded = true;
      this.createPJAdminWindows()
      if(this.adminWindows.length > 0){
        this.currentAdminWindow = this.adminWindows[this.adminWindows.length - 1];
      }
      this.studentPJData = this.fullStudentPJData.filter(fspjd => +fspjd.test_window === +this.currentAdminWindow.tw_id);
      //this.filterReports()  not implement yet
    }
  }

  isShowingReports:boolean;
  isShowingPJReports:boolean;
  currentClassFilter;
  setClassFilter(filterId){
    this.onSetClassFilter.emit(filterId);

    this.currentClassFilter = filterId;
    this.isShowingG9Reports= false;
    this.isShowingReports = false;
    this.isShowingPJReports= false;
    this.showAppealInterface = false;
    if (this.currentClassFilter){
      if(this.currentClassFilter === ClassFilterId.OSSLT){
        this.loadTestWindows();
        this.isShowingReports = true;
        this.loadStudentReports();
      }if(this.currentClassFilter === ClassFilterId.Primary || this.currentClassFilter === ClassFilterId.Junior){
        this.isShowingPJReports = true;
        this.loadStudentReports()
      }
      else if(this.currentClassFilter === ClassFilterId.G9){
        this.loadG9StudentClass();
        this.isShowingG9Reports= true;
      }
    }
  }

  isLangActive(langCode: string) {
    return (this.lang.c() == langCode);
  }

  isEnglish(){
    return this.isLangActive('en');
  }

  getWhitelabelUrl(slug:string){
    return this.whitelabel.getSiteText(slug);
  }

  timezone = moment.tz.guess();
  renderAttemptDate(dateTimeStart: string) {
    return moment.tz(dateTimeStart, this.timezone).format(this.lang.tra('datefmt_dashboard_short'));
  }

  print(){
    window.print()
  }

  maskOENWithFourStars(oen){
    let oenArr = oen.split('');
    for(let i =2; i < oenArr.length-3; i++){
      oenArr[i] = "*";
    }
    return oenArr.join('')
  }

  maskOENWithFiveStars(oen){
    let oenArr = oen.split('');
    for(let i =1; i < oenArr.length-3; i++){
      oenArr[i] = "*";
    }
    return oenArr.join('')
  }

  maskOENWithHyphen(oen){
    return oen.replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3');
  }

  generateReportsAfterDate(){
    this.filterReports()
  }

  createAdminWindows(){
    this.adminWindows = [];
    this.studentReportDates.forEach((reportDate:any) =>{
      const adminWindow = this.adminWindows.find( adminWindow => adminWindow.tw_id == reportDate.tw_id)
      if(!adminWindow){
        this.adminWindows.push({tw_id:reportDate.tw_id, tw_date_end:reportDate.tw_date_end, tw_is_allow_appeals:reportDate.is_allow_appeals})
      }
    })
    this.adminWindows.sort( (window1:any, window2:any) => {
      const window1StartDate = new Date(window1.tw_date_end)
      const window2StartDate = new Date(window2.tw_date_end)
      if(window1StartDate > window2StartDate){
        return 1
      }else if (window1StartDate < window2StartDate){
        return -1
      }else{
        return 0
      }
    })
  }

  createPJAdminWindows(){
    this.adminWindows = [];
    this.fullStudentPJData.forEach((report:any) =>{
      const adminWindow = this.adminWindows.find( adminWindow => adminWindow.tw_id == report.test_window)
      if(!adminWindow){
        this.adminWindows.push({tw_id:report.test_window, sch_year:report.sch_year, tw_is_allow_appeals:report.tw_is_allow_appeals})
      }
    })
    this.adminWindows.sort( (window1:any, window2:any) => {
      const window1SchlYear = new Date(window1.sch_year)
      const window2SchlYear = new Date(window2.sch_year)
      if(window1SchlYear > window2SchlYear){
        return 1
      }else if (window1SchlYear < window2SchlYear){
        return -1
      }else{
        return 0
      }
    })
  }

  onAdminWindowChanged(adminWindowIndex){
    this.showAppealInterface = false;
    this.currentAdminWindow = this.adminWindows[adminWindowIndex];
    this.loadStudentReports();
  }

  onPJAdminWindowChanged(adminWindowIndex){
    this.showAppealInterface = false;
    this.studentPJData = []
    this.currentAdminWindow = this.adminWindows[adminWindowIndex];
    this.studentPJData = this.fullStudentPJData.filter(fspjd => +fspjd.test_window === +this.currentAdminWindow.tw_id);
  }

  onG9AdminWindowChanged(adminWindowIndex){
    this.showAppealInterface = false;
    this.currentG9AdminWindow = this.g9AdminWindows[adminWindowIndex];
    this.filterG9Classes()
  }

  filterReports(){
    if(this.currentClassFilter === ClassFilterId.OSSLT){
      this.studentReportsAfterDate = this.studentReports.slice();
      if(this.report_after_date.value){
        this.studentReportsAfterDate = this.studentReportsAfterDate.filter( report => 
            Date.parse(report.report_gen_on) > Date.parse(this.report_after_date.value)
        )
      }
    } 
  }

  currentAdminWindowIndex(){
    return this.adminWindows.indexOf(this.currentAdminWindow);
  }

  currentG9AdminWindowIndex(){
    return this.g9AdminWindows.indexOf(this.currentG9AdminWindow);
  }

  getReportYear(report){
    const written_on = new Date (report.written_on)
    const writtenYear = written_on.getFullYear()
    const writtenMonth = written_on.getMonth() + 1
    const writtenSemester = writtenMonth < 7 ? this.lang.tra('lbl_osslt_report_spring') : this.lang.tra('lbl_osslt_report_term_fall')
    return writtenSemester + ' ' + writtenYear;
  }

  showReportNumQ(report) {
    return !!(+report.show_isr_num_q);
  }

  allowBypassDomain(){
    const isBypassDomain = allowBypassDomains.indexOf(getFrontendDomain()) > -1
    return isBypassDomain
  }

  onSetBypassAdminSignOff(value:boolean){
    this.bypassAdminSignOff = value;
    this.loadStudentReports()
  }

  exportOSSLTResult(){
    const user = this.auth.user().value;
    this.auth
      .apiGet(this.routes.SCHOOL_ADMIN_REPORTS, user.uid, this.configureQueryParams()
      ).then(res =>{
        let exportData;
        const columns = this.getOSSLTResultColumns();
        if(res.length > 0){
          exportData = this.getOSSLTResultData(res)
          //downloadFromExportData(exportData, columns, 'osslt-report', this.auth);
          downloadCSVFromExportData('osslt-report.csv',exportData, columns)
        }
      })
  }

  configureQueryParams(){
    let testWindowId
    if(this.currentClassFilter === ClassFilterId.OSSLT){
      testWindowId = this.currentAdminWindow.tw_id
    }
    if(this.currentClassFilter === ClassFilterId.G9){
      testWindowId = this.currentG9AdminWindow.tw_id
    }
    return { query: {schl_group_id: this.schoolService.getCurrentSchoolGroupId(), clientDomain: getFrontendDomain(), testWindowId, bypassAdminSignOff: this.bypassAdminSignOff }}
  }

  getOSSLTResultColumns(){
    const columnsName = OSSLTResultColumns;
    let columns: IExportColumn[] = [];
    columnsName.forEach(column =>{
      columns.push({
        prop: column,
        caption: column,
        isClickable: false
      })
    })
    return columns;
  }

  getOSSLTResultData(data:any){
    let records = [];
    data.forEach(record => {
      //Modify SASN
      if(record.SASN === null){
        record.SASN = '#'
      }

      //Modify EligibilityStatus
      if(record.EligibilityStatus){
        if(record.EligibilityStatus == '1'){
          record.EligibilityStatus = this.lang.tra("sdc_osslt_eligstat_1")
        }
        else if (record.EligibilityStatus == '2'){
          record.EligibilityStatus = this.lang.tra("sdc_osslt_eligstat_2")
        }else{
          record.EligibilityStatus = ''
        } 
      }else{
        record.EligibilityStatus = ''
      }

      //Modify HasStarted
      if(record.HasStarted){
        record.HasStarted = this.lang.tra("lbl_yes")
      }else{
        record.HasStarted = this.lang.tra("lbl_no")
      }

      //Modify HasSubmitted
      if((record.HasSubmitted && record.HasSubmitted == 1)||(record.HasReport && record.ta_closed_on)){
        record.HasSubmitted = this.lang.tra("lbl_yes")
      }else{
        record.HasSubmitted = this.lang.tra("lbl_no")
      }

      if(record.HasSubmitted && record.HasSubmitted == this.lang.tra("lbl_yes") && record.ta_closed_on ){
        record.SubmittedOn = formatDate(new Date(record.ta_closed_on), 'yyyyMMdd', 'en_US')
      }else{
        record.SubmittedOn = ''
      }
      delete record.ta_closed_on;

      //Modify StartedOn
      if(record.StartedOn){
        record.StartedOn = formatDate(new Date(record.StartedOn), 'yyyyMMdd', 'en_US')
      }else{
        record.StartedOn= ''
      }

      //Modify HasReport
      // if(record.HasReport){
      //   record.HasReport = this.lang.tra("lbl_yes")
      // }else{
      //   record.HasReport = this.lang.tra("lbl_no")
      // }

      //Modify Result, Scalescore and HasReport
      if(record.Result){
        switch(record.Result){
          case "0":{
            record.Result = this.lang.tra("sa_report_state_0");
            record.OSSLTScaleScore = ""
            record.HasReport = this.lang.tra("lbl_no")
            break;
          }  
          case "1":{
            record.Result = this.lang.tra("sa_report_state_1");
            //record.OSSLTScaleScore = 0
            record.ScaleScoreDescription = this.lang.tra("osslt_report_scalescore_1_succ")
            record.HasReport = this.lang.tra("lbl_yes")
            if(record.isLinear == '1'){
              record.OSSLTScaleScore = "ALT"
              record.ScaleScoreDescription = this.lang.tra("osslt_report_scalescore_3")
            }
            break;
          }
          case "2":{
            record.Result = this.lang.tra("sa_report_state_2");
            record.ScaleScoreDescription = this.lang.tra("osslt_report_scalescore_2")
            record.HasReport = this.lang.tra("lbl_yes")
            if(record.isLinear == '1'){
              record.OSSLTScaleScore = "ALT"
              record.ScaleScoreDescription = this.lang.tra("osslt_report_scalescore_3")
            }
            break;
          }
          case "3":{
            record.Result = this.lang.tra("sa_report_state_3");
            record.OSSLTScaleScore = ""
            record.HasReport = this.lang.tra("lbl_no")
            break;
          }
          case "4":{
            record.Result = this.lang.tra("sa_report_state_4");
            record.OSSLTScaleScore = ""
            record.HasReport = this.lang.tra("lbl_no")
            break;
          }
          case "5":{
            record.Result = this.lang.tra("sa_report_state_5");
            record.OSSLTScaleScore = ""
            record.HasReport = this.lang.tra("lbl_no")
            break;
          }
          case "6":{
            record.Result = this.lang.tra("sa_report_state_6");
            record.OSSLTScaleScore = ""
            record.HasReport = this.lang.tra("lbl_no")
            break;
          }
          case "10":{
            record.Result = this.lang.tra("sa_report_state_10");
            record.OSSLTScaleScore = ""
            record.HasReport = this.lang.tra("lbl_no")
            break;
          }
          case "11":{
            record.Result = this.lang.tra("sdc_const_osslt_succ_2");
            record.OSSLTScaleScore = 0
            record.ScaleScoreDescription = this.lang.tra("osslt_report_scalescore_1_na")
            record.HasReport = this.lang.tra("lbl_no")
            break;
          }          
          default:{
            record.Result = this.lang.tra("sa_report_state_2");
            record.OSSLTScaleScore = ""
            record.HasReport = this.lang.tra("lbl_no")
            break;
          }  
        }
      }else{
        record.Result = this.lang.tra("sa_report_state_2");
        record.OSSLTScaleScore = ""
        record.HasReport = this.lang.tra("lbl_no")
      }

      //Modify Note
      if(record.Note === null){
        record.Note = ''
      }
      records.push(record)
    });

    return records
  }
  isAllowBypassDomain(){
    // const clientDomain = getFrontendDomain()
    // const allowBypassDomain = ["http://localhost:4200/", "http://localhost:4401/","https://d3h4m0g2lmrmq6.cloudfront.net/"]
    // // const allowBypassDomain = ["https://d3h4m0g2lmrmq6.cloudfront.net/"]
    // const isBypassDomain = allowBypassDomain.indexOf(clientDomain) > -1
    // return isBypassDomain;
    return true;
  }


  isSignoffAvail(){
    if(!this.currentAdminWindow){
      return false
    }
    const studentSignoffs = this.mySchool.checkStuAsmtSignoffReport(this.currentClassFilter, this.currentAdminWindow.tw_id);
    return this.currentClassFilter && studentSignoffs; // to do, should show alternate version if signed off
  }

  getReportVersion(report){
    const written_on = new Date (report.written_on);
    const writtenYear = written_on.getFullYear();
    if(writtenYear >= 2022) {
      return 'reportVersion1'
    }
    return 'reportVersion0'
  }

  getAdminWindowSlug(adminWindow){
    if(this.currentClassFilter == ClassFilterId.Primary || this.currentClassFilter == ClassFilterId.Junior){
      return adminWindow.sch_year
    }
    if(this.currentClassFilter == ClassFilterId.G9){
      const startDate = formatDate(new Date(adminWindow.tw_date_start), 'MMM yyyy', 'en_US')
      const endDate = formatDate(new Date(adminWindow.tw_date_end), 'MMM yyyy', 'en_US')
      return startDate+" "+this.lang.tra("lbl_date_to") +" "+endDate;
    }
    if(this.currentClassFilter == ClassFilterId.OSSLT ){
      const adminWindowStartDate = new Date (adminWindow.tw_date_end)
      const adminWindowYear = adminWindowStartDate.getFullYear()
      const adminWindowMonth = adminWindowStartDate.getMonth()+1
      if(adminWindowMonth < 7){
        return ''+adminWindowYear+' '+this.lang.tra('lbl_osslt_report_term_spring')
      }else{
        return ''+adminWindowYear+' '+this.lang.tra('lbl_osslt_report_term_fall')
      }
    }  
  }
  
  getReportYearVersion1(report){
    if(this.currentClassFilter === ClassFilterId.OSSLT){
      return this.lang.tra("lbl_student_report_2") + ", " + this.getReportYear(report);
    }
    if(this.currentClassFilter === ClassFilterId.Primary|| this.currentClassFilter === ClassFilterId.Junior){
      return this.lang.tra("lbl_student_report_2") + ", " + report.sch_year;
    }
  }

  getScaleScore(report){
    const isLinearReport = report.isLinear && report.isLinear =='1'
    if( isLinearReport ){
      return 'ALT*'
    }
    return report.scale_score
  }

  isLinearFailReport(report){
    if(report.isLinear && report.isLinear =='1' && report.result_state == '2' ){
      return true;
    }
    return false;
  }

  isFireFox(){
    const agent = window.navigator.userAgent.toLowerCase()
    const isFireFox = agent.includes('firefox');
    return isFireFox;
  }

  generetePJReport(){
    if(!this.currentAdminWindow){
      return; 
    }
    this.schoolService
      .generateSchoolPJReports(this.currentClassFilter, this.currentAdminWindow.tw_id, this.singleStudentUid, this.bypassAdminSignOff)
      .then(res =>{
        if(res.message === 'REPORT_GENERATING'){
          this.loginGuard.confirmationReqActivate({
            caption: this.lang.tra('sa_report_generating_pj'),
            confirm: () => {}
          });
        }
        if(res.message === 'REPORT_REGENERATING'){
          this.loginGuard.confirmationReqActivate({
            caption: this.lang.tra('sa_report_regenerating'),
            confirm: () => {}
          });
        }
      })
      .catch(err => {
        if (err.message === 'REPORT_GENERATING') {
          this.loginGuard.confirmationReqActivate({
            caption: this.lang.tra('sa_report_generating_pj'),
            confirm: () => {}
          });
        }
        if (err.message === 'NO_DATA_FOR_REPORT') {
          //do nothing
        }
      })
  }

  async getPJReportPDF() {
    let noAvailableReport = false
    const res = await this.schoolService
       .loadSchoolPJReports(this.currentClassFilter, this.singleStudentUid, this.bypassAdminSignOff, false, this.currentAdminWindow.tw_id)
       .catch(err => {
          noAvailableReport = true;
          if (err.message === 'REPORT_GENERATING') {
            this.loginGuard.confirmationReqActivate({
              caption: this.lang.tra('sa_report_generating'),
              confirm: () => {}
            });
          }
          if (err.message === 'NO_DATA_FOR_REPORT') {
            //do nothing
          }
       })

    const message = res.message
    if(message === 'REPORT_GENERATING'){ // shouldn't come into this line, but put here just incase
      this.loginGuard.confirmationReqActivate({
        caption: this.lang.tra('sa_report_generating'),
        confirm: () => {}
      });
    } 
    if(message === 'REPORT_REGENERATING'){
      this.loginGuard.confirmationReqActivate({
        caption: this.lang.tra('sa_report_regenerating'),
        confirm: () => {}
      });
    } 
    const fileURL = res.reportURL
    if((message === 'REPORT_GENERATED' || message === 'INDIVIDUAL_REPORT_GENERATED') && fileURL && !noAvailableReport){
      // let a = document.createElement('a');
      // a.href = fileURL;
      // a.download = 'StudentResponseSheets.pdf';
      // a.dispatchEvent(new MouseEvent('click'));
      return fileURL;
    }  
    return null;
  }

  async printPJReport(){
    if(!this.currentAdminWindow){
      return; 
    }
    const fileURL = await this.getPJReportPDF();
    if(fileURL){
      let a = document.createElement('a');
      a.href = fileURL;
      a.download = 'StudentResponseSheets.pdf';
      a.dispatchEvent(new MouseEvent('click'));
    }
  }

  getFullReportParam() {
    let returnQueryParam = {}
    if(this.queryParam && this.queryParam.school ){
      returnQueryParam["school"] = this.queryParam.school
      returnQueryParam["reportAssessment"] = ''+this.currentClassFilter
    }
    return returnQueryParam
  }

  showPJReportByImage() {
    if(this.currentClassFilter === ClassFilterId.Primary || this.currentClassFilter == ClassFilterId.Junior) {
      if(this.currentAdminWindow.tw_id !== 39 && this.currentAdminWindow.tw_id !== 40) {  // hardcode this parts because only these two PJ test window has no single report
        return true;
      } 
    }
    return false;
  }

  getPJReportGenerateOn(){
    const currentTestWindowReport = this.studentPJData.find( (report:any) => +report.test_window == +this.currentAdminWindow.tw_id)
    if(currentTestWindowReport){
      const reportGenerateOn = currentTestWindowReport.report_gen_on
      if(reportGenerateOn && reportGenerateOn!= ''){
        return (new Date(reportGenerateOn)).toLocaleString()
      }  
    }
    return ''
  }  
  async loadG9StudentClass(){
    this.g9Classes = await this.mySchool.loadSchoolG9ClassesWithReport()
    this.createG9AdminWindows()
    if(this.g9AdminWindows.length > 0){
      this.currentG9AdminWindow = this.g9AdminWindows[this.g9AdminWindows.length - 1];
    }
    this.filterG9Classes()
  }

  createG9AdminWindows(){
    this.g9AdminWindows = [];
    this.g9Classes.forEach((theClass:any) =>{
      const g9AdminWindow = this.g9AdminWindows.find(adminWindow => adminWindow.tw_id == theClass.tw_id)
      if(!g9AdminWindow){
         this.g9AdminWindows.push({tw_id:theClass.tw_id, tw_date_start:theClass.tw_date_start, tw_date_end:theClass.tw_date_end, tw_is_allow_appeals:theClass.tw_is_allow_appeals})
      }
    })
    this.g9AdminWindows.sort( (window1:any, window2:any) => {
      const window1StartDate = new Date(window1.tw_date_start)
      const window2StartDate = new Date(window2.tw_date_start)
      if(window1StartDate > window2StartDate){
         return 1
      }else if (window1StartDate < window2StartDate){
         return -1
      }else{
         return 0
      }   
    })
  }

  onClickG9Report(classroom){
    if(!this.isPrivateSchool  || this.isPrivateSchool && +classroom.allow_ISR == 1){
      const class_id = classroom.sc_id;
      const schl_group_id = this.g9demoService.schoolData.group_id;
      this.auth.apiPatch(this.routes.SCHOOL_ADMIN_INVIGILATE_CLASS, 0, {class_id, schl_group_id}, this.configureQueryParams())
      .then(result =>{
        this.g9demoService.setIsFromSchoolAdmin(true)
        const queryParams = { ...this.route.snapshot.queryParams};
        const route = `/${this.lang.c()}/educator/assessment-report/${classroom.sc_id}/${classroom.sc_group_id}/`;
        this.router.navigate([route], {
          relativeTo: this.route,
          queryParams
        });
      });
    }else{
      this.loginGuard.quickPopup(this.lang.tra("ta_msg_g9_report_unavailable"))
    }
  }

  pageChanged(){
  }

  showExport() {
    if(this.allowBypassDomain()){
      return true
    }
    if(this.currentAdminWindow){
      return this.g9demoService.showReportToBoard(this.currentAdminWindow.tw_id);
    }
    return false;
  }

  filterG9Classes(){
    if(this.currentClassFilter === ClassFilterId.G9){
      this.g9ClassesInSelectedTw = this.g9Classes.filter(theClass => theClass.tw_id == this.currentG9AdminWindow.tw_id)
    } 
    this.classroomsTable.injestNewData(this.g9ClassesInSelectedTw)
  }

  printOSSLTReport(): void {
    const printContent = this.printContent.nativeElement.innerHTML;
    const WindowPrt = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    WindowPrt.document.write('<html><head>');
    WindowPrt.document.write(`<style>${cssFilePath}</style>`);
    WindowPrt.document.write('</head><body>');
    WindowPrt.document.write(printContent);
    WindowPrt.document.write('</body></html>');
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }

  /**",
   * check if current test window allow appeal (show/hide the appeal button)
   * @param null
   * @returns boolean
   */
  showAppealBtn(){
    //Primary, Junior, OSSLT use currentAdminWindow
    const adminWindowsAssessment = [ClassFilterId.Primary, ClassFilterId.Junior, ClassFilterId.OSSLT]
    const useadminWindows = adminWindowsAssessment.includes(this.currentClassFilter)
    if(useadminWindows && this.currentAdminWindow && this.currentAdminWindow.tw_is_allow_appeals){
      return true
    }
    
    //G9 use currentG9AdminWindow
    const g9AdminWindows = [ClassFilterId.G9]
    const useaG9dminWindows = g9AdminWindows.includes(this.currentClassFilter)
    if(useaG9dminWindows && this.currentG9AdminWindow && this.currentG9AdminWindow.tw_is_allow_appeals){
      return true
    }
    return false 
  }
  
  /**",
   * show/hide the appeal interface
   * @param null
   * @returns null
   */
  showAppealBtnClick(){
    this.showAppealInterface = !this.showAppealInterface
  }

  /**",
   * get current report test window id
   * @param null
   * @returns tw_id 
   */
  getCurrentTestWindowID(){
    //Primary, Junior, OSSLT use currentAdminWindow
    const adminWindowsAssessment = [ClassFilterId.Primary, ClassFilterId.Junior, ClassFilterId.OSSLT]
    const useadminWindows = adminWindowsAssessment.includes(this.currentClassFilter)
    if(useadminWindows && this.currentAdminWindow){
      return this.currentAdminWindow.tw_id
    }

    //G9 use currentG9AdminWindow
    const g9AdminWindows = [ClassFilterId.G9]
    const useaG9dminWindows = g9AdminWindows.includes(this.currentClassFilter)
    if(useaG9dminWindows && this.currentG9AdminWindow){
      return this.currentG9AdminWindow.tw_id
    }

    return undefined;
  }
}
