export enum SecureLinkSlug {
    SEB_CONFIG = 'SEB_CONFIG',
    SEB_CONFIG_PASS = 'SEB_CONFIG_PASS',
    KIOSK_POLICY = 'KIOSK_POLICY',
    KIOSK_POLICY_CREDS = 'KIOSK_POLICY_CREDS',
  }

export interface ILinkItem {
    linkUrl?: string,
    linkCaption?: string,
    isSecure?: boolean,
    slug?: SecureLinkSlug | string,
    disabled?: boolean,
  }
  
export interface ICheckListItem {
    id?: string,
    caption: string,
    links?: ILinkItem[],
    selectionCaption?:any[],
    selectionOptions?: {slug:string, caption:string}[],
  }