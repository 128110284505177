import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/api/notifications/notification.service';
import { NotificationCentre } from 'src/app/api/notifications/data/types';
import { AuthService } from '../../api/auth.service';
import { LangService } from 'src/app/core/lang.service';

@Component({
  selector: 'ntf-new-messages',
  templateUrl: './ntf-new-messages.component.html',
  styleUrls: ['./ntf-new-messages.component.scss']
})
export class NtfNewMessagesComponent implements OnInit {
  @Input() public isHeader: boolean = false;
  
  constructor(
    private auth: AuthService,
    public notifications: NotificationService,
    public lang: LangService,
  )
  { }

  public userNotifications: NotificationCentre.IUserMessage[];

  ngOnInit(): void {
    this.auth.user().subscribe(async userInfo => {
      this.notifications.onNotificationsLoaded.subscribe((notifications: NotificationCentre.IUserMessage[]) => {
        this.userNotifications = notifications;
      });      
    })
  }

  getAccountNotifications() {
    return this.notifications.unreadNotifications();
  }

}
