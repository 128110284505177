import { Injectable, OnDestroy, OnInit } from '@angular/core';
import {AuthService} from '../api/auth.service';
import Sockette from 'sockette';
import { AccountType } from '../constants/account-types';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { RoutesService } from '../api/routes.service';
import { LoginGuardService } from '../api/login-guard.service';
import { LangService } from '../core/lang.service';
import { Router } from '@angular/router';
import { StudentAssistiveTechService } from './student-assistive-tech.service';
import { WhitelabelService } from '../domain/whitelabel.service';
import { ASSESSMENT } from '../ui-teacher/data/types';

const EDUCATOR_PING_INTERVAL = 30 * 1e3;
const HEARTBEAT_PING_INTERVAL  = 15 * 1e3;

const PING_TIMEOUT_MSG = 'ping-timeout';

export interface IStudentPositionUpdate {
  uid: number,
  stageIndex?: number,
  questionIndex?: number,
  questionCaption?: string,
  submitConfig?: {
    submitted: boolean, 
    subSessionIndex: number
  },
  softLock?:number, // boolean // CLOSER_LOOK_20210807 incoming was number
  numTimesOffScreen?: number
}
export interface ISubSessionStateInfo {
  available: boolean,
  caption: string,
  asmtSlug: ASSESSMENT,
  subSessionSlug: string,
  isSubmitting?: boolean
}

interface SocketQueueItem {
  action: string,
  data: any
}

@Injectable({
  providedIn: 'root'
})

export class StudentG9ConnectionService implements OnDestroy {
  private ws: Sockette;
  private classId;
  public userConnectionId: number // connection id to communicate through websocket of online user
  private userType: string;
  private apiHost = 'https://qc7-api.vretta.com'
  // private apiHost = 'http://apiqc.mathproficiencytest.ca'

  private wsURL: string ;

  private socketReady: boolean = false;
  private socketQueue: Array<SocketQueueItem> = [];

  public connectedStudentsSub = new BehaviorSubject<any[]>([]);
  public updateStudentPositionSub = new BehaviorSubject<IStudentPositionUpdate>(null);
  public disconnectedStudentsSub = new BehaviorSubject<any[]>([]);
  public autoSubmissionTeacherPopupSub = new BehaviorSubject<any>(null)
  public testSubmitted = new BehaviorSubject<boolean>(false);
  public subSessionStateChange = new BehaviorSubject<ISubSessionStateInfo>(null);
  public networkOfflineSub: Subscription = null;
  private networkOnlineSub: Subscription = null;
  private offlineEvent: Observable<Event>;
  private onlineEvent: Observable<Event>;
  pingInterval;
  communicationTimeout;

  constructor(
    private routes: RoutesService,
    private auth: AuthService,
    private loginGuard: LoginGuardService,
    private lang: LangService,
    private assisTech: StudentAssistiveTechService,
    private router: Router,
    private whiteLabel: WhitelabelService,
  ) {
    this.wsURL = this.whiteLabel.getWebsocketAddress('invigilationWs')
    this.apiHost = this.whiteLabel.getApiAddress();

    this.offlineEvent = fromEvent(window, 'offline');
    this.networkOfflineSub = this.offlineEvent.subscribe(e => {
      this.ws.close();
      this.ws = undefined;
      this.socketReady = false;
      clearInterval(this.pingInterval);
      this.resetCommunicationTimeout();
    })

    this.onlineEvent = fromEvent(window, 'online');
    this.networkOnlineSub = this.onlineEvent.subscribe(e => {
      this.connect();
      this.resetCommunicationTimeout();
    })
    this.offlineEvent = fromEvent(window, 'offline');
    this.networkOfflineSub = this.offlineEvent.subscribe(e => {
      if (this.auth.getUid() && this.userConnectionId && this.classId) {
        this.logOffUser();
      }
    })
    this.auth.user().subscribe(user => {
      if (user === null || user === undefined)
        this.testSubmitted = new BehaviorSubject<boolean>(false); 
    });
  }

  ngOnDestroy(): void{
    this.disconnect()
  }

  resetCommunicationTimeout(timeoutDuration: number = 20000) {
    if(this.communicationTimeout) {
      clearTimeout(this.communicationTimeout);
    }
    this.communicationTimeout = setTimeout(this.didLoseCommunication, timeoutDuration);
  }

  didLoseCommunication = () => {
    // If we have not heard from the WS server in 20 seconds, disconnect.
    if (this.ws) {
      console.log('student close ws')
      this.ws.close(3001, PING_TIMEOUT_MSG);
      this.ws = undefined;
      clearInterval(this.pingInterval);
    }

    this.socketReady = false;
    this.resetCommunicationTimeout();
  }

  onTestSubmitted(){
    this.testSubmitted.next(true);
  }

  onUserInfoChange(info) {
    if(info && info.accountType !== AccountType.STUDENT){
      return;
    }
    if(info) {
      //Update the classID
      this.setClassId(this.auth.u().sch_class_group_id || info.sch_class_group_id);
      if(!this.isConnected()){
        this.connect();
      }
    }

    else if(!info && this.isConnected()){
      this.disconnect()
    }
  }

  setClassId(classId) {
    this.classId = classId;
  }

  connect() {
    console.log('student connect')

    this.userType = this.auth.user().value.accountType;
    if(this.userType != AccountType.STUDENT) {
      this.userType = AccountType.EDUCATOR;
    }

    if(this.isConnected()) {
      this.disconnect();
    }
    this.resetCommunicationTimeout();
    this.ws = new Sockette(this.wsURL, {
      timeout: 10e3,
      onopen: async (ev:Event) => {
        this.onUserConnect()
      },
      onmessage: (ev:MessageEvent) => { this.onUserMessage(ev) },
      onreconnect: (ev:Event | CloseEvent) => {
        this.socketReady = false
        console.debug('websocket trying to reconnect...', ev);
      },
      onmaximum: (ev: CloseEvent) => {
        console.debug('websocket max connection attempts reached, giving up', ev);
        this.socketReady = false;
      },
      onclose: (ev: CloseEvent) => {
        console.debug('websocket closed!', ev);
        this.socketReady = false
        this.onUserDisconnect(ev)
      },
      onerror: (ev: Event) => {
        console.debug('websocket error:', ev)
        this.socketReady = false
      }
    });
    console.log('student init ping interval')
    if(this.userType == AccountType.EDUCATOR) {
      this.setEducatorPingInterval();
    }
    this.pingInterval = setInterval(() => {
      console.log('student ws ping', this.userType)
      this.wsSend('ping',{
        ping: "Ping!",
        uid: this.auth.getUid(),
        userType: this.userType,
        classId: this.classId
      });
    }, HEARTBEAT_PING_INTERVAL);
  }

  /**
   * Pings every student in the class to see if they are still connected
   * If they are not, they are removed from the list of connected students
   * Chained, so that it will ping again after the timeout
   */
  private setEducatorPingInterval() {
    setTimeout(async () => {
      console.log('pinging students')
      this.pingStudents().finally(() => {
        this.setEducatorPingInterval();
      });
    }, EDUCATOR_PING_INTERVAL);
  }

  private async pingStudents() {
    return await this.auth.apiUpdate(this.routes.EDUCATOR_WEBSOCKET_CONNECTED_USERS, this.classId, {});
  }

  private wsSend (action:string, data:any, forceSend? : boolean):void {
    if (this.socketReady || forceSend) {
      this.ws.json({ action, data });
      return;
    }
    this.socketQueue.push({ action, data });
  }

  private onSocketReady() {
    this.socketReady = true;

    while (this.socketQueue.length) {
      const { action, data } = this.socketQueue.shift();
      this.wsSend(action, data);
    }
  }

  disconnect() {
    if(this.ws) {
      console.log('student close ws')
      this.ws.close();
      this.ws = undefined;
      this.logOffUser();
    }
    clearInterval(this.pingInterval);
    clearTimeout(this.communicationTimeout);
    this.socketReady = false;
  }

  public logOffUser() {
    // shows user as offline
    const uid = this.auth.getUid();
    this.auth.apiRemove(this.routes.EDUCATOR_WEBSOCKET_CONNECTED_USERS, uid, {query: {
      userType: this.userType,
      connectionId: this.userConnectionId,
      classId: this.classId
    }});
  }

  updateStudentPosition(data: Partial<IStudentPositionUpdate>) {
    // For ease of testing and deploying, alternating between dev and non-dev endpoints each time we release an update to the AWS code.
    // this.wsSend('studentUpdatePositionDev', data);
    const apiHost = this.apiHost;
    const classId = this.classId;

    const fullData = {...data, apiHost, classId}
    this.auth.apiCreate(this.routes.EDUCATOR_WEBSOCKET_STUDENT_SOFTLOCK, fullData);
    // this.ws.json({action: "studentUpdatePosition", data});
  }

  updateStudentSoftLock(data:{softLock:number}){
    this.wsSend('updateStudentSoftLock', data)
  }

  // updateStudentSoftLock(data:{softLock:boolean}){
  //   this.wsSend('updateStudentSoftLock',data)
  // }

  notifyStudentsOfSubsession(uids: number[], available: boolean, caption: string, asmtSlug: ASSESSMENT, subSessionSlug: string, isSubmitting?: boolean) {
    // this.wsSend('teacherNotifyStudents', { uids, available, caption, asmtSlug, subSessionSlug, isSubmitting });
    this.auth.apiCreate(this.routes.EDUCATOR_WEBSOCKET_STUDENT_SUBSESSION_NOTIFICATION, {
      uids,
      available,
      caption,
      asmtSlug,
      subSessionSlug,
      isSubmitting,
      classId: this.classId,
      apiHost: this.apiHost
    })

    this.resetStudentPosition(uids)
  }

   onUserConnect() {
    console.log('student onUserConnect')
    this.wsSend('studentConnectDev', {
      uid: this.auth.getUid(),
      userType: this.userType,
      classId: this.classId,
      apiHost: this.apiHost,
    }, true);
    this.socketReady = true;
    this.pingStudents();
  }

  private onUserDisconnect(ev:CloseEvent) {
    if (ev.reason === PING_TIMEOUT_MSG) {
      this.connect();
    }
  }

  private onUserMessage(e) {
    if(e.data === `User ${this.auth.getUid()} Connected.`) {
      this.onSocketReady();
    }
    let eObj;

    try {
      eObj = JSON.parse(e.data);
      //console.log(eObj)
    } catch(e) {
      //Don't set eObj
    }
    if(!eObj) {
      return;
    }

    if (Object.keys(eObj).includes('connectionId')) {
      this.userConnectionId = eObj.connectionId;
    }

    this.resetCommunicationTimeout();
    switch (this.userType) {
      case AccountType.STUDENT:
        this.onStudentMessage(eObj);
        break;
      case AccountType.EDUCATOR:
       // console.log('is educator',eObj)
        this.onTeacherMessage(eObj);
        break;
    }
  }

  private resetStudentPosition(uids) {
    for(let uid of uids) {
      this.updateStudentPositionSub.next({
        uid: uid,
      });
    }
  }

  private onTeacherMessage(e) {
    switch(e.eventType) {
      case 'updateConnectedStudents':
        this.connectedStudentsSub.next(e.connectedStudents);
        break;
      case 'updateStudentPosition':
        //console.log(e)
        this.updateStudentPositionSub.next({
          uid: e.uid,
          softLock: e.softLock,
          stageIndex: e.stageIndex,
          questionIndex: e.questionIndex,
          questionCaption: e.questionCaption,
          submitConfig: e.submitConfig,
          numTimesOffScreen: e.numTimesOffScreen,
        });
      case 'updateDisconnectedStudents':
        //console.log(e,"getting disconnected students")
        this.disconnectedStudentsSub.next(e.msg)
        break;
      case 'autoCloseWarning':
        this.autoSubmissionTeacherPopupSub.next({testWindow:e.testWindow, testSession:e.testSession})
        break
      case 'refreshPage':
        window.location.reload();
        break;
    }
  }

  private onStudentMessage(e) {
    switch(e.eventType) {
      case 'notifyAssessmentAvailable':
        if (!e.isSubmitting) {
          this.showAssessmentPopup(e.available, e.caption, e.asmtSlug, e.subSessionSlug);
        }
        this.subSessionStateChange.next({
          available: e.available,
          caption: e.caption,
          asmtSlug: e.asmtSlug,
          subSessionSlug: e.subSessionSlug,
          isSubmitting: e.isSubmitting
        })
        break;
      case 'ping':
        console.log('ping', e)
        break;
      case 'forceLogout':
        this.forceLogout();
        break;
    }
  }


  forceLogout() {
    this.auth.logout().then(() => {
      this.loginGuard.confirmationReqActivate({caption: this.lang.tra('lbl_logged_in_on_other_device'),
      btnCancelConfig: {hide: true}, confirm: () => {
        if (this.whiteLabel.getSiteFlag('IS_BCED')){
          this.router.navigate(['/en/bced-landing/admin']);
        }
        else{
          this.router.navigate(['/en/login-router-st']);
        }
      } });
    });
  }

  getAssessmentPopupText(available: boolean, caption: string, asmtSlug?: ASSESSMENT, subSessionSlug?: string) {
    const sessionState = this.lang.tra(available ? "questionnaire_opened":"session_closed");
    let messageSlug;
    if(asmtSlug === ASSESSMENT.G9_SAMPLE) {
      messageSlug = 'sample_test_opened_closed';
    } else if(asmtSlug === ASSESSMENT.OSSLT_SAMPLE) {
      messageSlug = 'practice_test_opened_closed';
    } else if(asmtSlug === ASSESSMENT.PRIMARY_SAMPLE || asmtSlug === ASSESSMENT.PRIMARY_OPERATIONAL || asmtSlug === ASSESSMENT.JUNIOR_SAMPLE || asmtSlug === ASSESSMENT.JUNIOR_OPERATIONAL) {
      if(subSessionSlug && subSessionSlug.startsWith("lang")) {
        messageSlug = 'pj_lang_session_opened_closed';
      } else if(subSessionSlug && subSessionSlug.startsWith("math")) {
        messageSlug = 'pj_math_stage_opened_closed';
      } else if (caption === 'Q') {
        messageSlug = 'test_session_Q_opened_closed';
      }
    } else if (caption === 'Q') {
      messageSlug = 'test_session_Q_opened_closed';
    } else {
      messageSlug = 'test_session_opened_closed';
    }
    const message = this.lang.tra(messageSlug, undefined, {caption, sessionState})
    return message;
  }

  showAssessmentPopup(available: boolean, caption: string, asmtSlug?: ASSESSMENT, subSessionSlug?: string) {
    const sessionState = this.lang.tra(available ? (caption === 'Q' ? "questionnaire_opened" : 'session_opened') : (caption === 'Q' ? "questionnaire_closed" : 'session_closed'));
    let messageSlug;
    if(asmtSlug === ASSESSMENT.G9_SAMPLE) {
      messageSlug = 'sample_test_opened_closed';
    } else if(asmtSlug === ASSESSMENT.OSSLT_SAMPLE) {
      messageSlug = 'practice_test_opened_closed';
    } else if(asmtSlug === ASSESSMENT.G9_OPERATIONAL && caption !== 'Q') {
      messageSlug = 'g9_session_opened_closed';
    } else if(asmtSlug === ASSESSMENT.PRIMARY_SAMPLE || asmtSlug === ASSESSMENT.PRIMARY_OPERATIONAL || asmtSlug === ASSESSMENT.JUNIOR_SAMPLE || asmtSlug === ASSESSMENT.JUNIOR_OPERATIONAL) {
      if(subSessionSlug && subSessionSlug.startsWith("lang")) {
        messageSlug = 'pj_lang_session_opened_closed';
      } else if(subSessionSlug && subSessionSlug.startsWith("math")) {
        messageSlug = 'pj_math_stage_opened_closed';
      } else if (caption === 'Q') {
        messageSlug = 'test_session_Q_opened_closed';
      }
    } else if (caption === 'Q') {
      messageSlug = 'test_session_Q_opened_closed';
    } else {
      messageSlug = 'test_session_opened_closed';
    }

    if (!available && this.auth.u()?.sch_class_group_type === 'EQAO_G9' || this.auth.u()?.sch_class_group_type === 'EQAO_G10') {
      // if(!this.assisTech.studentAssistiveTechStatus){ // possibly removed in CLOSER_LOOK_20210807 or the change was just not here yet
        // this.router.navigateByUrl(`${this.auth.getDashboardRoute(this.lang.c())}/main`)
      // }
      this.router.navigateByUrl(`${this.auth.getDashboardRoute(this.lang.c())}/main`)
    }

    const message = this.lang.tra(messageSlug, undefined, {caption, sessionState})
    this.loginGuard.quickPopup(message);

    //Acknowledge that the notification was received
    this.wsSend('studentAcknowledge', { uid: this.auth.getUid() })
  }

  isConnected():boolean {
    return !!this.ws;
  }
}
