<div class="page-body">
  <div>
    <header [breadcrumbPath]="breadcrumb" [hasSidebar]="true"></header>
    
    <div class="page-content is-fullpage " *ngIf="!hasAccess">
      Loading...
    </div>
    <div class="page-content is-fullpage " *ngIf="hasAccess">
      <img *ngIf="lang.c() === 'en'" style="width:8em;" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/EQAO_Logo_SVG/1609173725758/EQAO_Logo_SVG.svg">
      <img *ngIf="lang.c() === 'fr'" style="width:8em;" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6526/authoring/image/1606866913334/image.png">
      <h2 style="margin-bottom: 0"> {{schoolBoardInfo.name}} ({{schoolBoardInfo.foreign_id}}) </h2>
      <ntf-new-messages></ntf-new-messages>
      <div style="margin-top: 1em;">
        <menu-bar [menuTabs]="views" [tabIdInit]="selectedView" (change)="selectView($event)"></menu-bar>
        <div [ngSwitch]="selectedView">
          <div *ngSwitchCase="SchoolBoardView.TECH_READI">
            <sb-tech-readiness [boardInfo]="schoolBoardInfo"></sb-tech-readiness>
          </div>
          <!-- <div *ngSwitchCase="SchoolBoardView.CONTACT">
            <sb-it-contact [boardInfo]="schoolBoardInfo"></sb-it-contact>
          </div> -->
          <div *ngSwitchCase="SchoolBoardView.SECURITY">
            <tra-md slug="txt_board_it_landing_bottom"></tra-md>
          </div>
          <div *ngSwitchCase="SchoolBoardView.SESSIONS">
          <sb-sessions [boardInfo]="schoolBoardInfo"></sb-sessions>
          </div>
          <div *ngSwitchCase="SchoolBoardView.REPORTS">
            <div *ngIf="adminWindows.length == 0" style = "margin-left:1em; margin-bottom:1em;">
              <tra-md slug="txt_report_csv_note"></tra-md>
            </div>
            <div *ngIf="adminWindows.length > 0" style = "margin-left:1em; margin-bottom:1em;">
              <tra slug="sa_class_test_window_lb"></tra> 
              <select (change)="onAdminWindowChanged($event.target.value)" class="school-year-select">
                  <option *ngFor="let aw of adminWindows; let i = index" [value]="i" [selected]="i===currentAdminWindowIndex()">{{getAdminWindowSlug(aw)}}</option>
              </select>
              <!-- <tra-md slug="txt_report_csv_note"></tra-md> -->
              <tra-md slug="board_it_csv_info"></tra-md>
              <button class="file-cta" (click)="exportOSSLTResult()" style="cursor: pointer;">
                <span class="icon"><i class="fas fa-table"></i></span>
                <span><tra slug="ba_osslt_result_download"></tra></span>
              </button> 
            </div>
          </div>
        </div>
      </div>

      <!-- <sb-tech-readiness [boardInfo]="schoolBoardInfo"></sb-tech-readiness><br/> -->
      <div>
        <!-- <table style="width:auto; margin-bottom: 2em;">
          <tr>
            <th><tra slug="device_type"></tra></th>
            <th><tra slug="soft_ware"></tra></th>
            <th><tra slug="config_policy"></tra></th>
            <th><tra slug="pass_word"></tra></th>
          </tr>
          <tr>
            <td>
              Windows, Mac, iPad
            </td>
            <td>
              <a href="https://safeexambrowser.org/download_en.html" target="_blank">
                <tra slug="tech_redi_download_seb_sw"></tra>
              </a>
            </td>
            <td>
              <a target="_blank" [href]="schoolBoardInfo.seb.config">
                <tra slug="tech_redi_download_seb_cf"></tra>
              </a>
            </td>
            <td>
              <button (click)="isShowSebPass = !isShowSebPass" class="button is-small">
                <tra slug="tech_redi_download_seb_pass"></tra>
              </button>
              <div style="white-space: pre;"  *ngIf="isShowSebPass">{{schoolBoardInfo.seb.creds}}</div>
            </td>
          </tr>
          <tr>
            <td>
              Chromebook, Android (or Desktops requiring Chrome-based Accessibility Tools)
            </td>
            <td>
              <a target="_blank" href="https://chrome.google.com/webstore/detail/kiosk/afhcomalholahplbjhnmahkoekoijban" target="_blank">
                <tra slug="tech_redi_device_kiosk_ext"></tra>
              </a>
            </td>
            <td>
              <a target="_blank" [href]="schoolBoardInfo.kiosk.config">
                <tra slug="tech_redi_device_kiosk_cfg"></tra>
              </a>
            </td>
            <td>
              <button (click)="isShowKioskPass = !isShowKioskPass" class="button is-small">
                <tra slug="tech_redi_device_kiosk_pass"></tra>
              </button>
              <div style="white-space: pre;" *ngIf="isShowKioskPass">{{schoolBoardInfo.kiosk.creds}}</div>
            </td>
          </tr>
        </table> -->
      </div>

    </div>
  </div>
  <footer [hasLinks]="true"></footer>
</div>
