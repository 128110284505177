import { Component, Input, OnInit } from '@angular/core';
import { IContentElementTemplate } from './model';
import { QuestionState } from '../models';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';

@Component({
  selector: 'element-render-template',
  templateUrl: './element-render-template.component.html',
  styleUrls: ['./element-render-template.component.scss']
})
export class ElementRenderTemplateComponent implements OnInit {

  @Input() element:IContentElementTemplate;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter:number;
  @Input() questionPubSub?: QuestionPubSub;

  constructor() { }

  ngOnInit(): void {
  }

}
