<div>
    <!-- <p><tra slug="sa_tech_readiness_header_1"></tra></p>
    <br>
    <p><tra slug="sa_tech_readiness_header_2"></tra></p>
   -->
    <tra-md slug="txt_board_it_1"></tra-md>
    <h1><tra slug="lbl_technical_readiness"></tra></h1>
    <div>
        <filter-toggles 
          [state]="myBoard.classFilterToggles"
          (id)="setClassFilter($event)"
        ></filter-toggles>
    </div>
    <div *ngIf="!currentClassFilter">
        <tra-md slug="txt_guide_select_toggle_for_checklist"></tra-md>
    </div>
    <div style="margin-top:1em;" *ngIf="currentClassFilter">
        <table>
            <ng-container *ngIf="isG9Osslt()">
                <tr *ngFor="let item of list">
                    <td style="width:1em;">
                        <check-toggle [isChecked]="checklist[item.id+suffix]" (toggle)="toggleCheckList(item.id+suffix)" [disabled] = "listItemDisableCheck(item.id+suffix)"></check-toggle>
                    </td>
                    <td>
                        <tra-md [slug]="item.caption"></tra-md>
                        <div *ngIf="item.captionChecks" style="margin-top:1em;">
                            <ul>
                            <div *ngFor="let check of item.captionChecks">
                                <div [ngSwitch] = "check.status" >
                                    <div *ngSwitchCase="CheckingCaptionStatus.PASS">
                                        <li>
                                            <tra [slug] = "check.heading"></tra>
                                            <i class="fas fa-check check-status check-pass"></i>
                                        </li>
                                    </div>
                                    <div *ngSwitchCase="CheckingCaptionStatus.FAIL">
                                        <li>
                                            <tra class="check-fail" [slug] = "check.heading"></tra>
                                            <i class="fas fa-times check-status check-fail"></i>
                                            <tra class="check-status" [slug] = "check.failMessage"></tra>
                                        </li>
                                    </div>
                                    <div *ngSwitchCase="CheckingCaptionStatus.CHECKING">
                                        <li>
                                            <tra [slug] = "check.heading"></tra>
                                            <div class="progress-spinner check-status"></div>
                                        </li>
                                    </div>
                                    <div *ngSwitchCase="CheckingCaptionStatus.DEFAULT">
                                        <li>
                                            <tra [slug] = "check.heading"></tra>
                                        </li> 
                                    </div>
                                    <div *ngSwitchDefault>
                                        <li>
                                            <tra [slug] = "check.heading"></tra>
                                        </li> 
                                    </div>
                                </div>
                            </div>
                            </ul>
                        </div>    
                        <div *ngIf="item.selectionCaption" style="margin-top:1em;">
                            <div *ngFor="let caption of item.selectionCaption" class="">
                                <tra-md [slug]="caption.heading"></tra-md>
                                <div class="select is-multiple is-small"  style="margin-top:0.5em;">
                                    <div class="checkbox-option" *ngFor="let option of caption.selectionOptions">
                                        <check-toggle  [isChecked]="checklist[renderChecklistId(item, option)+suffix]" (toggle)="toggleCheckList(renderChecklistId(item, option)+suffix)"></check-toggle> 
                                        <div class="checklist-label">
                                            <tra-md (click)="toggleCheckList(renderChecklistId(item, option)+suffix)" [slug]="option.caption"></tra-md>
                                        </div>
                                    </div>
                                </div>
                            </div>          
                        </div>
                    </td>
                    <td>
                        <div *ngIf="item.links">
                            <div *ngFor="let link of item.links">
                                <a *ngIf="!link.isSecure" [class.disabled]="link.disabled" [href]="lang.tra(link.linkUrl)"
                                    target="_blank">
                                    <tra [slug]="link.linkCaption"></tra>
                                </a>
                                <br *ngIf="!link.isSecure"/>
                                <br *ngIf="!link.isSecure"/>
                                <a *ngIf="link.slug === 'SEB_CONFIG'" [class.disabled]="link.disabled" target="_blank"
                                    [href]="boardInfo.seb.config">
                                    <tra [slug]="link.linkCaption"></tra>
                                </a>
                                <br *ngIf="link.slug === 'SEB_CONFIG'"/>
                                <br *ngIf="link.slug === 'SEB_CONFIG'"/>
                                <button *ngIf="link.slug === 'SEB_CONFIG_PASS'" class="button is-small"
                                    (click)="isShowSebPass = !isShowSebPass">
                                    <tra slug="tech_redi_download_seb_pass"></tra>
                                </button>
                                <div style="white-space: pre;" *ngIf=" link.slug === 'SEB_CONFIG_PASS' && isShowSebPass">
                                    {{boardInfo.seb.creds}}</div>
                                <a *ngIf="link.slug === 'KIOSK_POLICY'" [class.disabled]="link.disabled" target="_blank"
                                    [href]="boardInfo.kiosk.config">
                                    <tra [slug]="link.linkCaption"></tra>
                                </a>
                                <br *ngIf="link.slug === 'KIOSK_POLICY'"/>
                                <br *ngIf="link.slug === 'KIOSK_POLICY'"/>
                                <button *ngIf="link.slug === 'KIOSK_POLICY_CREDS'" class="button is-small"
                                    (click)="isShowKioskPass = !isShowKioskPass">
                                    <tra slug="tech_redi_device_kiosk_pass"></tra>
                                </button>
                                <div style="white-space: pre;"
                                    *ngIf="link.slug === 'KIOSK_POLICY_CREDS' && isShowKioskPass">{{boardInfo.kiosk.creds}}
                                </div>
                                <button *ngIf="link.slug === 'DOMAIN_EXCLUSION_TEST'"
                                         class="button is-small"
                                        (click)="testDomainExclusion()">
                                    <tra [slug] = "link.linkCaption"></tra>
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="isPJ()">
                <tr *ngFor="let item of pjList">
                    <td style="width:1em;">
                        <check-toggle [isChecked]="checklist[item.id+suffix]" (toggle)="toggleCheckList(item.id+suffix)" [disabled] = "listItemDisableCheck(item.id+suffix)"></check-toggle>
                    </td>
                    <td>
                        <tra-md [slug]="item.caption"></tra-md>
                        <div *ngIf="item.captionChecks" style="margin-top:1em;">
                            <div *ngFor="let check of item.captionChecks">
                                <div [ngSwitch] = "check.status" >
                                    <div *ngSwitchCase="CheckingCaptionStatus.PASS">
                                        <li>
                                            <tra [slug] = "check.heading"></tra>
                                            <i class="fas fa-check check-status check-pass"></i>
                                        </li>
                                    </div>
                                    <div *ngSwitchCase="CheckingCaptionStatus.FAIL">
                                        <li>
                                            <tra class="check-fail" [slug] = "check.heading"></tra>
                                            <i class="fas fa-times check-status check-fail"></i>
                                            <tra class="check-status" [slug] = "check.failMessage"></tra>
                                        </li>
                                    </div>
                                    <div *ngSwitchCase="CheckingCaptionStatus.CHECKING">
                                        <li>
                                            <tra [slug] = "check.heading"></tra>
                                            <div class="progress-spinner check-status"></div>
                                        </li>
                                    </div>
                                    <div *ngSwitchCase="CheckingCaptionStatus.DEFAULT">
                                        <li>
                                            <tra [slug] = "check.heading"></tra>
                                        </li> 
                                    </div>
                                    <div *ngSwitchDefault>
                                        <li>
                                            <tra [slug] = "check.heading"></tra>
                                        </li> 
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div *ngIf="item.selectionCaption" style="margin-top:1em;">
                            <div *ngFor="let caption of item.selectionCaption" class="">
                                <tra-md [slug]="caption.heading"></tra-md>
                                <div class="select is-multiple is-small"  style="margin-top:0.5em;">
                                    <div class="checkbox-option" *ngFor="let option of caption.selectionOptions">
                                        <check-toggle  [isChecked]="checklist[renderChecklistId(item, option)+suffix]" (toggle)="toggleCheckList(renderChecklistId(item, option)+suffix)"></check-toggle> 
                                        <div class="checklist-label">
                                            <tra-md (click)="toggleCheckList(renderChecklistId(item, option)+suffix)" [slug]="option.caption"></tra-md>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div *ngIf="item.links">
                            <div *ngFor="let link of item.links">
                                <a *ngIf="!link.isSecure" [class.disabled]="link.disabled" [href]="lang.tra(link.linkUrl)"
                                    target="_blank">
                                    <tra [slug]="link.linkCaption"></tra>
                                </a>
                                <br *ngIf="!link.isSecure"/>
                                <br *ngIf="!link.isSecure"/>
                                <a *ngIf="link.slug === 'SEB_CONFIG'" [class.disabled]="link.disabled" target="_blank"
                                    [href]="boardInfo.seb.config">
                                    <tra [slug]="link.linkCaption"></tra>
                                </a>
                                <br *ngIf="link.slug === 'SEB_CONFIG'"/>
                                <br *ngIf="link.slug === 'SEB_CONFIG'"/>
                                <button *ngIf="link.slug === 'SEB_CONFIG_PASS'" class="button is-small"
                                    (click)="isShowSebPass = !isShowSebPass">
                                    <tra slug="tech_redi_download_seb_pass"></tra>
                                </button>
                                <div style="white-space: pre;" *ngIf=" link.slug === 'SEB_CONFIG_PASS' && isShowSebPass">
                                    {{boardInfo.seb.creds}}</div>
                                <a *ngIf="link.slug === 'KIOSK_POLICY'" [class.disabled]="link.disabled" target="_blank"
                                    [href]="boardInfo.kiosk.config">
                                    <tra [slug]="link.linkCaption"></tra>
                                </a>
                                <br *ngIf="link.slug === 'KIOSK_POLICY'"/>
                                <br *ngIf="link.slug === 'KIOSK_POLICY'"/>
                                <button *ngIf="link.slug === 'KIOSK_POLICY_CREDS'" class="button is-small"
                                    (click)="isShowKioskPass = !isShowKioskPass">
                                    <tra slug="tech_redi_device_kiosk_pass"></tra>
                                </button>
                                <div style="white-space: pre;"
                                    *ngIf="link.slug === 'KIOSK_POLICY_CREDS' && isShowKioskPass">{{boardInfo.kiosk.creds}}
                                </div>
                                <button *ngIf="link.slug === 'DOMAIN_EXCLUSION_TEST'" class="button is-small"
                                    (click)="testDomainExclusion()">
                                    <tra [slug] = "link.linkCaption"></tra>
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-container>
        </table>
        <br />
        <div class='sa-tech-readiness-confirmation-div'>
            <div>
                <p>
                    <!-- <span style="font-weight: bold; margin-right: 5px;"><tra slug="sa_tech_readiness_footer_note"></tra></span> -->
                    <tra-md slug="txt_board_it_2"></tra-md>
                </p>
                <!-- <span style="font-weight: bold; margin-right: 5px;"><tra slug="sa_tech_readiness_footer_note"></tra></span> -->
                <!-- <tra-md slug="txt_bmp_rep"></tra-md> -->
                <tra-md slug="txt_bmp_rep2"></tra-md>
                
            </div>
            <div>
                <!-- <div style="margin-bottom:1em;">
            <button class="button is-small is-success" [disabled]="!techReadinessConfirmed"> <tra slug="sa-tech-readiness-submit"></tra> </button>
          </div> -->
                <div *ngIf='techReadinessConfirmed'>
                    <div class='success-msg'>
                        <tra slug="board_completed_network_readiness"></tra>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="custom-modal" *ngIf="cModal()">
        <div class="modal-contents">
            <div [ngSwitch]="cModal().type">
                <div *ngSwitchCase="TechReadinessModal.NEW" style="width: 30em; text-align: center;">
                    <div *ngIf="cModal().config.text">
                        <h3>
                            <tra [slug]="cModal().config.caption"></tra>
                        </h3>
                        <div style="white-space: pre;">{{cModal().config.text}}</div>
                    </div>
                    <div *ngIf="cModal().config.link">
                        <div>
                            <tra-md [slug]="cModal().config.descSlug"></tra-md>
                        </div>
                        <a [href]="cModal().config.link" target=_blank>
                            <tra [slug]="cModal().config.caption"></tra>
                        </a>
                    </div>
                </div>
                <div *ngSwitchCase="TechReadinessModal.WARN" style="width: 30em; text-align: center;">
                    <div>
                        <tra slug="board_network_readiness_status_change"></tra>
                    </div>
                </div>
                <div *ngSwitchCase="TechReadinessModal.TOGGLEWARN" style="width: 30em; text-align: center;">
                    <div>
                        <tra-md slug= "board_tech_redi_toggle_check_warning" ></tra-md>
                    </div>
                </div>
            </div>
            <br />
            <modal-footer [pageModal]="pageModal" ></modal-footer>
        </div>
    </div>

</div>
<br/>
<!-- <tra slug="sb_additional_info"></tra><br />
<tra-md slug="sb_user_guide"></tra-md> -->