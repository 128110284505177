import { IContentElementAnnotatedText } from "src/app/ui-testrunner/element-render-annotation/model";
import { IContentElementCanvas, IContentElementCanvasPage } from "src/app/ui-testrunner/element-render-canvas/model";
import { IContentElementCustomMCQ, IContentElementCustomMcqOption } from "src/app/ui-testrunner/element-render-custom-mcq/model";
import { DndTargetType, IContentElementDnd } from "src/app/ui-testrunner/element-render-dnd/model";
import { IContentElementFrame, IContentElementFrameStateStyle } from "src/app/ui-testrunner/element-render-frame/model";
import { IContentElementGroup } from "src/app/ui-testrunner/element-render-grouping/model";
import { defaultImageScaleFactor, IContentElementConditionalImage, IContentElementDynamicImage, IContentElementImage, IContentElementImageSubText } from "src/app/ui-testrunner/element-render-image/model";
import { IContentElementInput, InputFormat } from "src/app/ui-testrunner/element-render-input/model";
import { IContentElementInsertion } from "src/app/ui-testrunner/element-render-insertion/model";
import { IContentElementMath } from "src/app/ui-testrunner/element-render-math/model";
import { IContentElementMcq, IContentElementMcqOption, McqDisplay } from "src/app/ui-testrunner/element-render-mcq/model";
import { IContentElementLocAndDims, IContentElementMoveableDragDrop } from "src/app/ui-testrunner/element-render-moveable-dnd/model";
import { IContentElementOrder, IContentElementOrderOption } from "src/app/ui-testrunner/element-render-order/model";
import { IContentElementResultsPrint } from "src/app/ui-testrunner/element-render-results-print/model";
import { IContentElementSbs } from "src/app/ui-testrunner/element-render-sbs/model";
import { IContentElementSelectableText, IContentElementTextSelection } from "src/app/ui-testrunner/element-render-select-text/model";
import { IContentElementSelectionTable } from "src/app/ui-testrunner/element-render-selection-table/model";
import { IContentElementSolution } from "src/app/ui-testrunner/element-render-solution/model";
import { IContentElementTable } from "src/app/ui-testrunner/element-render-table/model";
import { IContentElementTextLink } from "src/app/ui-testrunner/element-render-text-link/model";
import { IContentElementText, TextParagraphStyle } from "src/app/ui-testrunner/element-render-text/model";
import { IContentElementVideo } from "src/app/ui-testrunner/element-render-video/model";
import { ElementType, IContentElement, IVoiceover } from "../../../ui-testrunner/models";
import { elementTypes } from "../../../ui-testrunner/models/ElementTypeDefs";
import { CustomButtonPos } from "../../models";
import { indexOf } from "../../services/util";
import { IContentElementPassage, PASSAGE_LIN_COUNT_INTERVAL_DEFAULT_SYS, PassageCounterType } from "src/app/ui-testrunner/element-render-passage/model";
import { IContentElementTemplate } from "src/app/ui-testrunner/element-render-template/model";

export interface ICustomTaskSet { 
  __id?: string,
  __backup__id?: string,
  __backup__userDisplayName?: string,
  name?: string,
  order?: number,
  taskId?: string, // cross link to collection `tasks`
  project?: string,
  isReady?: boolean,
  labelCounter?:number,
  isArchived?: boolean,
  questions?:IQuestionConfig[] | string,
  creatorUid?: string,
  timeCreated?: number,
  timeLastSaved?: number,
  schemaVersion?: number,
  lastTouchedBy?: string,
  assessmentFrameworkId?:string,
  isProtected?:boolean,
}

export interface ICustomTaskTag {
  __id?:string,
  caption: string,
  customTaskSetId: string,
  localTaskId: number,
  createdBy: string,
  timestamp: number,
}

export interface ICustomTaskComment {
  __id?:string,
  caption: string,
  customTaskSetId: string,
  localTaskId: number,
  createdByName: string,
  createdByUid: string,
  timestamp: number,
}
export interface IQuestionRun {
  content: IContentElement[],
  // voiceover?: {url:string},
  entryOrder?: number[],
  isReadingSelectionPage?: boolean,
  readingSelectionCaption?: string,
  test_question_version_id?:number,
}
export interface IQuestionConfig extends IQuestionRun {
  id?: number,
  label: string,
  isReady?: boolean,
  readSel?:string,
  caption?:string,
  points?:string,
  ishidePoints?: boolean;
  // captionVoiceover?: IVoiceover;
  readSelections?:string[],
  isInfoSlide?: boolean,
  notes?: string,
  taskId?: string,
  localTaskId: number,
  entryIdCounter?: number,
  testLabel?: string,
  isReqFill?:boolean,
  reqFillEntries?:string,
  reqFillMsg?:string,
  isReadingSelectionsAlwaysShown?: boolean,
  isStartHalf?: boolean,
  backgroundImage?: IContentElementImage,
  bannerImage?: IContentElementImage,
  bannerSubtitle?: IContentElementText,
  bannerSubtitleMarginBottom?: number,
  bannerTitle?: IContentElementText,
  showBannerHr?: boolean,
  bannerHrColor?: string,
  bannerOverlay?: IContentElementImage,
  useCustomPrev?: boolean,
  useCustomNext?: boolean,
  customPrevBgColor?: string,
  customNextBgColor?: string,
  customPrevFgColor?: string,
  customNextFgColor?: string,
  customPrevText?: string,
  customNextText?: string,
  customPrevBold?: boolean,
  customNextBold?: boolean,
  customButtonPos?: CustomButtonPos,
  customButtonIndent?: number
}

export interface ExpectedAnswer {
  total?: number;
  formatted_response: string;
  score: number;
  weight: number;
  entries?: any[];
  lang?: string;
}

export const generateDefaultElementText = (elementType):IContentElementText => {
  return {
    elementType,
    caption: '',
    simpleList: [],
    advancedList: [],
    paragraphStyle: TextParagraphStyle.REGULAR
  }
}

export const generateDefaultElementAnnotatedText = ():IContentElementAnnotatedText => {
  const obj = {
    ...generateDefaultElementText(ElementType.TEXT),
    text: generateDefaultElementText(ElementType.TEXT),
    annotation: ""
  }
  obj.elementType = ElementType.ANNOTATION
  return obj;
}


export const generateDefaultFrameStateStyle = ():IContentElementFrameStateStyle => {
  console.log('generateDefaultElementTable')
  return {
    backGroundImg: generateDefaultElementImage(ElementType.IMAGE),
    dropShadowX: 0,
    dropShadowY: 0,
    blurRadius: 0,
    shadowColor: "#ffffff",
    backgroundColor: "#ffffff",
    padding: 0,
  }
}

export const generateDefaultElementTable = (elementType):IContentElementTable => {
  console.log('generateDefaultElementTable')

  let grid = [];
  const defaultRows = 3;
  const defaultCols = 2;
  for(let r = 0; r < defaultRows; r++) {
    const cols = [];
    for(let c = 0; c < defaultCols; c++) {
      const el = createDefaultElement(ElementType.TABLE_TEXT)
      cols.push(el)
    }
    grid.push(cols);
  }

  return {
    elementType,
    grid: grid,
    isHeaderRow: false,
    isHeaderCol: false,
    isColWidthConst: false,
    isTableOfValues: true,
    colWidthConst: 4
  }
}
export const generateDefaultElementMath = (elementType):IContentElementMath => {
  return {
    elementType,
    latex: '',
    _changeCounter: 0,
    paragraphStyle: TextParagraphStyle.REGULAR
  }
}
export const generateDefaultElementImage = (elementType):IContentElementImage => {
  return {
    elementType,
    url: null,
    scale: 100,
    scaleFactor: defaultImageScaleFactor
  }
}

export const generateOldDefaultImage = (element:IContentElementDynamicImage, condition:string) => {
  const image = { 
    elementType: ElementType.IMAGE,
    url: element.url, 
    fileType: element.fileType,
    altText: element.altText,
    scale: element.scale,
    outline: element.outline
  }
  return { condition: condition, image };
}

export const generateDefaultElementVideo = (elementType):IContentElementVideo => {
  return {
    elementType,
    url: null,
    urls: [],
  }
}


export const generateDefaultElementMcq = (elementType):IContentElementMcq => {
  return {
    elementType,
    displayStyle: McqDisplay.VERTICAL,
    options: [
      {elementType:ElementType.TEXT, optionType: ElementType.MCQ_OPTION, content: 'Option 1', isCorrect:false, optionId: 1, link: generateDefaultElementTextLink()},
      {elementType:ElementType.TEXT, optionType: ElementType.MCQ_OPTION, content: 'Option 2', isCorrect:true,  optionId: 2, link: generateDefaultElementTextLink()},
      {elementType:ElementType.TEXT, optionType: ElementType.MCQ_OPTION, content: 'Option 3', isCorrect:false, optionId: 3, link: generateDefaultElementTextLink()},
      {elementType:ElementType.TEXT, optionType: ElementType.MCQ_OPTION, content: 'Option 4', isCorrect:false, optionId: 4, link: generateDefaultElementTextLink()},
    ]
  }
}

export const generateDefaultElementTextLink = ():IContentElementTextLink => {
  return { elementType: "text_link", caption: ""};
}

export const generateDefaultElementOrder = (elementType):IContentElementOrder => {
  return {
    elementType,
    displayStyle: McqDisplay.HORIZONTAL,
    isScrambled: false,
    delimeter: '',
    options: [
    ]
  }
}

export const generateDefaultElementDnd = (elementType):IContentElementDnd => {
  return {
    elementType,
    targetType: DndTargetType.TARGET,
    defaultTargetStyle: null,
    draggableCounter:0,
    targetCounter:0,
    width: 360,
    height: 200,
    backgroundElements: [], 
    draggables: [],
    targets: [],
    groups:[],
  } 
}

export const generateDefaultElementGrouping = (elementType) => {
  return {
    elementType,
    targetType: DndTargetType.TARGET,
    defaultTargetStyle: null,
    draggableCounter:0,
    targetCounter:0,
    width: 360,
    height: 200,
    draggables: [],
    targets: [],
  }
}

export const generateDefaultElementPassage = (elementType:string):IContentElementPassage => {
  return {
    elementType,
    text: '\n\n\n\n\n\n\n\n\n',
    images: [],
    counterType: PassageCounterType.LINE,
    lineCountInterval: PASSAGE_LIN_COUNT_INTERVAL_DEFAULT_SYS,
  }
}
export const generateDefaultElementTemplate = (elementType:string):IContentElementTemplate => {
  return {
    elementType,
    templateId: -1, // invalid values, indicate that a template needs to be selected
    templateVersion: -1, // invalid values, indicate that a template needs to be selected
    content: [],
    configs: {},
  }
}

export const generateDefaultElementFrame = (elementType):IContentElementFrame => {
  return {
    elementType,
    content: [],
    // activatedStyles: [],
    // styleRaw:{},
  }
}

export const generateDefaultElementCanvas = (elementType):IContentElementCanvas => {
  return {
    elementType,
    pages: [],
    width: 1,
    height: 1
  }
}

export const generateDefaultElementInput = (elementType):IContentElementInput => {
  return {
    elementType,
    format: null,
    alternativeValues: [],
    ratioTerms: [],
  }
}

export const getElementChildrenText = (element:IContentElementText) => {
  let elements = [];
  if (element.advancedList){
    elements = elements.concat(element.advancedList.map(el => el));
  }

  if(element.link) {
    elements.push(element.link);
  }

  if(element.paragraphList) {
    elements = elements.concat(element.paragraphList.map(el => el));
  }

  return elements;
}

export const getElementChildrenSelectText = (element:IContentElementTextSelection) => {
  const elements:IContentElement[] = [];
  for(const text of element.texts) {
    if(text.elementType) {
      elements.push(text);
    }
  }

  for(const p of (element.paragraphs || [])) {
    for(const par of p) {
      if(par.elementType) {
        elements.push(par);
      }
    }
  }
  return elements;
}
export const getElementChildrenTable = (element:IContentElementTable) => {
  const elements:IContentElement[] = [];
  if (element.grid){
    for(const row of element.grid) {
      for(const cell of row) {
        if (cell.elementType){
          elements.push(<IContentElement> cell);
        }
      }
    }
  }
  return elements;
}
export const getElementChildrenSbs = (element:IContentElementSbs) => {
  return element.left.concat(element.right);
}
export const getElementChildrenMcq = (element:IContentElementMcq) => {
  const elements:IContentElement[] = []
  if (element.options){
    element.options.forEach(option => {
      if (option.elementType){ // this is not handling the simple text properly
        elements.push(option);
      }
    })
  }
  return elements;
}
export const getElementChildrenOrder = (element:IContentElementOrder) => {
  const elements:IContentElement[] = []
  if (element.options){
    element.options.forEach(option => {
      if (option.elementType){ // this is not handling the simple text properly
        elements.push(option);
      }
    })
  }
  return elements;
}

export const getElementChildrenFrame = (element:IContentElementFrame)=>{
  return element.content.map( el => el )
}
export const getElementChildrenTemplate = (element:IContentElementTemplate) => {
  return element.content.map( el => el )
  // const elements: IContentElement[] = [];
  // for (let subElement of element.content){
  //   elements.push(... getElementChildren(subElement))
  // }
  // return elements;
}

export const getElementChildrenCanvas = (element:IContentElementCanvas)=>{
  const elements:IContentElement[] = []
  element.pages.forEach((page:IContentElementCanvasPage)=>{
    page.displayList.forEach(el => {
      if (el.elementType){
        elements.push(el);
      }
    });
  })
  return elements;
}

export const getElementChildrenSelectTable = (element:IContentElementSelectionTable)=> {
  const elements:IContentElement[] = []
  element.leftCol.forEach(cell => {
    if (cell.content && cell.content.elementType) {
      elements.push(cell.content)
    }
    if (cell.audio) {
      elements.push(cell.audio)
    }
  });

  for(const cell of element.topRow) {
    if(cell.content && cell.content.elementType) {
      elements.push(cell.content)
    }
    if(cell.audio) {
      elements.push(cell.audio);
    }
  }
  
  const propNames = ['mathCellElement', 'textCellElement', 'imgCellElement', 'mathCellSelectedElement', 'textCellSelectedElement', 'imgCellSelectedElement']; 
  for(const row of element.checkBoxRows) { 
    for(const col of row) {
      for(const prop of propNames) {
        if(col[prop]) {
          elements.push(col[prop]);
        }
      }
    }
  }

  if(element.topLeftText) {
    elements.push(element.topLeftText);
  }
  // console.log(elements);
  return elements
}

export const getElementChildrenImage = (element: any) => {
  const elements:IContentElement[] = [];
  //Could be IContentElementDynamicImage
  if(element.images) {
    for(const condImage of Object.values(element.images)) {
      if(condImage){
        const image = (<IContentElementConditionalImage>condImage).image;
        if(image.elementType) {
          elements.push(image);
        }
      }
    }
  }

  if(element.subtexts) {
    for(const subtext of element.subtexts) {
      if(subtext.elementType) {
        elements.push(<IContentElementImageSubText>subtext);
      }
    }
  }

  if(element.hiContrastImg) {
    elements.push(element.hiContrastImg);
  }

  if(element.frameState?.bgdImg) {
    elements.push(element.frameState.bgdImg);
  }

  return elements;
}

export const getElementChildrenInsertion = (element: IContentElementInsertion) => {
  const elements: IContentElement[] = [];

  for(const draggable of element.draggables.concat(element.textBlocks)) {
    const el = draggable.element;
    if(el.elementType) {
      elements.push(el);
    }
  }

  return elements;
}

export const getElementChildrenMoveableDnd = (element:IContentElementMoveableDragDrop) => {
  const elements: IContentElement[] = [];
  for(const draggable of element.draggables) {
    const el = draggable.element;
    if(el.elementType) {
      elements.push(el);
    }
  }

  if(element.backgroundImg) {
    elements.push(element.backgroundImg);
  }

  //Not technically a content element
  // for(const target of element.targets) {
  //   elements.push(target);
  // }

  return elements;
}



export const getElementChildrenGrouping = (element:IContentElementGroup) => {
  const elements: IContentElement[] = [];
  
  if(element.separatorText && element.separatorText.elementType) {
    elements.push(element.separatorText);
  }

  if(element.separatorImage && element.separatorImage.elementType) {
    elements.push(element.separatorImage);
  }

  if(element.separatorCanvas && element.separatorCanvas.elementType) {
    elements.push(element.separatorCanvas);
  }

  for(const draggable of element.draggables) {
    const el = draggable.element;
    if(el.elementType) {
      elements.push(el);
    }
  }  

  for(const target of element.targets) {
    const el = target.element;
    if(el && el.elementType) {
      elements.push(el);
    }
    const bgdImg = target.backgroundImg;
    if(bgdImg && bgdImg.elementType) {
      elements.push(bgdImg);
    }
  }
  return elements;
}

export const getElementChildrenResultsPrint = (element:IContentElementResultsPrint) => {
  const elements: IContentElement[] = [];
  for(const el of element.content) {
    if(el.elementType) {
      elements.push(el);
    }
  }
  return elements;
}

export const getElementChildrenSolution =(element:IContentElementSolution) => {
  const elements: IContentElement[] = [];
  for(const el of element.content) {
    if(el.elementType) {
      elements.push(el);
    }
  }
  return elements;
}

export const getElementChildrenCustomMcq = (element:IContentElementCustomMCQ) => {
  return getElementChildrenMcq(element);
}

export const getElementChildrenDnd = (element:IContentElementDnd) => {
  const elements: IContentElement[] = [];

  for(const bgdEl of element.backgroundElements) {
    const el = bgdEl.element;
    if(el.elementType) {
      elements.push(el);
    }
  }

  for(const draggable of element.draggables) {
    const el = draggable.element;
    if(el.elementType) {
      elements.push(el);
    }
  }

  //note: targets are not technically elements, so not included.

  return elements;
}

export const getElementChildrenInput = (element: IContentElementInput) => {
  if(element.alternativeValues) {
    return element.alternativeValues.map(el => el);
  }
  return [];
}

export const getElementChildrenTextLink = (element: IContentElementTextLink) => {
  const propNames = ['thumbNail', 'bookmarkImg'];
  const elements = [];

  for(const prop of propNames) {
    if(element[prop]) {
      elements.push(element[prop]);
    }
  }

  return elements;
}


export const getElementChildrenCustomMcqOption = (element: IContentElementCustomMcqOption) => {
  if(element.dynamicImage) {
    return [element.dynamicImage];
  }

  return [];
}

export const getElementChildrenOrderOption = (element: IContentElementOrderOption) => { 
  if(element.labelImg) {
    return [element.labelImg];
  }
  return [];
}

export const getElementChildrenMcqOption = (element: IContentElementMcqOption) => {
  if(element.link) {
    return [element.link];
  }

  return [];
}

export const getElementChildrenSelectableText = (element: IContentElementSelectableText) => {
  return [];
}


export const elementIconById:Map<string, string> = new Map();
elementTypes.forEach(elementType => {
  elementIconById.set(elementType.id, elementType.icon)
});

export const createDefaultElementWithDims = (elementType:string):IContentElementLocAndDims => {
  return {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    padding: 0,
    ...createDefaultElement(elementType)
  }
}

export const createDefaultElement = (elementType:string):IContentElement => {
  switch(elementType){
    case ElementType.TEXT:     return generateDefaultElementText(elementType);
    case ElementType.TABLE:    return generateDefaultElementTable(elementType);
    case ElementType.MATH:     return generateDefaultElementMath(elementType);
    case ElementType.IMAGE:    return generateDefaultElementImage(elementType);
    case ElementType.MCQ:      return generateDefaultElementMcq(elementType);
    case ElementType.ORDER:    return generateDefaultElementOrder(elementType);
    case ElementType.DND:      return generateDefaultElementDnd(elementType);
    case ElementType.VIDEO:    return generateDefaultElementVideo(elementType);
    case ElementType.INPUT:    return generateDefaultElementInput(elementType);
    case ElementType.FRAME:    return generateDefaultElementFrame(elementType);
    case ElementType.GROUPING: return generateDefaultElementGrouping(elementType);
    case ElementType.MATCHING: return generateDefaultElementGrouping(elementType);
    case ElementType.PASSAGE:  return generateDefaultElementPassage(elementType);
    case ElementType.TEMPLATE: return generateDefaultElementTemplate(elementType);
    case ElementType.CANVAS:   return generateDefaultElementCanvas(elementType);
    case ElementType.TABLE_TEXT: return generateDefaultElementTableText(elementType);
    default: return { elementType }
  }
}

export const generateDefaultElementTableText = (elementType) => {
  return {
    elementType,
    val: ''
  }
}
  
export const ensureDefaultElementProps = (element:any, elementType:string) => {
  const baseElement = createDefaultElement(elementType);
  element.elementType = elementType;
  Object.keys(baseElement).forEach(prop =>{
    if (!element[prop]){
      element[prop] = baseElement[prop]
    }
  })
}

export const frameElement = (content:any[], element:any) => {
  if (window.confirm('Frame this element?')){
    let i = indexOf(content, element);
    const replacementElement = <IContentElementFrame> createDefaultElement(ElementType.FRAME);
    replacementElement['x'] = element.x;
    replacementElement['y'] = element.y;
    replacementElement.content.push(element)
    content[i] = replacementElement;
  }
}

export const getAllTextElements = (elements:IContentElement[]) : string[] => {
  let allTexts = []
  if (elements) {
    elements.forEach(element => {
      if (element.elementType == ElementType.TEXT) {
        const el = <IContentElementText>element
        const style = el.paragraphStyle
        switch (style) {
          case TextParagraphStyle.LINK:
            console.log(el)
            if (el.link?.caption) allTexts = allTexts.concat(el.link.caption)
            break;
          case TextParagraphStyle.NUMBERED:
          case TextParagraphStyle.BULLET:
          case TextParagraphStyle.ADVANCED_INLINE:
            allTexts = allTexts.concat(getAllTextElements(el.advancedList))
            break;
          case TextParagraphStyle.PARAGRAPHS:
            el.paragraphList.forEach((word)=>{
              allTexts.push(word.caption)
            })
            break;
          case TextParagraphStyle.ANNOTATION:
            allTexts = allTexts.concat(getAllTextElements([el["text"]]))
            break;
          default: 
            allTexts.push(el.caption)
        }
        if (style==TextParagraphStyle.ADVANCED_INLINE) {
        } else if (style==TextParagraphStyle.PARAGRAPHS) {
          
        } else if (el.paragraphStyle==TextParagraphStyle.ANNOTATION) {
          
        } else {
          
        }
        
      } else {
        const childrenText = getElementChildren(element)
        allTexts = allTexts.concat(getAllTextElements(childrenText))
      }
    });
  }
  return allTexts
}

export const getElementChildren = (element:IContentElement, includeSolution: boolean = false, includeResultsPrint: boolean = false) : IContentElement[] => {
  let elements = [];
  
  switch(element.elementType){
    case ElementType.TEXT:          elements = getElementChildrenText   (<IContentElementText>   element); break;
    case ElementType.TEXT_LINK:     elements = getElementChildrenTextLink (<IContentElementTextLink> element); break;
    case ElementType.TABLE:         elements = getElementChildrenTable  (<IContentElementTable>  element); break;
    case ElementType.MCQ:           elements = getElementChildrenMcq    (<IContentElementMcq>    element); break;
    case ElementType.ORDER:         elements = getElementChildrenOrder  (<IContentElementOrder>  element); break;
    case ElementType.SBS:           elements = getElementChildrenSbs    (<IContentElementSbs>    element); break;
    case ElementType.SELECT_TABLE:  elements = getElementChildrenSelectTable (<IContentElementSelectionTable> element); break;
    case ElementType.CANVAS:        elements = getElementChildrenCanvas (<IContentElementCanvas> element); break;
    case ElementType.FRAME:         elements = getElementChildrenFrame  (<IContentElementFrame>  element); break;
    case ElementType.SELECT_TEXT:   elements = getElementChildrenSelectText (<IContentElementTextSelection> element); break;
    case ElementType.INSERTION:     elements = getElementChildrenInsertion (<IContentElementInsertion> element); break;
    case ElementType.IMAGE:         elements = getElementChildrenImage (<IContentElementImage> element); break;
    case ElementType.MOVEABLE_DND:  elements = getElementChildrenMoveableDnd (<IContentElementMoveableDragDrop> element); break;
    case ElementType.GROUPING:      elements = getElementChildrenGrouping(<IContentElementGroup> element); break;
    case ElementType.TEMPLATE:      elements = getElementChildrenTemplate(<IContentElementTemplate> element); break;
    case ElementType.CUSTOM_MCQ:    elements = getElementChildrenCustomMcq(<IContentElementCustomMCQ> element); break;
    case ElementType.RESULTS_PRINT: elements = (includeResultsPrint ? getElementChildrenResultsPrint(<IContentElementResultsPrint> element) : []); break;
    case ElementType.SOLUTION:      elements = (includeSolution ? getElementChildrenSolution(<IContentElementSolution> element) : []); break;
    case ElementType.DND:           elements = getElementChildrenDnd (<IContentElementDnd> element); break;     
    case ElementType.INPUT:         elements = getElementChildrenInput(<IContentElementInput> element); break;
    default: elements = []; 
  }

  const optionType = (<any>element).optionType;
  if(optionType) {
    let optionElements = [];
    switch(optionType) {
      case ElementType.CUSTOM_MCQ_OPTION:
        optionElements = getElementChildrenCustomMcqOption(<IContentElementCustomMcqOption>element); break;
      case ElementType.MCQ_OPTION:
        optionElements = getElementChildrenMcqOption(<IContentElementMcqOption>element); break;
      case ElementType.SELECTABLE_TEXT:
        optionElements = getElementChildrenSelectableText(<IContentElementSelectableText>element); break;
      case ElementType.ORDER_OPTION:
        optionElements = getElementChildrenOrderOption(<IContentElementOrderOption>element); break;
      default:
        break;
    }

    elements = elements.concat(optionElements);
  }
  return elements;
}

export const checkElementIsEntry = (element:IContentElement, isAutoScoreable?:boolean, typePropName = 'elementType') : boolean => {
  if (element["isScoringDisabled"] && element[typePropName] && String(element[typePropName]).startsWith(ElementType.INPUT)) {
    return false
  }
  if (element[typePropName]==ElementType.INPUT && (
    element["format"]==InputFormat.NUMBER ||
    element["format"]==InputFormat.NUMBER_LIST ||
    element["format"]==InputFormat.RATIO ||
    element["format"]==InputFormat.ALGEBRA ||
    element["format"]==InputFormat.FRACTION)) {
    return true;
  } else if (
    element[typePropName]==ElementType.INPUT+'-'+InputFormat.NUMBER ||
    element[typePropName]==ElementType.INPUT+'-'+InputFormat.NUMBER_LIST ||
    element[typePropName]==ElementType.INPUT+'-'+InputFormat.ALGEBRA ||
    element[typePropName]==ElementType.INPUT+'-'+InputFormat.FRACTION ||
    element[typePropName]==ElementType.INPUT+'-'+InputFormat.RATIO
  ) {
    return true;
  }
  switch(element[typePropName]){
    case ElementType.CAMERA:
    case ElementType.GRAPHING:
    case ElementType.UPLOAD:
    case ElementType.MIC:
      return !isAutoScoreable;
    case ElementType.INPUT:
      return !isAutoScoreable;
    case ElementType.DND:
    case ElementType.MOVEABLE_DND:
    case ElementType.MCQ:
    case ElementType.CUSTOM_MCQ:
    case ElementType.HOTSPOT:
    case ElementType.HOTTEXT:
    case ElementType.MATCHING:
    case ElementType.SELECT_TEXT:
    case ElementType.ORDER:
    case ElementType.INSERTION:
    case ElementType.SELECT_TABLE:
    case ElementType.GROUPING:
      if (element["isScoringDisabled"]) {
        return false
      }
    case ElementType.VALIDATOR:
    case ElementType.CUSTOM_INTERACTION:
      return true;
    case ElementType.PASSAGE:
    case ElementType.TEMPLATE: // it is always the template content that is the actual entry
    default: 
      return false;
  }
}



export const applyDefaultElementProps = (target:any, elementType:string) => {
  const ref = createDefaultElement(elementType);
  Object.keys(ref).forEach(key => {
    target[key] = ref[key];
  })
}
