export enum SchoolBoardView {
    TECH_READI = 'tech_readiness',
    //CONTACT = 'contact',
    SECURITY = 'security',
    SESSIONS = 'sessions',
    REPORTS = "reports"
  }

  export const SCHOOL_BOARD_VIEWS = [
    {
      id:SchoolBoardView.TECH_READI, 
      caption: ('lbl_network_readiness'), //'Technical Readiness',
      imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/clipboard/1602235150476/clipboard.png',
      description: ('txt_tech_readi'), //'',
      hasIndicator: true,
    },
    // {
    //   id:SchoolBoardView.CONTACT, 
    //   caption: ('lbl_contact_info'), //'Class Sections',
    //   imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/school/1602128112754/school.png',
    //   description: ('txt_sa_classrooms_info_1'), //'You can review and manage the classes as they progress with their introductory materials and administration of the assessment.',
    // },
    {
      id:SchoolBoardView.SECURITY, 
      caption:('lbl_security_info'), //'Teachers',
      imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/work-from-home/1602128147290/work-from-home.png',
      description: ('txt_teachers'), // '0 accounts registered. Use this view to send email invitations to teachers so that they can access their account.',
    },
    {
      id:SchoolBoardView.SESSIONS, 
      caption:('lbl_monitoring'), //'Teachers',
      imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/work-from-home/1602128147290/work-from-home.png',
      description: ('txt_teachers'), // '0 accounts registered. Use this view to send email invitations to teachers so that they can access their account.',
    },
    {
      id:SchoolBoardView.REPORTS, 
      caption:('lbl_reports'), 
      imgUrl: 'https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/work-from-home/1602128147290/work-from-home.png',
      description: ('txt_teachers'), // '0 accounts registered. Use this view to send email invitations to teachers so that they can access their account.',
    }
]