<div class="sa-reports-appeal">
    <div>
        <h1><tra slug= "lbl_request_appeal_header"></tra></h1>
        <tra-md slug= "sa_report_appeal_instructions"></tra-md>
    </div>
    <div style = "display:table">
        <div class = "sa-reports-appeal-search">
            <div class = "sa-reports-appeal-search-content">
                <div><b><tra slug= "lbl_search_by"></tra></b></div>
                <div><tra slug= "sa_report_appeal_search_discription"></tra></div>
                <form [formGroup]="searchStudentReportForm" (ngSubmit)="searchStudentReport()"> 
                    <table style="width:auto; margin-bottom:1em;">
                        <tr>
                            <td><tra slug= "sa_report_appeal_student_oen_exact"></tra></td>
                            <td><input [formControl]="searchStudentReportForm.controls.student_oen" /></td>
                        </tr>
                        <tr>
                            <td><tra slug= "sp_lbl_student_first_name"></tra></td>
                            <td><input [formControl]="searchStudentReportForm.controls.student_first_name" /></td>
                        </tr>
                        <tr>
                            <td><tra slug= "pc_lbl_student_last_name"></tra></td>
                            <td><input [formControl]="searchStudentReportForm.controls.student_last_name" /></td>
                        </tr>
                    </table>
                    <div class = "sa-reports-appeal-search-btn-container">
                        <button><tra slug="btn_search"></tra></button>
                    </div>     
                </form>
            </div>
        </div>
        <div *ngIf = 'student' class = "sa-reports-appeal-request">
            <div class = "sa-reports-appeal-request-content">
                <div class = 'sa-reports-appeal-request-content-element'><b><tra slug= "lbl_student_name"></tra>:</b>  {{student.name}}</div>
                <div class = 'sa-reports-appeal-request-content-element'><b><tra slug= "pc_lbl_student_oen"></tra>:</b>  {{student.oen}}</div>
                <div class = 'sa-reports-appeal-request-content-element'><b><tra slug= "lbl_outcome"></tra>:</b>  {{convertOveralls(student.overalls)}}</div>
                <div *ngIf = 'student.reported_issues.length > 0' class = 'sa-reports-appeal-request-content-element'><a (click)="expandReportIssues()"><i>{{lang.tra("sa_report_appeal_reported_issues", undefined, getStudentAppealReportedIssueCount())}}</i></a></div>
                <div *ngIf = 'student.showReportedIssue' class = 'sa-reports-appeal-request-content-element'>
                    <ol>
                        <li *ngFor="let reported_issue of student.reported_issues">
                            {{getTranslatedReportCategory(reported_issue.category)}} ({{convertIssueStatus(reported_issue)}})
                            <div class = 'sa-reports-appeal-request-ri-description'>
                                {{reported_issue.description}}
                            </div>
                        </li>
                    </ol>
                </div>
                <div class = 'sa-reports-appeal-request-content-element'>
                    <textarea class = 'sa-reports-appeal-request-reason-textarea' [(ngModel)]="appeal_reason" placeholder=" {{lang.tra('sa_report_appeal_reason_placeholder')}}"></textarea>
                </div>
                <div class="sa-reports-appeal-request-graduating-container sa-reports-appeal-request-content-element">
                    <input class="sa-reports-appeal-request-graduating-checkbox" type="checkbox" [(ngModel)]="isGraduatingThisYear" />
                    <span><tra slug= "sa_report_appeal_gradute_this_year"></tra></span>
                </div>
            </div>
            <div class = "sa-reports-appeal-request-btn-container">
                <button class ="sa-reports-appeal-request-btn" (click)="sentAppealRequest()">
                    <tra slug="sa_appeal_request_sent_to_eqao"></tra>
                </button>
            </div>
        </div>    
    </div>
</div>    
