import { Component, Input, OnInit } from '@angular/core';

export enum WarningTagDismissType {
  TRANSPARENT = 'transparent', // semi-transparent dismiss with disabled pointer events
  HIDDEN = 'hidden', // completely hidden dismiss
  NONE = 'none' // no dismiss icon or functionality
}

@Component({
  selector: 'warning-tag',
  templateUrl: './warning-tag.component.html',
  styleUrls: ['./warning-tag.component.scss']
})
export class WarningTagComponent implements OnInit {
  @Input() slug: string; // inserts tra-md component automatically if set
  @Input() dismissType: WarningTagDismissType = WarningTagDismissType.TRANSPARENT;
  @Input() background: string = 'is-warning'; // Bulma color class

  public isDismissed = false;

  constructor() { }

  ngOnInit(): void {
  }

  dismiss(): void {
    this.isDismissed = true;
  }

  get isDismissedHidden(): boolean {
    return this.isDismissed && this.dismissType === WarningTagDismissType.HIDDEN;
  }

  get isDismissedTransparent(): boolean {
    return this.isDismissed && this.dismissType === WarningTagDismissType.TRANSPARENT;
  }

  get isDismissable(): boolean {
    return this.dismissType !== WarningTagDismissType.NONE && !this.isDismissed;
  }
}
