import { Injectable } from '@angular/core';
import { LoginGuardService } from '../api/login-guard.service';
import { MemDataPaginated } from './paginator/helpers/mem-data-paginated';


export interface IListElement {
  __isSelected?:boolean
}


@Injectable({
  providedIn: 'root'
})
export class ListSelectService {

  constructor(
    private loginGuard: LoginGuardService,
  ) { }

  defineNewSelectableList(list:IListElement[]){
    return new ListSelector(list);
  }

  archiveTableEntry<T>(list:IListElement[], table:MemDataPaginated<T>, confirmationMsg:string){
    const targets = [].concat(list).filter(entry => entry.__isSelected);
    this.loginGuard.confirmationReqActivate({
      caption: confirmationMsg,
      confirm: () => {
        targets.map(entry => {
          const i = list.indexOf(entry);
          list.splice(i,1);
        });
        table.injestNewData(list);
      }
    })
  }
  
}

export class ListSelector {

  public isAllSelected = false;
  public isAnySelected = false;

  constructor(
    private list:IListElement[]
  ) { }

  toggleSelectAll() {
    this.setSelectAll(!this.isAllSelected);
  }
  
  setSelectAll(state: boolean) {
    state = this.isAllSelected;
    this.list.forEach(classroom => classroom.__isSelected = state);
    this.isAnySelected = state;
  }

  getSelected(){
    return [].concat(this.list).filter(entry => entry.__isSelected);
  }

  isolateFirstSelection(){
    const selectedEntries = this.getSelected()
    const target = selectedEntries[0];
    this.setSelectAll(false);
    target.__isSelected = true;
    return target;
  }

  checkSelection() {
    this.isAnySelected = false;
    this.isAllSelected = false
    this.list.forEach(student => {
      if (student.__isSelected) {
        this.isAnySelected = true;
      }
    });
  }

  wipeSelections() {
    this.list.forEach(student => {
      student.__isSelected = false
    });
  }
}
