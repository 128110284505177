
<div 
    class="element-render-moveable-dnd"
    [class.has-frame]="element.hasFrame"
    [class.is-dragging]="isDragging"
    [style.width.em]="element.width"
    [style.height.em]="element.height"
>
    <render-image 
        *ngIf="element.backgroundImg" 
        [element]="element.backgroundImg"
        [ngStyle]="getImageStyle()"
    ></render-image>
    <div class="background-el">
        <div *ngFor="let image of this.element.moveableImages">
            <element-render *ngIf="image" class="moveable-obj" [style.left.em]="image.x" [style.top.em]="image.y" [contentElement]="image" [ngStyle]="getImageStyle()"></element-render>
        </div>
    </div>
    <div  cdkDropListGroup>
        <div *ngFor="let target of this.targets;let cind=index" tabindex="0" (keyup.enter)="onEnter($event,target,cind)"
            cdkDropList
            class="target-el"
            [cdkDropListData]="target.contents"
            cdkDropListSortingDisabled
            (cdkDropListDropped)="drop($event); isDragging=false"
            (cdkDropListEntered)="isDragging=true"
            (mousedown)="onClickToDrag($event, target, cind)"
            [ngStyle]="target.targetContext ? getTargetStyle(target) : {}"
        >
            <div class="hover-indicator"></div>
            <div cdkDrag class="drag-el" [class.is-deactivateDropShadow]="element.isHideDraggableDropShadow" [cdkDragDisabled]="isLocked" 
                (cdkDragStarted) = "dragStarted($event)"                
                (cdkDragMoved) = "dragging($event)"
                [class.is-transparent]="element.isDragsTransparent" 
                [style.width.em]="element.isDraggableNotResizable ? '' : target.targetContext.width" 
                [style.height.em]="element.isDraggableNotResizable ? '' : target.targetContext.height"
                [style.padding.em]="target.targetContext.padding ? target.targetContext.padding : target.contents[0].ref.padding"
                [style.background-color]="getDraggableColour(target.contents[0].ref)"
                *ngIf="target.contents && target.contents[0] && target.contents[0].ref && target.contents[0].ref.element"
                (mousedown)="clickDrag(target.contents[0].ref); onClickToDrag($event, target, cind)"
            >
                <element-render 
                    [class.is-selected]="isSelected(target)"
                    [class.is-para-full-width]="element.isParaFullWidth"
                    [contentElement]="target.contents[0].ref.element"
                    [questionPubSub]="questionPubSub"
                ></element-render>
                <div class="img-drag-shield"></div>
                <render-audio 
                   *ngIf="target.contents[0].ref.voiceover && target.contents[0].ref.voiceover.url"
                  [url]="target.contents[0].ref.voiceover.url" 
                  [trigger]="getClickTrigger(target.contents[0].ref)" 
                  [isTriggerDisabled]="!isVoiceOverEnabled()"
                ></render-audio>
            </div>
        </div>
    </div>
</div>
