<div *ngIf="data && !exportModal">
    <button *ngIf="data.length" class="button is-small has-icon" (click)="export()">
        <span class="icon"><i class="fas fa-table"></i></span>
        <span><tra slug="g9_export"></tra></span>
    </button>

    <table *ngIf="!hideTable">
        <tr>
            <th *ngFor="let col of columns">
                {{col.caption}}
            </th>
        </tr>
        <tr *ngFor="let row of data">
            <td *ngFor="let col of columns">
                <div *ngIf="col.isClickable">
                    <a (click)="selectCell(row, col.prop)">{{ row[col.prop] }}</a>
                </div>
                <div *ngIf="!col.isClickable">{{ row[col.prop] }}</div>
            </td>
        </tr>
    </table>
</div>
<ng-container *ngIf="exportModal">
    <tra-md slug="txt_export"></tra-md>
    <ng-container *ngIf="additionalInstrSlug">
        <tra-md [slug]="additionalInstrSlug"></tra-md>
    </ng-container>

    <div *ngIf="additionalDownload" class="pre-table-strip">
        <div class="file template">
            <label class="file-label">
                <button class="file-cta" (click)="downloadFileFromSlug(additionalDownload.urlSlugExport)">
                    <span><tra [slug]="additionalDownload.captionSlugExport"></tra></span>
                </button>
            </label>
        </div>
    </div>

    <div class="pre-table-strip">
        <div class="file">
            <label class="file-label">
                <button class="file-cta" (click)="exportStudents()">
                    <span class="icon"><i class="fas fa-table"></i></span>
                    <span><tra slug="g9_export"></tra></span>
                </button>
            </label>
        </div>
    </div>
</ng-container>
