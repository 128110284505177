import { Injectable } from '@angular/core';
//import moment from 'moment';
import * as moment from 'moment-timezone'
import { AuthService } from '../api/auth.service';
import { IMarkingWindowConfig } from '../ui-scoring-leader/view-sl-dashboard/data/sample-data';
import { Subject } from 'rxjs';

export enum UserContextProperty {
  ACTIVE_CLASSROOM = ':classroomId',
}

@Injectable({
  providedIn: 'root'
})
export class UserSiteContextService {

  contextState:Map<UserContextProperty, string> = new Map();
  markingWindows:IMarkingWindowConfig[] = []; 
  loadMarkingWindowCompleted: Subject<boolean> = new Subject();

  constructor(private auth: AuthService) {
    this.resetContextState()
  }

  resetContextState(){
    this.contextState = new Map();
    this.setActiveClassroom(null)
  }

  processRoutePart(str:string) : string {
    const DEFAULT = '_';
    if (!str){
      return DEFAULT;
    }
    if (this.contextState.has(<UserContextProperty>str)){
      const contextValue = this.contextState.get(<UserContextProperty>str);
      if (!contextValue){
        return DEFAULT;
      }
      return contextValue;
    }
    return str;
  }

  handleClassroomRouteParam(classroomId:string){
    if (!classroomId || classroomId === '_' || classroomId === 'NONE'){
      return null;
    }
    else{
      this.setActiveClassroom(classroomId);
      return classroomId;
    }
  }

  setActiveClassroom(classroomId:string){
    this.contextState.set(UserContextProperty.ACTIVE_CLASSROOM, classroomId);
  }
  
  hasActiveClassroom(){
    if (this.contextState.get(UserContextProperty.ACTIVE_CLASSROOM)){
      return true;
    }
    return false;
  }

  getMarkingWindowId(markingWindowId){
    if(this.markingWindows && this.markingWindows.length){
      return this.markingWindows.find(markingWindow => +markingWindow.meta.markingWindowId === +markingWindowId);
    } 
    return null;
  }

  async loadWindows(){
    const windowRecords = await this.auth.apiFind('/public/scor-lead/windows')
    this.markingWindows = windowRecords.map(record => {
      return {
        caption: record.window_name,
        mrkg_group_id: record.group_id,
        dateFrom: moment.utc(record.start_on).tz('America/New_York').format('MMM. D, YYYY'),
        dateTo: moment.utc(record.end_on).tz('America/New_York').format('MMM. D, YYYY'),
        timeEnd: moment.utc(record.end_on).tz('America/New_York').format('HH:mm:ss'),
        stats: {},
        meta:{
          markingWindowId: record.window_id,
          isArchived: record.is_archived == 1,
        }
      }
    })
    if(this.loadMarkingWindowCompleted){
      this.loadMarkingWindowCompleted.next();
    }
    return this.markingWindows;
  }
}
