import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AccountType } from 'src/app/constants/account-types';
import { LangService } from 'src/app/core/lang.service';
import { mtz } from 'src/app/core/util/moment';
import { ITestWindow, MyCtrlOrgService } from '../my-ctrl-org.service';

@Component({
  selector: 'panel-test-window-table',
  templateUrl: './panel-test-window-table.component.html',
  styleUrls: ['./panel-test-window-table.component.scss']
})
export class PanelTestWindowTableComponent implements OnInit {
  @Input() isTwSelectDisabled: boolean = false;
  @Input() hideEdits: boolean = false;
  @Output() onTwSelect: EventEmitter<ITestWindow> = new EventEmitter();
  @Output() onVisibleTestWindowsChange: EventEmitter<ITestWindow[]> = new EventEmitter();

  public SELECT_OPTIONS = {
    ALL: 'all',
    OTHER: 'other',
  }
  public testWindows: ITestWindow[] = [];
  public isShowQA: boolean = false;
  public selectedAcademicYear: any = this.SELECT_OPTIONS.OTHER;

  private routeQuerySub: Subscription;

  constructor(
    public myCtrlOrg: MyCtrlOrgService,
    public lang: LangService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    // Load all test windows 
    this.routeQuerySub = this.route.queryParams.subscribe(queryParams => {
      this.isShowQA = (queryParams['isShowQa'] == 'yes');
    })

    this.myCtrlOrg.loadWindows(true).then((testWindows) => {
      this.testWindows = testWindows;
      this.selectedAcademicYear = this.myCtrlOrg?.selectedWindow?.academic_year || this.academicYears[0]; // Sets selected academic year to last one / or the one of the selected test window
      this.testWindowsChanged();
    });
  }

  ngOnDestroy() {
    this.routeQuerySub?.unsubscribe();
  }

  /**
   * @returns array of unique academic years from test windows
   * @example ["2022-2023", "2021-2022"]
   */
  get academicYears() {
    return this.testWindows
      .map(tw => tw.academic_year)
      .filter((v, i, a) => a.indexOf(v) === i)
      .filter(v => v && v !== 'null')
  }

  get visibleTestWindows() {
    return this.testWindows.filter(tw => {
      const isQaVisible = this.isShowQA || tw.is_qa == 0;
      return isQaVisible && this.isInAcademicYear(tw)
    })
  }

  /**
   * Adds a query param to the route to toggle QA test windows
   */
  qaToggleChanged() {
    this.router.navigate( [], {
        relativeTo: this.route,
        queryParams: {isShowQa: this.isShowQA ? 'yes' : null }, 
        queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }

  /**
   * Emits current filtered test windows to the parent
   */
  testWindowsChanged() {
    this.onVisibleTestWindowsChange.emit(this.visibleTestWindows);
  }

  /**
   * Selects the test window and emits the event to the parent
   */
  selectTestWindowFocus(tw: ITestWindow) {
    this.myCtrlOrg.selectedWindow = tw;
    this.onTwSelect.emit(tw);
  }

  /**
   * @returns true if the test window is the currently selected test window
   */
  checkActiveWindow(tw: ITestWindow) {
    return (this.myCtrlOrg.selectedWindow === tw);
  }

  /**
   * @returns formatted date string in guessed timezone 
   */
  renderDate(dateStr) {
    return mtz(dateStr).format('MMM D, YYYY')
  }

  /**
   * @example '/en/test-ctrl/test-window/ctrl-group/5403/window/65'
   */
  getTestWindowRoute(tw) {
    return `/${this.lang.c()}/${AccountType.TEST_CTRL}/test-window/ctrl-group/${tw.test_ctrl_group_id}/window/${tw.id}` ;
  }

  /**
   * Renders the test window title in the current language
   * @param twTitle: ITestWindow.title
   */
  renderTwTitle(twTitle: any){
    if(!twTitle) return '---'
    try {
      const title = JSON.parse(twTitle);
      return title[this.lang.c()] || title['en']
    }
    catch(e){
      return '---'
    }
  }

  /**
   * Checks if the test window should be shown based on the selected academic year
   *  - if the selected academic year is 'all', return true
   *  - if the selected academic year is 'other', return true if the test window is not in the academic years array
   * @param tw: ITestWindow
   */
  isInAcademicYear(tw: ITestWindow): boolean {
    if(this.selectedAcademicYear === this.SELECT_OPTIONS.ALL) return true;
    if(this.selectedAcademicYear === this.SELECT_OPTIONS.OTHER) {
      return !this.academicYears.includes(tw.academic_year);
    };
    return tw.academic_year === this.selectedAcademicYear;
  }

  renderAcademicYear(academicYear) {
    return academicYear;
  }
}
