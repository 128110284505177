import { IIdentifiedEntry } from "./types";

export interface IMappedList<T extends IIdentifiedEntry> {
    map: {[key:number]:T}
    list: T[]
}
export const renderMappedValue = (ml:IMappedList<IIdentifiedEntry>, key:number) => {
    const entry = ml.map[key];
    if (entry){
        return entry.label.trim();
    }
}
export const initMappedList = <T extends IIdentifiedEntry>(list:T[]):IMappedList<T> => {
    const mappedList:IMappedList<T> = {
        map: {},
        list,
    }
    list.forEach(entry => {
        mappedList.map[entry.id]  = entry;
    })
    return mappedList;
}

export const filterArr = <T>(arr:T[], check:(entry:T)=>boolean):T[] => [].concat(arr).filter(check);
export const isInArr = <T>(arr:T[], entry:T) => (arr.indexOf(entry) !== -1);
export const pickFromArr = <T>(arr:T[], check: (entry:T)=>boolean) => filterArr(arr, check)[0];

/**
 * Safely removes an entry from an array.  
 * Needed because `Array.prototype.splice` can remove last element of array if there is no entry in the array
 */
export const safeRemove = <T>(arr:T[], entry:T) => {
    const index = arr.indexOf(entry);
    if (index !== -1){
        arr.splice(index, 1);
    }
}

/**
 * Safely removes an entry from both the `map` and `list` properties of a given `IMappedList`.
 *
 * @param mappedList - The `IMappedList` object from which to remove the entry.
 * @param id - The identifier of the entry to remove.
 *
 * @example
 * ```typescript
 * const myMappedList = initMappedList([
 *   { id: 1, label: "One" },
 *   { id: 2, label: "Two" },
 *   { id: 3, label: "Three" },
 * ]);
 *
 * removeFromMappedList(myMappedList, 2);
 * console.log(myMappedList);
 * {
 *   map: {
 *     1: { id: 1, label: "One" },
 *     3: { id: 3, label: "Three" },
 *   },
 *   list: [
 *     { id: 1, label: "One" },
 *     { id: 3, label: "Three" },
 *   ],
 * }
 * ```
 */
export const removeFromMappedList = <T extends IIdentifiedEntry>(
    mappedList: IMappedList<T>,
    id: number
  ): void => {
    // Find the entry in the map
    const entry = mappedList.map[id];
    if (!entry) {
        console.warn(`ID ${id} not found in map.`);
        return;
    }

    // Remove the entry from the map and list
    safeRemove(mappedList.list, entry)
    delete mappedList.map[id];
  };