import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'link-field',
  templateUrl: './link-field.component.html',
  styleUrls: ['./link-field.component.scss'],
  host: {
    'class': 'field has-addons'
  }
})
export class LinkFieldComponent implements OnInit {
  @Input() url: string = "";
  @Input() isExpanded = false;

  constructor() { }

  ngOnInit(): void {
  }

  openInNewTab() {
    window.open(this.url, '_blank');
  }
}
