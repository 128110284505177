<div class="layout-bg">
    <div class="layout-container">
        <div class="header-container">
            <img src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6263/authoring/HELIX/1594929184731/HELIX.png">
            <img src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6263/authoring/ENS/1594929007313/ENS.png">
        </div>
        <div class="call-to-action">Ready to put your skills to the test? </div>
        <div class="button-start-container"><button (click)="startTest.emit()" class="button-start"><span>START</span></button></div>
        <div class="logo-container"><img class="logo" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/21/authoring/vretta-logo/1596159474108/vretta-logo.png"></div>
    </div>
</div>
