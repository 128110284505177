<div *ngIf="isActive">
  <div *ngIf="isModeSelect" class="select is-small is-fullwidth">
    <select [formControl]="modeSelector" tabindex="0">
      <option *ngFor="let option of modeOptions" [value]="option.id">{{option.caption}}</option>
    </select>
  </div>
  <div [ngSwitch]="modeSelector.value">
    <div *ngSwitchCase="FilterSettingMode.VALUE">
      <div [ngSwitch]="dataType">
        <div *ngSwitchCase="FilterDataType.TEXT">
          <input class="input is-small is-fullwidth" type="text" [formControl]="value" tabindex="0">
        </div>
        <div *ngSwitchCase="FilterDataType.NUMBER">
          <input class="input is-small is-fullwidth" type="number" [formControl]="value" tabindex="0">
        </div>
      </div>
    </div>
    <div *ngSwitchCase="FilterSettingMode.RANGE">
      <input class="input is-small is-fullwidth" placeholder="Min" type="number" [formControl]="rangeFrom" tabindex="0">
      <input class="input is-small is-fullwidth" placeholder="Max" type="number" [formControl]="rangeTo" tabindex="0">
    </div>
    <div *ngSwitchCase="FilterSettingMode.LIST">
      <select [formControl]="selectedValue">
        <option *ngFor="let item of config.list" [value]="item.val"><tra [slug]="item.display"></tra></option>
      </select>
    </div>
  </div>
</div>