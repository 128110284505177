
<!--<div 
    *ngIf="hasControls && isOneButton() && isCustomMode()" 
    class="one-button" 
    (click)="onTriggerPlay(true);stopProp($event);" 
    [class.is-default]="!isPlaying"
    [class.is-playing]="isPlaying"
>
    <img class="on-default" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6276/authoring/audio_button-default/1612797879947/audio_button-default.svg" >
    <img class="on-hover" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6276/authoring/audio_button-hover/1612798136994/audio_button-hover.svg" >
    <img class="on-playing" src="https://d3azfb2wuqle4e.cloudfront.net/user_uploads/6276/authoring/audio_button-playing/1612798150063/audio_button-playing.svg">
</div>-->
<audio
    #audioPlayer
    (click)="stopProp($event)"
    [src]="sanitizeUrl(url)"
    controlsList="nodownload"
    [controls]="controlsVisible()"
    [class.has-controls]="controlsVisible()"
    (play)="emitPlay()"
    (ended)="triggerDonePlaying()"
    (canplay)="emitCanStartPlaying()"
    (loadedmetadata)="loadedMetaData()"
    (timeupdate)="onTimeUpdate()"
    [id]="audioID ? audioID : 'audio'"
></audio>

<!-- <plyr plyrTitle=""
      *ngIf="hasControls"
      [plyrType]="audio"
      [plyrPlaysInline]="false"
      [plyrOptions]="plyrOptions"
      [plyrSources]="getAudioSources()"
      plyrCrossOrigin="anonymous"
></plyr> -->
